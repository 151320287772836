import { useEffect, useState } from "react";
import { z } from "zod";

import { EventName, useAnalytics, EventForm } from "@/hooks/useAnalytics";
import { useQueryParams } from "@/hooks/useQueryParams";
import { useSchedulingRequest } from "@/context/SchedulingContext/state/useSchedulingRequest";
import { FormUtil } from "@/components/controls/FormUtil";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";
import { formatDateRange } from "@/components/ui/eventDetailsPanel";
import { getTimeZoneIndicator } from "@fyxer-ai/shared";

type IncorrectProperty = "proposedSlots" | "timeZones" | "durationMins" | "emailPeopleToInvite" | "title";

export const CalendarSlotRatingPageWithInferredData = () => {
  const { areSlotsGood, triggeringEmailMessageId } = useQueryParams();
  const { logEvent, logFormSubmit } = useAnalytics();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { schedulingRequest } = useSchedulingRequest();
  const inferredData = schedulingRequest.inferredEventData;

  const schedulingRange = {
    startsAt: new Date(Math.min(...schedulingRequest.suggestedSlots.map((slot) => slot.startsAt.getTime()))),
    endsAt: new Date(Math.max(...schedulingRequest.suggestedSlots.map((slot) => slot.endsAt.getTime()))),
  };

  const computedTickboxItems = [
    {
      property: "proposedSlots" as IncorrectProperty,
      label: "Times",
      criteria: `We chose ${inferredData.proposedSlots.length} timeslots for you, from ${formatDateRange(
        schedulingRange.startsAt,
        schedulingRange.endsAt,
      )}`,
    },
    {
      property: "timeZones" as IncorrectProperty,
      label: "Time Zones",
      criteria: `Your time zone for this event will be ${getTimeZoneIndicator({
        timeZone: inferredData.schedulingTimeZones.userTimeZone,
      })}${
        inferredData.schedulingTimeZones.recipientTimeZones.length > 0
          ? `. The time zone(s) of other attendee(s): ${inferredData.schedulingTimeZones.recipientTimeZones
              .map((tz) => getTimeZoneIndicator({ timeZone: tz }))
              .join(", ")}`
          : ``
      }`,
    },
    {
      property: "durationMins" as IncorrectProperty,
      label: "Duration",
      criteria: `The event will last ${inferredData.durationMins} minutes`,
    },
    {
      property: "emailPeopleToInvite" as IncorrectProperty,
      label: "Attendees",
      criteria: `${inferredData.emailPeopleToInvite
        .map((person) => person.address)
        .join(", ")} will be invited to this event`,
    },
    {
      property: "title" as IncorrectProperty,
      label: "Event Title",
      criteria: `The title of this event will be: ${inferredData.title}`,
    },
  ];

  useEffect(() => {
    logEvent(EventName.CALENDAR_SLOT_RATING, {
      triggeringEmailMessageId,
      areSlotsGood: !!areSlotsGood,
    });
  }, [logEvent, triggeringEmailMessageId, areSlotsGood]);

  const feedbackSchema = z.object({
    slotFeedback: makeSchemaSafe(z.string().min(1).max(2000)),
    incorrectAttributes: z.object({
      proposedSlots: z.boolean(),
      timeZones: z.boolean(),
      durationMins: z.boolean(),
      emailPeopleToInvite: z.boolean(),
      title: z.boolean(),
    }),
  });

  const defaultValues = {
    slotFeedback: "",
    incorrectAttributes: {
      proposedSlots: false,
      timeZones: false,
      durationMins: false,
      emailPeopleToInvite: false,
      title: false,
    },
  };

  const handleSubmit = (data: { slotFeedback: string; incorrectAttributes: Record<IncorrectProperty, boolean> }) => {
    const { slotFeedback, incorrectAttributes } = data;

    try {
      logFormSubmit(EventForm.CALENDAR_SLOT_FEEDBACK, {
        slotFeedback,
        incorrectAttributes,
        triggeringEmailMessageId,
        areSlotsGood: !!areSlotsGood,
      });
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (areSlotsGood || isSubmitted) {
    return (
      <div className="flex min-h-screen items-center justify-center px-4">
        <p className="text-center">Thank you for your feedback! Feel free to close this window.</p>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen items-center justify-center px-4 py-8">
      <div className="w-full max-w-md space-y-6">
        <h2>Thank you for your rating!</h2>
        <p>
          To help you schedule, we inferred some data about your event.
          <br />
          Please <b>tick any details that are incorrect</b> so we can improve our service in future:
        </p>
        <FormUtil
          schema={feedbackSchema}
          defaultValues={defaultValues}
          render={(form) => (
            <div className="space-y-4">
              {computedTickboxItems.map((item) => (
                <div key={item.property} className="grid grid-cols-[auto,1fr] items-center gap-x-4">
                  <FormFieldUtil
                    isInline
                    control={form.control}
                    name={
                      `incorrectAttributes.${item.property}` as
                        | "incorrectAttributes.proposedSlots"
                        | "incorrectAttributes.timeZones"
                        | "incorrectAttributes.durationMins"
                        | "incorrectAttributes.emailPeopleToInvite"
                        | "incorrectAttributes.title"
                    }
                    title=""
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value as boolean}
                        onCheckedChange={(checked) => {
                          field.onChange(checked);
                        }}
                      />
                    )}
                  />

                  <div>
                    <div className="text-sm font-semibold">{item.label}</div>
                    <p className="text-xs text-gray-500">{item.criteria}</p>
                  </div>
                </div>
              ))}
              <div className="space-y-2">
                <FormFieldUtil
                  isLabelHidden={true}
                  control={form.control}
                  name="slotFeedback"
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      placeholder="Type any additional feedback here..."
                      rows={4}
                      className="w-full"
                    />
                  )}
                />
              </div>
            </div>
          )}
          onSubmit={handleSubmit}
          renderSubmitButton={(props) => (
            <Button {...props} className="w-full">
              Submit Feedback
            </Button>
          )}
        />
      </div>
    </div>
  );
};
