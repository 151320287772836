import { QueryDocumentSnapshot } from "firebase/firestore";
import { cn } from "@/lib/utils";
import { formatMilliseconds } from "../formatMilliseconds";
import { TranscriptSegment as TranscriptSegmentType } from "@fyxer-ai/shared";
import { useTranscript } from "../context/TranscriptContext";
import { Copy } from "lucide-react";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";

interface TranscriptSegmentProps {
  transcriptSegment: QueryDocumentSnapshot<TranscriptSegmentType>;
}

const getAllOccurenceIndexes = (searchTerm: string, valueToSearch: string) => {
  const regex = new RegExp(searchTerm, "g");
  return Array.from(valueToSearch.matchAll(regex), (match) => match.index);
};

export const TranscriptSegment = ({ transcriptSegment }: TranscriptSegmentProps) => {
  const { searchValue, searchIndex, matchedTranscriptSegmentIds, transcriptRefs } = useTranscript();

  const { startMs, speaker, text } = transcriptSegment.data();
  const isMatched = text.toLowerCase().includes(searchValue.toLowerCase());
  const segmentIndex = matchedTranscriptSegmentIds.indexOf(transcriptSegment.id);

  const { logButtonPress } = useAnalytics();

  const handleCopy = () => {
    logButtonPress(EventButton.COPY_TRANSCRIPT_SEGMENT);
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied to clipboard",
    });
  };

  return (
    <div
      ref={transcriptRefs[segmentIndex]}
      className={cn("group relative rounded-lg px-4 py-3 transition-colors hover:bg-slate-50", "flex flex-col gap-2")}
    >
      <Button
        variant="outline"
        size="sm"
        className="z-90 absolute right-0 top-0 flex -translate-y-1/2 items-center gap-1 border-slate-50 bg-white opacity-0 shadow-sm transition-opacity group-hover:opacity-100"
        onClick={handleCopy}
      >
        <Copy className="h-3.5 w-3.5" />
        <span>Copy</span>
      </Button>

      <div className="flex items-center gap-2">
        <div className="box-border inline-block overflow-hidden text-ellipsis whitespace-nowrap rounded-lg bg-slate-100 px-2 py-0.5 text-sm font-medium">
          {speaker}
        </div>

        <p
          className="overflow-hidden text-ellipsis whitespace-nowrap py-1 text-xs text-slate-500"
          style={{ fontFamily: "DM Mono" }}
        >
          {formatMilliseconds(startMs)}
        </p>
      </div>

      <p className="ml-2 flex-shrink flex-grow text-sm leading-relaxed">
        {isMatched
          ? getAllOccurenceIndexes(searchValue.toLowerCase(), text.toLowerCase()).reduce<(string | JSX.Element)[]>(
              (acc, occurenceIndex, index, arr) => {
                const startIndex = index === 0 ? 0 : arr[index - 1] + searchValue.length;
                const endIndex = occurenceIndex;
                const isLast = index === arr.length - 1;

                return acc.concat(
                  text.slice(startIndex, endIndex),
                  <span
                    key={index}
                    className={cn(segmentIndex === searchIndex ? "bg-purple-500 text-white" : "bg-purple-200")}
                  >
                    {text.slice(occurenceIndex, occurenceIndex + searchValue.length)}
                  </span>,
                  ...(isLast ? [text.slice(occurenceIndex + searchValue.length)] : []),
                );
              },
              [],
            )
          : text}
      </p>
    </div>
  );
};
