import { BillingCycle, getAnnualPriceId, SubscriptionPlanType } from "@fyxer-ai/shared";
import { useState } from "react";
import { Check } from "lucide-react";
import { z } from "zod";

import handshakeSrc from "@/assets/subscription-plan-icons/handshake.svg";
import lightningSrc from "@/assets/subscription-plan-icons/lightning.svg";
import rocketSrc from "@/assets/subscription-plan-icons/rocket.svg";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableCell, TableRow } from "@/components/ui/table";
import { EventButton, EventForm, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { Badge } from "@/components/ui/badge";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { SelectUtil } from "@/components/controls/SelectUtil";

import { redirectExternally } from "@/lib/redirectExternally";

import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { NumberInput } from "@/components/ui/input";
import { getCompanyName } from "@/lib/getWhitelabelProvider";
import { useApi } from "@/hooks/useApi";
import { getEnvironment } from "@/lib/getEnvironment";

export const planTypes = [
  SubscriptionPlanType.STANDARD,
  SubscriptionPlanType.PRO,
  SubscriptionPlanType.ENTERPRISE,
] as const;
type PlanType = (typeof planTypes)[number];

type Color = "indigo" | "violet" | "purple" | "pink";

export const threeMonthAnnualDiscount = 9 / 12; // 3 months free

type NewDatum = {
  planType: PlanType;
  iconSrc: string;
  color: Color;
  priceMonthlyCents: number | undefined;
  buttonCta: string;
  data: (string | boolean)[];
};

export const newData: NewDatum[] = [
  {
    planType: SubscriptionPlanType.STANDARD,
    data: ["Pay and add your team.", "PRICE", "Chat support", "-", "-", "-", "-"],
    priceMonthlyCents: 3000,
    color: "purple",
    iconSrc: lightningSrc,
    buttonCta: "Choose",
  },
  {
    planType: SubscriptionPlanType.PRO,
    data: [
      "Pay and add your team.",
      "PRICE",
      "Account manager with 24/7 support",
      "Hubspot & Salesforce integration",
      "Add multiple inboxes and calendars for each user",
      "Custom branded Meeting Notetaker",
      "-",
    ],
    priceMonthlyCents: 5000,
    color: "indigo",
    iconSrc: rocketSrc,
    buttonCta: "Choose",
  },
  {
    planType: SubscriptionPlanType.ENTERPRISE,
    data: [
      "Talk to us about advanced features for bigger teams.",
      "PRICE",
      "Account manager with 24/7 support",
      "Hubspot & Salesforce integration",
      "Add multiple inboxes and calendars for each user",
      "Custom branded Meeting Notetaker",
      "SSO Login, Team Analytics, Advanced security controls",
    ],
    color: "pink",
    priceMonthlyCents: undefined,
    iconSrc: handshakeSrc,
    buttonCta: "Talk to us",
  },
];

const commarizeNumber = (n: number, decimalPlaces: number = 2, locale: string = "en-US"): string => {
  const options = {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };
  return n.toLocaleString(locale, options);
};

export const getPriceCents = (
  {
    priceMonthlyCents,
    billingCycle,
  }: {
    priceMonthlyCents: number;
    billingCycle: BillingCycle;
  },
  yearlyDiscount: number = threeMonthAnnualDiscount,
) => Math.round(priceMonthlyCents * (billingCycle === BillingCycle.ANNUALLY ? yearlyDiscount : 1));

export const getDisplayPrice = (priceCents: number, showCents = false) => {
  const price = priceCents / 100;

  if (showCents) {
    return `$${commarizeNumber(price, 2)}`;
  }
  return `$${commarizeNumber(price, 0)}`;
};

const displayBillingCycle = BillingCycle.ANNUALLY;

export const PricingView = () => {
  const { organisationId, memberships } = useOrganisation();
  const { logButtonPress, logFormSubmit } = useAnalytics();
  const api = useApi();
  const [billingCycle, setBillingCycle] = useState<BillingCycle>(BillingCycle.ANNUALLY);
  const [showCheckoutModal, setShowCheckoutModal] = useState<{
    show: boolean;
    planType: SubscriptionPlanType.PRO | SubscriptionPlanType.STANDARD;
    billingCycle: BillingCycle;
  }>({
    show: false,
    planType: SubscriptionPlanType.STANDARD,
    billingCycle: billingCycle,
  });
  const annualDiscount = threeMonthAnnualDiscount;

  useLogPageView(EventPage.PRICING, { organisationId });

  const handleSelectPlan =
    (planType: PlanType, selectedBillingCycle: BillingCycle = displayBillingCycle) =>
    () => {
      logButtonPress(EventButton.SELECT_PLAN, { planName: planType });

      if (planType === SubscriptionPlanType.ENTERPRISE) {
        return redirectExternally("https://cal.com/forms/26b045cc-f0b2-4987-a8ea-2fad13840543");
      }

      setShowCheckoutModal({
        show: true,
        planType,
        billingCycle: selectedBillingCycle,
      });
    };

  const selfServePlanTypes = [SubscriptionPlanType.STANDARD, SubscriptionPlanType.PRO] as const;
  const IS_ANNUAL = billingCycle === BillingCycle.ANNUALLY;

  return (
    <>
      <Dialog
        open={showCheckoutModal.show}
        onOpenChange={(open) => setShowCheckoutModal({ ...showCheckoutModal, show: open })}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Start a {showCheckoutModal.planType === SubscriptionPlanType.PRO ? "Pro" : "Standard"} subscription
            </DialogTitle>
          </DialogHeader>

          <FormUtil
            schema={z.object({
              billingCycle: z.nativeEnum(BillingCycle),
              planType: z.enum(selfServePlanTypes),
              seatCount: z.number().int().gte(Math.max(1, memberships.length)),
            })}
            defaultValues={{
              seatCount: Math.max(1, memberships.length),
              planType: showCheckoutModal.planType,
              billingCycle: showCheckoutModal.billingCycle,
            }}
            submitTitle="Continue to checkout"
            render={(form) => {
              const billingCycle = form.watch("billingCycle");
              const seatCount = form.watch("seatCount");
              const planTypeForm = form.watch("planType");
              const priceMonthlyCents =
                newData.find((datum) => datum.planType === planTypeForm)?.priceMonthlyCents ?? 3000;

              const pricePerSeatPerMonth = getPriceCents({ priceMonthlyCents, billingCycle }, annualDiscount);

              return (
                <>
                  <FormFieldUtil
                    render={({ field }) => <SelectUtil {...field} items={Object.values(BillingCycle)} />}
                    control={form.control}
                    name="billingCycle"
                  />
                  <FormFieldUtil
                    render={({ field }) => <NumberInput {...field} />}
                    control={form.control}
                    name="seatCount"
                    description={`This can't be less than the number of teammates you have on ${getCompanyName()}, which is ${memberships.length}.`}
                  />

                  <Table>
                    <TableRow>
                      <TableCell>Cost per seat</TableCell>
                      <TableCell className="text-right">
                        {seatCount ? getDisplayPrice(pricePerSeatPerMonth, true) + " / month" : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total cost</TableCell>
                      <TableCell className="text-right">
                        {seatCount
                          ? getDisplayPrice(
                              pricePerSeatPerMonth * seatCount * (billingCycle === BillingCycle.MONTHLY ? 1 : 12),
                              true,
                            ) +
                            " / " +
                            (billingCycle === BillingCycle.MONTHLY ? "month" : "year")
                          : "-"}
                      </TableCell>
                    </TableRow>
                  </Table>
                </>
              );
            }}
            onSubmit={async (data) => {
              let priceId = undefined;
              logFormSubmit(EventForm.START_SUBSCRIPTION, data);

              if (data.billingCycle === BillingCycle.ANNUALLY) {
                const isPro = data.planType === SubscriptionPlanType.PRO;
                priceId = getAnnualPriceId(getEnvironment(), isPro);
              }

              const checkoutSessionUrl = await api.organisations.id(organisationId).checkoutSessions.create({
                ...data,
                ...(priceId ? { priceId } : {}),
              });
              redirectExternally(checkoutSessionUrl);
            }}
          />
        </DialogContent>
      </Dialog>

      <h2 className="mb-12 mt-4 text-center text-3xl sm:text-4xl">Choose the right plan for you</h2>
      <div className="mb-12 block">
        <div className="mx-auto grid grid-cols-2 items-center">
          <div className="flex items-center justify-end gap-x-3">
            <Label className={`${!IS_ANNUAL ? "font-bold" : "text-gray-500"}`}>Monthly</Label>
            <Switch
              checked={IS_ANNUAL}
              onCheckedChange={(checked) => setBillingCycle(!checked ? BillingCycle.MONTHLY : BillingCycle.ANNUALLY)}
            />
          </div>
          <div className="flex items-center gap-x-3 pl-3">
            <Label className={`${IS_ANNUAL ? "font-bold" : "text-gray-500"}`}>Yearly</Label>
            <Badge className="bg-yellow-200 font-semibold text-black hover:bg-yellow-200">3 months free</Badge>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-lg">
        <Card className="mb-6">
          <CardHeader>
            <div>
              <div className="flex">
                <CardTitle className="flex flex-grow items-center gap-x-3">
                  <img src={newData[1].iconSrc} className="h-8" />
                  Professional
                </CardTitle>
                <Badge className="bg-yellow-200 font-semibold text-black hover:bg-yellow-200">Most popular</Badge>
              </div>
              <p className="mt-6 text-base">Take your productivity to the next level with Pro.</p>
            </div>
          </CardHeader>
          <CardContent>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" />{" "}
              <p>
                <span className="font-bold">Unlimited</span> connected inboxes and calendars
              </p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Email sorting &amp; categorization</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Draft replies in your tone and language</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Custom branded Meeting Notetaker</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Advanced team-level calendar scheduler</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Hubspot & Salesforce integration</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>24/7 Account manager support</p>
            </div>
            <div className="mt-8 flex items-center justify-between">
              <Button onClick={handleSelectPlan(newData[1].planType, billingCycle)}>Upgrade now</Button>
              <p className="text-right">
                <div className="block sm:inline-block">
                  {IS_ANNUAL ? (
                    <span className="text-gray-500 line-through">
                      {getDisplayPrice(Number(newData[1].priceMonthlyCents), false)}
                    </span>
                  ) : null}
                  <span className={`ml-1 font-semibold ${IS_ANNUAL ? "text-purple-700" : ""}`}>
                    {getDisplayPrice(
                      getPriceCents(
                        {
                          priceMonthlyCents: Number(newData[1].priceMonthlyCents),
                          billingCycle,
                        },
                        annualDiscount,
                      ),
                      true,
                    )}
                  </span>
                </div>
                <span className="ml-1 text-xs text-gray-500">/ user / month</span>
              </p>
            </div>
          </CardContent>
        </Card>

        <Card className="mb-6">
          <CardHeader>
            <div>
              <CardTitle className="flex flex-grow items-center gap-x-3">
                <img src={newData[0].iconSrc} className="h-8" />
                Standard
              </CardTitle>
              <p className="mt-6 text-base">Our most basic plan for individuals and small teams.</p>
            </div>
          </CardHeader>
          <CardContent>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p className="font-bold">1 inbox and calendar per user</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Email sorting &amp; categorization</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Draft replies in your tone and language</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Meeting Notetaker &amp; Scheduler</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Chat support</p>
            </div>
            <div className="mt-8 flex items-center justify-between">
              <Button variant="secondary" onClick={handleSelectPlan(newData[0].planType, billingCycle)}>
                Upgrade now
              </Button>
              <p className="text-right">
                <div className="block sm:inline-block">
                  {billingCycle === BillingCycle.ANNUALLY ? (
                    <span className="text-gray-500 line-through">
                      {getDisplayPrice(Number(newData[0].priceMonthlyCents), false)}
                    </span>
                  ) : null}
                  <span className={`ml-1 font-semibold ${IS_ANNUAL ? "text-purple-700" : ""}`}>
                    {getDisplayPrice(
                      getPriceCents(
                        {
                          priceMonthlyCents: Number(newData[0].priceMonthlyCents),
                          billingCycle,
                        },
                        annualDiscount,
                      ),
                      true,
                    )}
                  </span>
                </div>
                <span className="ml-1 text-xs text-gray-500">/ user / month</span>
              </p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <div>
              <CardTitle className="flex flex-grow items-center gap-x-3">
                <img src={newData[2].iconSrc} className="h-8" />
                Enterprise
              </CardTitle>
              <p className="mt-6 text-base">Our most secure and advanced plan, including all of Pro plan's benefits.</p>
            </div>
          </CardHeader>
          <CardContent>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Everything in Pro &amp; Standard</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>SSO Login</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Team analytics</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>SCIM provisioning</p>
            </div>
            <div className="mb-4 flex items-center gap-x-4 text-sm">
              <Check className="stroke-purple-700" /> <p>Advanced security controls</p>
            </div>
            <div className="mt-8 flex items-center justify-between">
              <Button onClick={handleSelectPlan(SubscriptionPlanType.ENTERPRISE)} variant="secondary">
                Contact us
              </Button>
              <p className="text-right">
                <span className="text-gray-500">Tiered pricing</span>
              </p>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};
