import { useForm } from "react-hook-form";
import { Form, FormItem, FormLabel, FormControl, FormField, FormMessage } from "@/components/ui/form";
import { Button } from "@/components/ui/button";

export const UserDetailsForm = ({
  onSubmit,
  details,
}: {
  onSubmit: (details: { name: string; email: string }) => void;
  details: { name: string; email: string };
}) => {
  const form = useForm<{ name: string; email: string }>({
    defaultValues: {
      email: details.email,
      name: details.name,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {/* Email Field */}
        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <input
                  {...field}
                  type="email"
                  placeholder={details.email}
                  className="w-full rounded-md border border-gray-300 p-2 focus:border-purple-500 focus:outline-none focus:ring focus:ring-purple-500 focus:ring-opacity-50"
                  required
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Name Field */}
        <FormField
          name="name"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <input
                  {...field}
                  type="text"
                  placeholder={details.name}
                  className="w-full rounded-md border border-gray-300 p-2 focus:border-purple-500 focus:outline-none focus:ring focus:ring-purple-500 focus:ring-opacity-50"
                  required
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Submit Button */}
        <div className="text-right">
          <Button type="submit" className="mt-4 rounded-md bg-purple-500 px-6 py-2 text-white hover:bg-purple-600">
            Create Event
          </Button>
        </div>
      </form>
    </Form>
  );
};
