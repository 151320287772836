import { useCallback, useMemo } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { Collection } from "@/lib/firebase/Collection";
import { useBase } from "@/context/BaseContext/state/useBase";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";
import { useCurrentChatThread } from "./useCurrentChatThread";

export const useChatMessageRating = () => {
  const { authUser } = useBase();
  const { logButtonPress } = useAnalytics();
  const { thread } = useCurrentChatThread();

  const canVote = useMemo(() => thread?.userId === authUser.value?.uid, [thread, authUser.value?.uid]);

  const handleRating = useCallback(
    async (messageId: string, isGood: boolean) => {
      await updateDoc(doc(Collection.CallRecordingChatMessage, messageId), {
        rating: isGood,
      });

      logButtonPress(EventButton.RATE_CHAT_MESSAGE, {
        isMessageGood: isGood,
      });
    },
    [logButtonPress],
  );

  return { canVote, handleRating };
};
