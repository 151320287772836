export enum LocalStorageBooleanKey {
  IS_SIDEBAR_EXPANDED = "isSidebarExpanded",
  ALLOWED_MARKETING_ON_SIGN_UP = "allowedMarketingOnSignUp",
  WAS_ONBOARDING_SKIPPED = "wasOnboardingSkipped",
  WAS_ZOOM_CONNECTION_SKIPPED = "wasZoomConnectionSkipped",
  WAS_TEAMS_SIGNUP_SKIPPED = "wasTeamsSignupSkipped",
  ONBOARDING_AUTH_STARTED = "onboardingEmailAuthStarted",
  TRACKED_SIGNUP_EVENT = "hasTrackedSignUpEvent",
  TRACKED_HIGH_EMPLOYEE_SIGNUP_EVENT = "hasTrackedHighEmployeeSignUpEvent",
  SEEN_CC_UPFRONT_OFFER = "hasSeenCreditCardUpfrontOffer",
  SEEN_BOOK_CS_CALL = "hasSeenBookCSCallOffer",
  SKIPPED_CALENDAR_GUARD = "hasSkippedCalendarGuard",
}

export enum LocalStorageStringKey {
  EMAIL_FOR_SIGN_IN = "emailForSignIn",
  REFERRER = "referrer",
  OAUTH_STATE_ID = "oauthStateId",
  UTM_PARAMS = "utmParams",
  ONBOARDING_STEP_INDEX = "onboardingStepIndex",
  USER_ID = "userId",
}

export enum LocalStorageEnumKey {
  SIGNUP_SOURCE = "signupSource",
  CALENDAR_PROVIDER = "calendarProvider",
}

export enum LocalStorageDateKey {
  _ = "",
}

export enum SessionStorageStringKey {
  _ = "",
}

export enum SessionStorageEnumKey {
  _ = "",
}

export enum SessionStorageBooleanKey {
  HAS_REDIRECTED_TO_ORG_CREATE = "hasRedirectedToOrgCreate",
}

export enum SessionStorageDateKey {
  _ = "",
}

export enum CalendarProvider {
  GOOGLE = "google",
  MICROSOFT = "microsoft",
}

const getStorage = <
  StringKey extends string,
  BooleanKey extends string,
  DateKey extends string,
  EnumKey extends string,
>(
  storage: Storage,
) => {
  return {
    boolean: (key: BooleanKey) => {
      const remove = () => storage.removeItem(key);
      const get = () => {
        const value = storage.getItem(key) ?? "false";
        if (!["true", "false"].includes(value)) throw new Error("Invalid boolean");
        return value === "true";
      };
      const set = (value: boolean) => {
        const stringValue = value ? "true" : "false";
        storage.setItem(key, stringValue);
      };

      const toggle = () => set(!get());

      return { remove, get, set, toggle };
    },
    string: (key: StringKey) => ({
      remove: () => storage.removeItem(key),
      get: () => storage.getItem(key) ?? undefined,
      set: (value: string) => {
        storage.setItem(key, value);
      },
    }),
    date: (key: DateKey) => ({
      remove: () => storage.removeItem(key),
      get: () => {
        const value = storage.getItem(key);
        if (!value) return undefined;
        const date = new Date(parseInt(value, 10));
        return isNaN(date.getTime()) ? undefined : date;
      },
      set: (value: Date) => {
        storage.setItem(key, value.getTime().toString());
      },
    }),
    enum: <T extends string>(key: EnumKey) => {
      const remove = () => storage.removeItem(key);
      const get = () => storage.getItem(key) as T | undefined;
      const set = (value: T) => {
        storage.setItem(key, value);
      };

      return { remove, get, set };
    },
  };
};

export const storage = {
  local: getStorage<LocalStorageStringKey, LocalStorageBooleanKey, LocalStorageDateKey, LocalStorageEnumKey>(
    localStorage,
  ),
  session: getStorage<SessionStorageStringKey, SessionStorageBooleanKey, SessionStorageDateKey, SessionStorageEnumKey>(
    sessionStorage,
  ),
};
