import { constructUrl, OauthServiceType, SignupSource } from "@fyxer-ai/shared";
import { useQuery } from "@tanstack/react-query";
import { getCountFromServer, query, where } from "firebase/firestore";
import { Link } from "react-router-dom";

import { Logo } from "@/components/layout/TopBar";
import { Button } from "@/components/ui/button";
import { SchedulingRequestView } from "./SchedulingRequestView";
import { getCompanyName, getMarketingSiteUrl } from "@/lib/getWhitelabelProvider";
import { PageContainer } from "@/components/layout/ShadowedPageContainer";
import { useBase } from "@/context/BaseContext/state/useBase";
import { Collection } from "@/lib/firebase/Collection";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";

const OnboardingBanner = () => {
  const { logButtonPress } = useAnalytics();

  return (
    <div className="left-0 top-0 w-full border-b border-slate-200 bg-white max-sm:sticky sm:fixed">
      <div className="m-auto flex h-full max-w-4xl flex-col items-start gap-x-4 gap-y-2 px-4 py-3 sm:flex-row sm:items-center">
        <Link to={getMarketingSiteUrl()} target="_blank" rel="noreferrer" className="flex-shrink-0">
          <Logo />
        </Link>

        <p className="text-left text-sm text-slate-500 sm:text-center">
          {getCompanyName()} organizes your inbox, drafts extraordinary emails and writes better-than-human meeting
          notes.
        </p>
        <div className="flex-grow" />
        <Link
          onClick={() => {
            logButtonPress(EventButton.SCHEDULING_LINK_CTA_CLICK);
          }}
          to={constructUrl({
            path: "/org",
            search: { src: SignupSource.CALENDAR_SCHEDULING_LINK },
          })}
          target="_blank"
          rel="noreferrer"
          className="flex-shrink-0"
        >
          <Button>Start free trial</Button>
        </Link>
      </div>
    </div>
  );
};

export const OnboardingBannerIfNeeded = () => {
  const { authUser } = useBase();
  const userId = authUser.value?.uid;
  const isUnauthenticated = !authUser.isLoading && !authUser.value;

  const emailConnectionCount = useQuery({
    queryKey: ["emailConnectionCount"],
    queryFn: async () => {
      if (!userId) return undefined;
      const emailConnectionCountAggregation = await getCountFromServer(
        query(
          Collection.OauthConnection,
          where("serviceType", "==", OauthServiceType.EMAIL),
          where("userId", "==", userId),
        ),
      );

      return emailConnectionCountAggregation.data().count;
    },
    refetchOnWindowFocus: false,
  });

  const shouldOnboard = isUnauthenticated || emailConnectionCount.data === 0;

  return shouldOnboard ? <OnboardingBanner /> : null;
};

export const SchedulingPage = () => {
  return (
    <>
      <OnboardingBannerIfNeeded />
      <PageContainer>
        <SchedulingRequestView />
      </PageContainer>
    </>
  );
};
