import { useSearchParams } from "react-router-dom";

/**
 * A custom hook that parses the URL query parameters.
 * @returns An object containing the query parameters as key-value pairs.
 * @example
 * // Assuming the URL is http://example.com/?key1=value1&key2=value2
 * const params = useQueryParams();
 * console.log(params); // { key1: 'value1', key2: 'value2' }
 */
export const useQueryParams = () => {
  const [searchParams] = useSearchParams();
  return Object.fromEntries(
    Array.from(searchParams.entries()).map(([key, value]) => [key, decodeURIComponent(value)]),
  ) as { [k: string]: string | undefined };
};
