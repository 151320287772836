import { Button } from "./button";
import { Logo } from "../layout/TopBar";
import { cn } from "@/lib/utils";
import { useIsMobile } from "@/hooks/useIsMobile";

interface BoxContainerProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  onBackClick?: () => void;
  isBackButtonVisible?: boolean;
}

export const minimalistBackArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 sm:h-6 sm:w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
  </svg>
);

export const BoxContainer = ({ leftContent, rightContent, onBackClick }: BoxContainerProps) => {
  const isBackButtonVisible = !!onBackClick;
  const isMobile = useIsMobile();
  return (
    <div
      className={`flex w-full max-w-4xl flex-col overflow-hidden rounded-xl bg-white shadow-xl ${
        !isMobile ? "h-[568px]" : ""
      }`}
    >
      <div className="flex items-center justify-between border-b border-gray-200 p-4 sm:p-6">
        <Button
          variant="ghost"
          size="icon"
          onClick={onBackClick}
          aria-label="Go back"
          className={cn(
            "text-gray-500 transition-opacity hover:text-gray-700",
            !isBackButtonVisible && "pointer-events-none invisible",
          )}
        >
          {minimalistBackArrow}
        </Button>
        <div className="flex flex-1 justify-center">
          <Logo />
        </div>
        <div className="w-6" />
      </div>
      <div className={`flex flex-col md:flex-row ${!isMobile ? "h-full items-start" : ""}`}>
        <div className={`w-full bg-gray-50 p-4 sm:p-6 md:w-1/3 ${!isMobile ? "self-stretch" : ""}`}>{leftContent}</div>
        <div
          className={`w-full overflow-y-auto p-4 ${
            !isMobile ? "max-sm:max-h-[40vh] sm:max-h-[60vh]" : ""
          } sm:p-6 md:w-2/3`}
        >
          {rightContent}
        </div>
      </div>
    </div>
  );
};
