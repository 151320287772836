import csPeople from "@/assets/people/cs.png";
import {
  getScopes,
  OauthConnection,
  OauthServiceType,
  parseCustomEmailDomain,
  organisationConfigDefaults,
} from "@fyxer-ai/shared";
import { useQuery } from "@tanstack/react-query";
import { deleteDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { Rocket, Sparkle, User, X } from "lucide-react";
import { ReactNode, useState } from "react";

import zoomLogo from "@/assets/company-logos/zoom.png";
import { ConfirmAlertDialog } from "@/components/ConfirmAlertDialog";
import { CenterPage } from "@/components/layout/CenterPage";
import { Container } from "@/components/layout/Container";
import { SpinnerPage } from "@/components/layout/SpinnerPage";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/BaseContext/state/useAuth";
import { useUser } from "@/context/BaseContext/state/useUser";
import { EventButton, EventName, EventPage, useAnalytics, useFeatureFlag, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useClickProps } from "@/hooks/useClickProps";
import { useUpdateState } from "@/hooks/useUpdateState";
import { unwrap } from "@/lib/firebase/unwrap";
import { LocalStorageBooleanKey, storage } from "@/lib/storage";
import {
  integrationData,
  IntegrationDatum,
} from "@/routes/org/[organisationId]/settings/tabs/integrations/integrationData";
import { useOauthRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/useOauthRedirect";
import { useRecallZoomConnectionRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/ZoomIntegrationsSection";
import { InvitesForm } from "@/routes/org/[organisationId]/settings/tabs/team/InvitesDialog";
import { PlanTypeSource } from "@/routes/org/[organisationId]/settings/tabs/billing/getPlanType";

import { useBase } from "@/context/BaseContext/state/useBase";
import { useOrganisation } from "../state/useOrganisation";
import { getCompanyName, getWhitelabelProviderFromPageUrl, WhitelabelProvider } from "@/lib/getWhitelabelProvider";
import { FreeTrialGuard } from "@/components/InviteFreeTrialPopup";
import { NoEmailConnectionPage } from "./EmailConnectionGuard";
import { NoCalendarConnectionPage } from "./NoCalendarConnectionPage";

const ReconnectRow = ({ connection }: { connection: QueryDocumentSnapshot<OauthConnection> }) => {
  const { metadata, integration, organisationId } = connection.data();
  const email = metadata.email as string | undefined;
  const { title, logoUrl } = integrationData.find((datum) => datum.integration === integration) as IntegrationDatum;
  const { handleOauthRedirect } = useOauthRedirect();
  const refreshButtonProps = useClickProps({
    onClick: () => handleOauthRedirect({ integration, organisationId, type: "integration" }),
    buttonText: "Refresh",
  });
  return (
    <div className="flex items-center gap-x-4">
      <img alt={title} src={logoUrl} className="h-8 w-8 rounded-sm" />
      <h4>{title}</h4>
      {email ? <Badge variant="secondary">{email}</Badge> : null}
      <div className="flex-grow" />
      <Button {...refreshButtonProps} />
      <ConfirmAlertDialog
        title="Delete"
        warningText="If you delete the connection, you'll lose all associated data."
        fn={() => deleteDoc(connection.ref)}
      />
    </div>
  );
};

const OutOfDateConnectionsPage = ({
  outOfDateConnections,
}: {
  outOfDateConnections: QueryDocumentSnapshot<OauthConnection>[];
}) => {
  return (
    <Container className="space-y-4 py-12">
      <div className="space-y-2">
        <h1>Refresh your integrations</h1>
        <p className="text-slate-500">
          Occasionally we release updates that require us to refresh our access to your connections with{" "}
          {getCompanyName()}. Please refresh the following connections to return to the main dashboard.
        </p>
      </div>

      <div className="space-y-2">
        {outOfDateConnections.map((connection) => (
          <ReconnectRow key={connection.id} connection={connection} />
        ))}
      </div>
    </Container>
  );
};

const NoZoomConnectionPage = ({ organisationId, markSeen }: { organisationId: string; markSeen: () => void }) => {
  useLogPageView(EventPage.NO_ZOOM_CONNECTION);

  const { redirectToAuthUrl } = useRecallZoomConnectionRedirect();

  const handleSkip = () => {
    storage.local.boolean(LocalStorageBooleanKey.WAS_ZOOM_CONNECTION_SKIPPED).set(true);
    markSeen();
  };

  const redirectToZoomClickProps = useClickProps({
    onClick: () => redirectToAuthUrl(organisationId),
    buttonText: "Connect Zoom",
  });

  return (
    <CenterPage className="space-y-8 max-sm:space-y-4">
      <img src={zoomLogo} className="m-auto h-8 w-8 rounded" />
      <h1 className="text-center text-3xl max-sm:text-xl">Do you use Zoom?</h1>
      <p className="text-center">
        Connect your zoom account with {getCompanyName()} so our Notetaker can take the minutes from your video meetings
      </p>
      <div className="space-y-2">
        <Button {...redirectToZoomClickProps} className="w-full" />
        <Button variant="ghost" onClick={handleSkip} className="w-full">
          Skip for now
        </Button>
      </div>
    </CenterPage>
  );
};

export const TeamInvitePage = ({ markSeen }: { markSeen: (() => void) | undefined }) => {
  useLogPageView(EventPage.ADD_TEAM);
  const { organisationId } = useOrganisation();
  const { logEvent } = useAnalytics();

  const api = useApi();
  const [state, updateState] = useUpdateState<{
    invitees: string[] | undefined;
  }>({
    invitees: undefined,
  });

  const recommendedInvitees = useQuery({
    queryKey: ["recommendedInvitees", organisationId],
    queryFn: async () => {
      const emails = await api.organisations.id(organisationId).invites.recommend();
      updateState({ invitees: emails });
      logEvent(EventName.INVITE_RECOMMENDATIONS_LOADED, { recommendations: emails.length });
      return emails;
    },
    refetchOnWindowFocus: false,
  });

  const handleNext = () => {
    storage.local.boolean(LocalStorageBooleanKey.WAS_TEAMS_SIGNUP_SKIPPED).set(true);
    markSeen?.();
  };

  if (recommendedInvitees.isLoading) return <SpinnerPage />;

  return (
    <CenterPage className="max-w-[540px] space-y-4">
      <h1 className="text-center text-3xl max-sm:text-xl">Give your teammates a free trial</h1>
      {state.invitees !== undefined && (
        <InvitesForm initialInviteEmails={state.invitees} showRole={false} afterSubmit={() => handleNext()} />
      )}
      {markSeen && (
        <Button variant="ghost" onClick={handleNext} className="w-full">
          Skip for now
        </Button>
      )}
    </CenterPage>
  );
};

const OnboardingIntegrationImage = ({ src, alt }: { src: string; alt: string }) => (
  <img src={src} alt={alt} className="h-10 w-10 rounded-lg border border-slate-100" />
);

export const OnboardingIntegrationsRack = ({
  integrations,
  text = "Pairs with:",
}: {
  integrations: { src: string; alt: string }[];
  text?: string;
}) => (
  <div className="flex items-center gap-x-4">
    <p>{text}</p>
    <div className="flex gap-x-2">
      {integrations.map(({ src, alt }) => (
        <OnboardingIntegrationImage src={src} alt={alt} key={src} />
      ))}
    </div>
  </div>
);

const BookingPopup = ({ onHide }: { onHide: () => void }) => {
  const { logButtonPress } = useAnalytics();

  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white">
      <X size={32} className="absolute right-4 top-4 cursor-pointer" onClick={onHide} />
      <div className="mx-auto max-w-md px-3">
        <img src={csPeople} alt="Customer success team" className="mx-auto mb-6 block max-w-[150px]" />
        <h1 className="mb-3 text-center text-3xl">Fyxer Pro, free for your team!</h1>
        <p className="mb-6 text-center text-gray-500">
          We offer a 21-day pilot for businesses where we share performance data and time saved so you can make an
          informed decision.
        </p>

        <div className="mx-auto mb-6 flex max-w-md items-center justify-center gap-x-3">
          <User size={20} className="flex-shrink-0 stroke-purple-500" />{" "}
          <p className="flex-grow text-sm">
            Dedicated account manager to tweak preferences to match your email organization flow.
          </p>
        </div>
        <div className="mx-auto mb-6 flex max-w-md items-center justify-center gap-x-3">
          <Sparkle size={20} className="flex-shrink-0 stroke-purple-500" />{" "}
          <p className="flex-grow text-sm">
            Access to all of Fyxer AI's features including Pro AI model, note taker, and calendar scheduler.
          </p>
        </div>
        <div className="mx-auto mb-10 flex max-w-md items-center justify-center gap-x-3">
          <Rocket size={20} className="flex-shrink-0 stroke-purple-500" />{" "}
          <p className="flex-grow text-sm">
            For businesses with 100+ employees, pilot requires minimum 25 participants.
          </p>
        </div>
        <div className="flex flex-col items-center">
          <Button
            data-cal-namespace="conta"
            data-cal-link="forms/fd6d69b9-72cb-4056-a61b-aecc6a27c981"
            data-cal-config='{"layout":"month_view"}'
            className="mb-2 w-full max-w-xs"
            onClick={() => logButtonPress(EventButton.BOOK_A_CALL_CLICK, { source: "popup" })}
          >
            Book a call
          </Button>
          <Button onClick={onHide} variant="link" className="text-gray-500">
            Skip offer, continue with 7 day free trial
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ConnectionsGuard = ({ children }: { children: ReactNode }) => {
  const [showFreeTrialPopup, setShowFreeTrialPopup] = useState(true);
  const [showWhiteGlovePopup, setShowWhiteGlovePopup] = useState(true);
  const { userMembership, planData, connections, organisationId, recallZoomConnections, organisation } =
    useOrganisation();
  const { enrichedData } = useBase();
  const { user } = useUser();
  const { userId } = useAuth();
  const location = useLocation();
  const { logButtonPress } = useAnalytics();
  const optionalCalendarGuard = useFeatureFlag("optional-calendar-guard");

  const { planType, planTypeSource } = planData;
  const { role: userRole } = userMembership.data();
  const canInviteUsers = (
    organisation.rolesThatCanInviteUsers ?? organisationConfigDefaults.rolesThatCanInviteUsers
  ).includes(userRole);

  const connectionsThatCantBeOutOfDate = connections.filter(
    unwrap((connection) => !connection.isOrgLevel || connection.userId === userId),
  );
  const outOfDateConnections = connectionsThatCantBeOutOfDate.filter(
    unwrap((connection) => {
      const { approvedScopes, integration } = connection;
      const requestedScopes = getScopes({ integration, mode: "validate" });
      const hasAllScopes = requestedScopes.every((scope) => approvedScopes.includes(scope));
      return !hasAllScopes;
    }),
  );

  const [state, updateState] = useUpdateState({
    hasSeenZoomConnectionPage: storage.local.boolean(LocalStorageBooleanKey.WAS_ZOOM_CONNECTION_SKIPPED).get(),
    hasSeenTeamsSignupPage: storage.local.boolean(LocalStorageBooleanKey.WAS_TEAMS_SIGNUP_SKIPPED).get(),
    hasSeenCalendarGuard: storage.local.boolean(LocalStorageBooleanKey.SKIPPED_CALENDAR_GUARD).get(),
  });

  const emailConnections = connections.filter(
    unwrap((connection) => connection.serviceType === OauthServiceType.EMAIL),
  );

  const calendarConnections = connections.filter(
    unwrap((connection) => connection.serviceType === OauthServiceType.CALENDAR),
  );

  const allowSkippingCalendar = calendarConnections.length === 0 && optionalCalendarGuard.value() === "test";

  const hasSeenFreeTrialPopup = storage.local.boolean(LocalStorageBooleanKey.SEEN_CC_UPFRONT_OFFER).get();

  // don't show guard when processing stripe payment
  if (
    location.pathname.includes("/checkout-session") ||
    location.hash.includes("#tab=billing") ||
    location.hash.includes("#tab=team") ||
    location.hash.includes("#tab=details")
  ) {
    return children;
  }

  if (outOfDateConnections.length > 0) {
    return <OutOfDateConnectionsPage outOfDateConnections={outOfDateConnections} />;
  }

  if (planType !== "FREE" && (emailConnections.length === 0 || calendarConnections.length === 0)) {
    if (emailConnections.length === 0) {
      return <NoEmailConnectionPage userMembership={userMembership} />;
    } else if (calendarConnections.length === 0) {
      if (allowSkippingCalendar) {
        if (!state.hasSeenCalendarGuard) {
          return (
            <NoCalendarConnectionPage
              organisationId={organisationId}
              onSkip={() => {
                storage.local.boolean(LocalStorageBooleanKey.SKIPPED_CALENDAR_GUARD).set(true);
                updateState({ hasSeenCalendarGuard: true });
              }}
            />
          );
        }
      } else {
        return <NoCalendarConnectionPage organisationId={organisationId} />;
      }
    }
  }

  const isOnFreeTrialOrFreePlan = planType === "FREE" || planTypeSource === PlanTypeSource.FREE_TRIAL;
  const hasCustomDomain = Boolean(parseCustomEmailDomain(user.email));

  const hasSeenCSPopup = storage.local.boolean(LocalStorageBooleanKey.SEEN_BOOK_CS_CALL).get();
  const orgSize = enrichedData.value?.enrichedIndividualData?.org?.numberOfEmployees;
  const showPopup = isOnFreeTrialOrFreePlan && !hasSeenFreeTrialPopup && showFreeTrialPopup;
  const shouldGetWhiteGlove =
    hasCustomDomain && !hasSeenCSPopup && (orgSize ?? 0) >= 50 && isOnFreeTrialOrFreePlan && showWhiteGlovePopup;

  const onSkip = () => {
    setShowFreeTrialPopup(false);
    logButtonPress(EventButton.SKIP_FREE_TRIAL_POPUP);
    storage.local.boolean(LocalStorageBooleanKey.SEEN_CC_UPFRONT_OFFER).set(true);
  };

  if (shouldGetWhiteGlove) {
    return (
      <BookingPopup
        onHide={() => {
          setShowWhiteGlovePopup(false);
          storage.local.boolean(LocalStorageBooleanKey.SEEN_BOOK_CS_CALL).set(true);
        }}
      />
    );
  } else if (showPopup) {
    return <FreeTrialGuard onSkip={onSkip} calendarEnabled={calendarConnections.length > 0} />;
  }

  if (
    recallZoomConnections.length === 0 &&
    !state.hasSeenZoomConnectionPage &&
    planType !== "FREE" &&
    getWhitelabelProviderFromPageUrl() === WhitelabelProvider.FYXER_AI
  ) {
    return (
      <NoZoomConnectionPage
        organisationId={organisationId}
        markSeen={() => updateState({ hasSeenZoomConnectionPage: true })}
      />
    );
  }

  if (hasCustomDomain && !state.hasSeenTeamsSignupPage && canInviteUsers) {
    return <TeamInvitePage markSeen={() => updateState({ hasSeenTeamsSignupPage: true })} />;
  }

  return children;
};
