import { constructReferralLink, constructUrl } from "@fyxer-ai/shared";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { format } from "date-fns";
import { Building, ChevronDown, LogOut, User } from "lucide-react";
import { Link } from "react-router-dom";
import { useUser } from "@/context/BaseContext/state/useUser";
import { useBillingBannerData } from "@/context/OrganisationContext/state/useBillingBannerData";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";
import { cn } from "@/lib/utils";
import { useSignOut } from "@/routes/auth/actions/useSignOut";
import { PlanTypeSource } from "@/routes/org/[organisationId]/settings/tabs/billing/getPlanType";

import { CopyIconButton } from "../controls/CopyUtil";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Separator } from "../ui/separator";
import { UserAvatar } from "./UserAvatar";
import { getLogoSrc } from "@/lib/getWhitelabelProvider";
const BillingHeaderItem = ({
  organisationId,
  planTypeSource,
  formattedTimeLeftInFreeTrial,
}: {
  organisationId: string;
  planTypeSource: PlanTypeSource;
  formattedTimeLeftInFreeTrial: string;
}) => {
  const { logButtonPress } = useAnalytics();

  if (!organisationId || !planTypeSource) return null;

  switch (planTypeSource) {
    case PlanTypeSource.FREE_TRIAL:
    case PlanTypeSource.NONE:
      return (
        <>
          <div className="flex flex-shrink-0 items-center gap-x-2">
            {planTypeSource === PlanTypeSource.FREE_TRIAL ? (
              <p className="hidden sm:block">{formattedTimeLeftInFreeTrial} left in free trial</p>
            ) : (
              <p className="hidden sm:block">Your free trial has ended</p>
            )}
            <Link
              to={constructUrl({
                path: `/org/${organisationId}/settings`,
                hash: {
                  tab: "billing",
                },
              })}
              onClick={() => logButtonPress(EventButton.UPGRADE, { location: "navbar" })}
            >
              <Button size="sm">Upgrade</Button>
            </Link>
          </div>
        </>
      );
    case PlanTypeSource.PRIVATE_BETA:
    case PlanTypeSource.SUBSCRIPTION:
    default:
      return null;
  }
};

export const ReferralTermsView = ({
  referralLink,
  size = "md",
}: {
  referralLink: string | undefined;
  size?: "sm" | "md";
}) => (
  <div className="space-y-4">
    <div className="space-y-2">
      <p className={size === "sm" ? "text-sm text-slate-500" : ""}>Every time someone signs up through your link:</p>
      <ul className="list-inside list-disc pl-2">
        <li className={size === "sm" ? "text-sm text-slate-500" : ""}>
          If you have a paid plan, we'll give you $25 of credit in your invoices
        </li>
        <li className={size === "sm" ? "text-sm text-slate-500" : ""}>
          If not, we'll give you 4 weeks of the product free (in addition to any you've already accrued)
        </li>
      </ul>
    </div>

    {referralLink && (
      <div className="relative h-12 rounded-xl bg-slate-100">
        <p className="absolute left-4 top-1/2 w-[80%] -translate-y-1/2 overflow-hidden text-ellipsis whitespace-nowrap">
          {referralLink}
        </p>
        <CopyIconButton value={referralLink} className="absolute right-2 top-1/2 -translate-y-1/2" />
      </div>
    )}
  </div>
);

export const RecentReferralsView = ({ referralLink }: { referralLink: string | undefined }) => {
  const { userReferrals } = useUser();

  if (!referralLink) return null;

  return (
    <>
      <div className="w-full space-y-2">
        <h4>Your recent referrals</h4>
        {userReferrals.length > 0 ? (
          userReferrals.map((userReferral) => {
            const { createdAt, referredUser } = userReferral.data();
            const { name, email } = referredUser;
            return (
              <div key={userReferral.id} className="flex w-full items-center gap-x-4 overflow-hidden">
                <span className="w-0 flex-grow overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                  {name} ({email})
                </span>
                <span className="flex-shrink-0 text-sm text-slate-500">{format(createdAt, "do MMM yyyy")}</span>
              </div>
            );
          })
        ) : (
          <p className="text-sm text-slate-500">You haven't referred anyone yet</p>
        )}
      </div>
    </>
  );
};

const ReferralHeaderItem = ({ referralLink }: { referralLink: string }) => (
  <Dialog>
    <DialogTrigger asChild>
      <Button size="sm">Refer a friend</Button>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Refer a friend</DialogTitle>
      </DialogHeader>
      <ReferralTermsView referralLink={referralLink} size="sm" />
      <Separator className="bg-slate-200" />
      <RecentReferralsView {...{ referralLink }} />
    </DialogContent>
  </Dialog>
);

export const FeatureReminderHeaderItem = ({ organisationId }: { organisationId: string | undefined }) => {
  // Build the URL to your Settings page, linking to the calendar/drafts section
  const draftsSectionUrl = constructUrl({
    path: organisationId ? `/org/${organisationId}/settings` : "/org",
    search: { section: "calendar_drafts" },
  });

  return (
    <div className="flex w-full items-center justify-center gap-x-2">
      <div className="flex flex-col">
        <p className="hidden sm:block">
          <b className="text-green">Beta feature:</b> Save time scheduling with our new booking link.
        </p>
      </div>
      <div className="hidden px-4 sm:block">
        <Link to={draftsSectionUrl}>
          <Button size="sm">Enable now</Button>
        </Link>
      </div>
    </div>
  );
};

const HeaderItem = () => {
  const { user } = useUser();
  const { slug } = user;
  const referralLink = slug ? constructReferralLink(slug) : undefined;

  const {
    organisationId,
    planTypeSource,
    formattedTimeLeftInFreeTrial,
    shouldShowBanner: shouldShowBillingBanner,
    userMembership,
  } = useBillingBannerData();

  const { shouldUseFyxerSchedulingLink } = userMembership
    ? userMembership.data()
    : { shouldUseFyxerSchedulingLink: true };

  if (!shouldUseFyxerSchedulingLink) return <FeatureReminderHeaderItem organisationId={organisationId} />;

  if (shouldShowBillingBanner && organisationId && planTypeSource)
    return (
      <BillingHeaderItem
        organisationId={organisationId}
        planTypeSource={planTypeSource}
        formattedTimeLeftInFreeTrial={formattedTimeLeftInFreeTrial}
      />
    );

  if (referralLink) return <ReferralHeaderItem referralLink={referralLink} />;

  return null;
};

export const Logo = ({ className }: { className?: string }) => {
  return <img className={cn("h-6 object-fill sm:h-8", className)} src={getLogoSrc()} />;
};

export const TopBar = () => {
  const { user } = useUser();
  const signOut = useSignOut();

  return (
    <div className="flex h-12 w-full flex-shrink-0 items-center border-b-2 border-slate-100">
      <Link to="/" className="flex-shrink-0">
        <Logo className="pl-4" />
      </Link>

      <div className="flex w-full items-center gap-x-4 bg-white">
        <div className="flex-grow"></div>
        <HeaderItem />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex h-full cursor-pointer items-center gap-1 pr-3">
              <UserAvatar name={user.name} photoUrl={user.photoUrl} />
              <ChevronDown size={16} />
            </div>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <Link to="/org">
              <DropdownMenuItem className="cursor-pointer">
                <Building className="mr-2 h-4 w-4" />
                <span>Organisations</span>
              </DropdownMenuItem>
            </Link>
            <Link to="/profile">
              <DropdownMenuItem className="cursor-pointer">
                <User className="mr-2 h-4 w-4" />
                <span>Profile</span>
              </DropdownMenuItem>
            </Link>
            <DropdownMenuItem onClick={() => signOut.mutate()} className="cursor-pointer">
              <LogOut className="mr-2 h-4 w-4" />
              <span>Log out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
