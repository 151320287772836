import { useMutation } from "@tanstack/react-query";
import { addDoc } from "firebase/firestore";
import { Collection } from "@/lib/firebase/Collection";
import { useBase } from "@/context/BaseContext/state/useBase";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useApi } from "@/hooks/useApi";
import { EventName, useAnalytics } from "@/hooks/useAnalytics";

export const useChatMessageSender = (callRecordingId: string, currentThreadId: string | null) => {
  const api = useApi();
  const { authUser } = useBase();
  const { updateState } = useCallRecording();
  const { logEvent } = useAnalytics();

  return useMutation({
    mutationFn: async ({ message, clearThread }: { message: string; clearThread?: boolean }) => {
      if (!callRecordingId) throw new Error("No call recording ID");
      if (!authUser.value) throw new Error("No user");
      let threadId = clearThread ? null : currentThreadId;

      logEvent(EventName.CHAT_MESSAGE_SENT, {
        callRecordingId,
        currentThreadId,
      });

      if (!threadId) {
        const newThread = await addDoc(Collection.CallRecordingChatThread, {
          callRecordingId,
          title: message,
          createdAt: new Date(),
          updatedAt: new Date(),
          userId: authUser.value.uid,
        });

        updateState({ currentThreadId: newThread.id });
        threadId = newThread.id;
      }

      addDoc(Collection.CallRecordingChatMessage, {
        callChatThreadId: threadId,
        content: message,
        role: "user",
        timestamp: new Date(),
      });

      const { message: assistantMessage, relevantTranscriptSegments } = await api.callChatThreads
        .id(threadId)
        .sendMessage({
          message,
        });

      return {
        assistantMessage,
        relevantTranscriptSegments,
        threadId,
      };
    },
    onSuccess: ({ assistantMessage, relevantTranscriptSegments, threadId }) => {
      addDoc(Collection.CallRecordingChatMessage, {
        callChatThreadId: threadId,
        content: assistantMessage,
        role: "assistant",
        timestamp: new Date(),
        relevantTranscriptSegments,
      });
    },
    onError: () => {
      if (!currentThreadId) return;

      addDoc(Collection.CallRecordingChatMessage, {
        callChatThreadId: currentThreadId,
        content: "Something went wrong. Please try again.",
        role: "assistant",
        timestamp: new Date(),
        rating: false,
      });
    },
  });
};
