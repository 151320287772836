import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useTranscript } from "../context/TranscriptContext";
import { ArrowDown, ArrowUp, X } from "lucide-react";
import { EventName, useAnalytics } from "@/hooks/useAnalytics";
export const TranscriptSearch = () => {
  const { searchValue, searchIndex, updateState, matchedTranscriptSegmentIds } = useTranscript();

  const isSearching = searchValue.length > 0;

  const { logEvent } = useAnalytics();

  return (
    <div className="mb-2 flex items-center gap-2 px-2">
      <Input
        placeholder="Search transcript..."
        className="w-full border-0 bg-slate-100 pr-32"
        value={searchValue}
        onFocus={() => logEvent(EventName.SEARCH_TRANSCRIPT)}
        onChange={(event) => updateState({ searchValue: event.target.value })}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            if (searchIndex === undefined) return;
            if (searchIndex === matchedTranscriptSegmentIds.length - 1) return updateState({ searchIndex: 0 });
            const newIndex = searchIndex! + 1;
            updateState({ searchIndex: newIndex });
          }
        }}
      />
      {isSearching && (
        <div className="absolute right-2 flex items-center gap-1">
          <p className="text-sm text-slate-500">
            {searchIndex !== undefined ? Math.min(searchIndex + 1, matchedTranscriptSegmentIds.length) : 0} /{" "}
            {matchedTranscriptSegmentIds.length}
          </p>
          <Button
            size="icon"
            variant="ghost"
            className="h-6 w-6"
            disabled={searchIndex === 0 || searchIndex === undefined}
            onClick={() => {
              const newIndex = searchIndex! - 1;
              updateState({ searchIndex: newIndex });
            }}
          >
            <ArrowUp className="h-3 w-3 stroke-slate-500" />
          </Button>
          <Button
            size="icon"
            variant="ghost"
            className="h-6 w-6"
            disabled={
              searchIndex === matchedTranscriptSegmentIds.length - 1 || matchedTranscriptSegmentIds.length === 0
            }
            onClick={() => {
              const newIndex = searchIndex! + 1;
              updateState({ searchIndex: newIndex });
            }}
          >
            <ArrowDown className="h-3 w-3 stroke-slate-500" />
          </Button>
          <Button
            size="icon"
            variant="ghost"
            className="h-6 w-6"
            onClick={() => updateState({ searchValue: "", searchIndex: undefined })}
          >
            <X className="h-3 w-3 stroke-slate-500" />
          </Button>
        </div>
      )}
    </div>
  );
};
