import { date, parseCustomEmailDomain } from "@fyxer-ai/shared";
import { useMutation } from "@tanstack/react-query";
import { addMinutes } from "date-fns";
import { Loader2 } from "lucide-react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { Progress } from "@/components/ui/progress";
import { useMembershipsActions } from "@/context/BaseContext/actions/useMembershipsActions";
import { useMemberships } from "@/context/BaseContext/state/useMemberships";
import { useUser } from "@/context/BaseContext/state/useUser";
import { EventPage, useLogPageView } from "@/hooks/useAnalytics";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";
import { useUpdateState } from "@/hooks/useUpdateState";
import { unwrap } from "@/lib/firebase/unwrap";

import { getSuggestedOrganisationName } from "./create";
import { isExpAgentCase } from "@/lib/getWhitelabelProvider";

export const ListOrganisationsPage = () => {
  useLogPageView(EventPage.LIST_ORGANISATIONS);

  const { memberships } = useMemberships();
  const organisationIds = memberships.map(unwrap((membership) => membership.organisationId));
  const { createOrg, joinOrgIfNeeded } = useMembershipsActions();
  const { user } = useUser();
  const { createdAt: userCreatedDate } = user;
  const emailDomain = parseCustomEmailDomain(user.email);
  const navigate = useComplexNavigate();
  const [state, updateState] = useUpdateState({ autoCreateOrgProgress: 20, isAutoCreating: false });

  const hasMembership = memberships.length > 0;
  const thirtyMinutesAgo = addMinutes(date.now(), -30);
  const shouldAutoCreateOrg = !hasMembership && userCreatedDate > thirtyMinutesAgo;

  const createOrganisation = useMutation({
    mutationFn: async () => {
      try {
        const organisationIdFromJoinedOrg = await joinOrgIfNeeded();
        if (organisationIdFromJoinedOrg) return;

        if (emailDomain && isExpAgentCase(emailDomain.toLowerCase())) {
          await navigate("/org/create");
          return;
        }
        const name = getSuggestedOrganisationName(user);
        await createOrg({ name });

        updateState({ autoCreateOrgProgress: 100 });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        navigate("/org/create");
      }
    },
  });

  useEffect(() => {
    if (state.isAutoCreating && organisationIds.length > 0)
      navigate({
        path: `/org/${organisationIds[0]}/settings`,
        hash: { tab: "integrations" },
      });
  }, [organisationIds, state.isAutoCreating, navigate]);

  useEffect(() => {
    if (!shouldAutoCreateOrg) return;
    if (createOrganisation.isSuccess || createOrganisation.isPaused || createOrganisation.isPending) return;
    if (state.isAutoCreating) return;
    updateState({ isAutoCreating: true });
    createOrganisation.mutate();
  }, [shouldAutoCreateOrg, createOrganisation, state.isAutoCreating, updateState]);

  if (shouldAutoCreateOrg)
    return (
      <div className="flex h-full items-center justify-center">
        <div className="w-128 space-y-8">
          <Loader2 className="m-auto animate-spin" size={50} />
          <div className="space-y-2">
            <h2 className="text-center">Setting up your account</h2>
            <p className="text-center text-slate-500">This usually takes 10 seconds</p>
          </div>
          <Progress className="m-auto w-96" value={state.autoCreateOrgProgress} />
        </div>
      </div>
    );

  if (organisationIds.length === 0) return <Navigate to="/org/create" />;

  return <Navigate to={`/org/${organisationIds[0]}/settings`} />;
};
