// basename is filename without extension

const getContentTypeFromExtension = (extensionName: string): string => {
  switch (extensionName) {
    case "mp4":
      return "video/mp4";
    case "text":
    default:
      return "text/plain";
  }
};

export const downloadFile = ({
  content,
  baseName,
  extensionName = "text",
}: {
  content: string | Blob;
  baseName: string;
  extensionName?: "text" | "mp4";
}) => {
  const contentType = getContentTypeFromExtension(extensionName);
  const blob = typeof content === "string" ? new Blob([content], { type: contentType }) : content;
  const fileName = `${baseName}.${extensionName}`;
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a"); // Create a new anchor element
  link.href = url; // Set the href to the Blob URL
  link.download = fileName; // Set the desired file name
  document.body.appendChild(link); // Append the link to the body (required for Firefox)
  link.click(); // Programmatically click the link to trigger the download
  document.body.removeChild(link);
  URL.revokeObjectURL(url); // Clean up the URL object
};
