import { OauthIntegration, OauthProvider } from "@fyxer-ai/shared";
import { LucideIcon, Pen, Tag, Video, X } from "lucide-react";
import { forwardRef, HTMLAttributes, useRef } from "react";

import { Container } from "@/components/layout/Container";
import { EventPage, useLogPageView } from "@/hooks/useAnalytics";
import { useUpdateState } from "@/hooks/useUpdateState";
import { cn } from "@/lib/utils";
import { GoogleDisclosure } from "@/routes/org/[organisationId]/settings/tabs/integrations/integrationData";
import { useOauthRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/useOauthRedirect";
import { ConnectionButton } from "./EmailConnectionGuard";
import { getCompanyName } from "@/lib/getWhitelabelProvider";
import { Button } from "@/components/ui/button";

const CircleIcon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { Icon: LucideIcon }>(
  ({ Icon, className, ...props }, ref) => (
    <div
      className={cn("flex h-12 w-12 items-center justify-center rounded-full bg-purple-100", className)}
      {...props}
      ref={ref}
    >
      <Icon className="stroke-purple-500" size={24} />
    </div>
  ),
);

export const NoCalendarConnectionPage = ({
  organisationId,
  onSkip,
}: {
  organisationId: string;
  onSkip?: () => void;
}) => {
  useLogPageView(EventPage.NO_CALENDAR_CONNECTION);

  const [state, updateState] = useUpdateState<{ redirectingIntegration: OauthIntegration | undefined }>({
    redirectingIntegration: undefined,
  });
  const { handleOauthRedirect } = useOauthRedirect();
  const buttonsRef = useRef<HTMLDivElement>(null);

  const connectIntegration = async (integration: OauthIntegration) => {
    updateState({ redirectingIntegration: integration });
    const isGoogle = [OauthIntegration.GMAIL, OauthIntegration.GOOGLE_CALENDAR].includes(integration);
    const rest = isGoogle
      ? { type: "provider" as const, provider: OauthProvider.GOOGLE }
      : { type: "integration" as const, integration };
    await handleOauthRedirect({ organisationId, ...rest });
    updateState({ redirectingIntegration: undefined });
  };

  return (
    <Container className="relative max-w-2xl space-y-12 py-12">
      {!!onSkip ? (
        <X size={32} className="absolute right-4 top-4 block cursor-pointer sm:hidden" onClick={onSkip} />
      ) : null}

      <h1 className="text-3xl">Connect your calendar</h1>

      <div className="space-y-4 pb-12 sm:pb-0">
        <p>{getCompanyName()} works by connecting to your email and calendar. Once we're connected, we'll:</p>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Tag} className="flex-shrink-0" />
          <p>Screen low priority emails from your inbox and highlight what's important</p>
        </div>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Pen} className="flex-shrink-0" />
          <p>Leave draft replies in email threads you need to reply to or follow up on</p>
        </div>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Video} className="flex-shrink-0" />
          <p>Join meetings and recording, transcribe and take notes</p>
        </div>
        <p>We never send email on your behalf. We leave drafts for you to edit and send.</p>
        <p>If it doesn't work out with us, we'll leave your inbox as we found it.</p>
      </div>

      <div
        className="space-y-4 max-sm:fixed max-sm:bottom-0 max-sm:left-0 max-sm:w-full max-sm:border-t max-sm:border-slate-100 max-sm:bg-white max-sm:p-4"
        ref={buttonsRef}
      >
        <div className="max-sm:space-y-2 sm:flex sm:items-center sm:gap-x-4">
          <ConnectionButton
            {...{
              integration: OauthIntegration.GOOGLE_CALENDAR,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
          <ConnectionButton
            {...{
              integration: OauthIntegration.MICROSOFT_OUTLOOK_CALENDAR,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
        </div>

        <GoogleDisclosure />

        {!!onSkip ? (
          <div className="pt-4 text-center">
            <Button onClick={onSkip} variant="ghost">
              Skip connection and setup later
            </Button>
          </div>
        ) : null}
      </div>
    </Container>
  );
};
