import {
  CalendarEvent,
  CallAnalysis,
  CallRecording,
  CallRecordingSnippet,
  CallSummary,
  CheckoutSession,
  CollectionName,
  EmailConnectionSetupStatus,
  EmailMessage,
  EmailSignUp,
  OrganisationTeam,
  EmailUnsubscribe,
  FreeTrial,
  getType,
  Invite,
  MeetingAssistantVeto,
  Membership,
  OauthClient,
  OauthConnection,
  Organisation,
  RecallZoomConnection,
  RemoteConfig,
  SingleSignOnDetails,
  Subscription,
  SystemSuggestedSlot,
  TranscriptSegment,
  Type,
  User,
  UserReferral,
  CalendarSchedulingRequest,
  EnrichedIndividualData,
  CallRecordingChatThread,
  CallRecordingChatMessage,
} from "@fyxer-ai/shared";
import {
  collection,
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";
import { z } from "zod";

import { db } from "./app";

const timestampSchema = z.object({
  seconds: z.number(),
  nanoseconds: z.number(),
});

const _timestampSchema = z.object({
  _seconds: z.number(),
  _nanoseconds: z.number(),
});

const isTimestamp = (value: unknown) => {
  if (value instanceof Timestamp) return true;

  if (timestampSchema.safeParse(value).success) return true;
  if (_timestampSchema.safeParse(value).success) return true;

  return false;
};

const convertTimestamp = (value: unknown) => {
  if (value instanceof Timestamp) return value.toDate();

  const parsed = timestampSchema.safeParse(value);

  if (parsed.success) {
    const { seconds, nanoseconds } = parsed.data;
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  }

  const _parsed = _timestampSchema.safeParse(value);

  if (_parsed.success) {
    const { _seconds, _nanoseconds } = _parsed.data;
    return new Date(_seconds * 1000 + _nanoseconds / 1000000);
  }

  throw new Error(`Could not convert timestamp: ${JSON.stringify(value)}`);
};

export const recursivelyConvertTimestampsToDates = (data: unknown): unknown => {
  if (isTimestamp(data)) return convertTimestamp(data);

  switch (getType(data)) {
    case Type.ARRAY:
      return (data as unknown[]).map(recursivelyConvertTimestampsToDates);
    case Type.OBJECT:
      return Object.entries(data as object).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: recursivelyConvertTimestampsToDates(value),
        }),
        {},
      );
    default:
      return data;
  }
};

const converter = <T extends { [key: string]: unknown }>(): FirestoreDataConverter<T> => ({
  toFirestore(data: WithFieldValue<T>): DocumentData {
    return data;
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<T>, options: SnapshotOptions) {
    const data = snapshot.data(options);
    return recursivelyConvertTimestampsToDates(data) as T;
  },
});

const ref = <T>(collectionName: CollectionName) =>
  collection(db, collectionName).withConverter(converter()) as CollectionReference<T>;

export const Collection = {
  [CollectionName.USER]: ref<User>(CollectionName.USER),
  [CollectionName.ORGANISATION]: ref<Organisation>(CollectionName.ORGANISATION),
  [CollectionName.MEMBERSHIP]: ref<Membership>(CollectionName.MEMBERSHIP),
  [CollectionName.INVITE]: ref<Invite>(CollectionName.INVITE),
  [CollectionName.ENRICHED_INDIVIDUAL_DATA]: ref<EnrichedIndividualData>(CollectionName.ENRICHED_INDIVIDUAL_DATA),
  [CollectionName.OAUTH_CONNECTION]: ref<OauthConnection>(CollectionName.OAUTH_CONNECTION),
  [CollectionName.EMAIL_MESSAGE]: ref<EmailMessage>(CollectionName.EMAIL_MESSAGE),
  [CollectionName.CHECKOUT_SESSION]: ref<CheckoutSession>(CollectionName.CHECKOUT_SESSION),
  [CollectionName.SUBSCRIPTION]: ref<Subscription>(CollectionName.SUBSCRIPTION),
  [CollectionName.REMOTE_CONFIG]: ref<RemoteConfig>(CollectionName.REMOTE_CONFIG),
  [CollectionName.EMAIL_SIGN_UP]: ref<EmailSignUp>(CollectionName.EMAIL_SIGN_UP),
  [CollectionName.EMAIL_CONNECTION_SETUP_STATUS]: ref<EmailConnectionSetupStatus>(
    CollectionName.EMAIL_CONNECTION_SETUP_STATUS,
  ),
  [CollectionName.SINGLE_SIGN_ON_DETAILS]: ref<SingleSignOnDetails>(CollectionName.SINGLE_SIGN_ON_DETAILS),
  [CollectionName.OAUTH_CLIENT]: ref<OauthClient>(CollectionName.OAUTH_CLIENT),
  [CollectionName.RECALL_ZOOM_CONNECTION]: ref<RecallZoomConnection>(CollectionName.RECALL_ZOOM_CONNECTION),
  [CollectionName.CALL_RECORDING]: ref<CallRecording>(CollectionName.CALL_RECORDING),
  [CollectionName.CALL_ANALYSIS]: ref<CallAnalysis>(CollectionName.CALL_ANALYSIS),
  [CollectionName.CALL_SUMMARY]: ref<CallSummary>(CollectionName.CALL_SUMMARY),
  [CollectionName.TRANSCRIPT_SEGMENT]: ref<TranscriptSegment>(CollectionName.TRANSCRIPT_SEGMENT),
  [CollectionName.CALENDAR_EVENT]: ref<CalendarEvent>(CollectionName.CALENDAR_EVENT),
  [CollectionName.CALENDAR_SCHEDULING_REQUEST]: ref<CalendarSchedulingRequest>(
    CollectionName.CALENDAR_SCHEDULING_REQUEST,
  ),
  [CollectionName.CALL_RECORDING_SNIPPET]: ref<CallRecordingSnippet>(CollectionName.CALL_RECORDING_SNIPPET),
  [CollectionName.USER_REFERRAL]: ref<UserReferral>(CollectionName.USER_REFERRAL),
  [CollectionName.FREE_TRIAL]: ref<FreeTrial>(CollectionName.FREE_TRIAL),
  [CollectionName.EMAIL_UNSUBSCRIBE]: ref<EmailUnsubscribe>(CollectionName.EMAIL_UNSUBSCRIBE),
  [CollectionName.MEETING_ASSISTANT_VETO]: ref<MeetingAssistantVeto>(CollectionName.MEETING_ASSISTANT_VETO),
  [CollectionName.SYSTEM_SUGGESTED_SLOT]: ref<SystemSuggestedSlot>(CollectionName.SYSTEM_SUGGESTED_SLOT),
  [CollectionName.ORGANISATION_TEAM]: ref<OrganisationTeam>(CollectionName.ORGANISATION_TEAM),
  [CollectionName.CALL_RECORDING_CHAT_THREAD]: ref<CallRecordingChatThread>(CollectionName.CALL_RECORDING_CHAT_THREAD),
  [CollectionName.CALL_RECORDING_CHAT_MESSAGE]: ref<CallRecordingChatMessage>(
    CollectionName.CALL_RECORDING_CHAT_MESSAGE,
  ),
};
