import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { useMemberships } from "@/context/BaseContext/state/useMemberships";
import { CreditCard, Users, Zap } from "lucide-react";

export const PrivateBetaView = () => {
  const { memberships } = useMemberships();

  return (
    <div>
      <div className="px-4 py-4">
        <h2 className="mb-8">Your subscription</h2>

        <Table className="max-w-96">
          <TableBody>
            <TableRow className="border-b">
              <TableCell className="py-4 pl-0">
                <div className="flex items-center gap-2">
                  <CreditCard className="text-muted-foreground h-5 w-5" />
                  <span className="text-lg">Plan</span>
                </div>
              </TableCell>
              <TableCell className="py-4 pr-0 text-right">
                <Badge variant="secondary" className="bg-slate-900 text-white hover:bg-slate-800">
                  Private Beta
                </Badge>
              </TableCell>
            </TableRow>

            <TableRow className="border-b">
              <TableCell className="py-4 pl-0">
                <div className="flex items-center gap-2">
                  <Zap className="text-muted-foreground h-5 w-5" />
                  <span className="text-lg">Feature access</span>
                </div>
              </TableCell>
              <TableCell className="py-4 pr-0 text-right">
                <span className="font-medium">Pro</span>
              </TableCell>
            </TableRow>

            <TableRow className="border-b">
              <TableCell className="py-4 pl-0">
                <div className="flex items-center gap-2">
                  <Users className="text-muted-foreground h-5 w-5" />
                  <span className="text-lg">Seats</span>
                </div>
              </TableCell>
              <TableCell className="py-4 pr-0 text-right">
                <span className="font-medium">{memberships.length}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
