import { getIsServiceTypeOrgLevel, OauthConnection, OauthServiceType } from "@fyxer-ai/shared";
import { deleteDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { MoreHorizontal, Settings, Trash } from "lucide-react";
import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Textarea } from "@/components/ui/textarea";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventButton, useAnalytics, useFeatureFlag } from "@/hooks/useAnalytics";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

import { ConnectionSyncIndicator } from "./ConnectionSyncIndicator";
import { integrationData, IntegrationDatum } from "./integrationData";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";

export const ConnectionRow = ({ connection }: { connection: QueryDocumentSnapshot<OauthConnection> }) => {
  const { emailConnectionSetupStatuses } = useOrganisation();
  const { logButtonPress } = useAnalytics();
  const navigate = useComplexNavigate();
  const { metadata, integration, serviceType } = connection.data();
  const easyPreferencesExperiment = useFeatureFlag("easy-preferences-experiment");
  const enableEasyPreferences = easyPreferencesExperiment.value() === "test";
  const { title, logoUrl } = integrationData.find((datum) => datum.integration === integration) as IntegrationDatum;
  const email = metadata.email as string | undefined;

  function goToEmailPreferences() {
    return navigate({ path: "", hash: { tab: "configuration" } });
  }

  function goToCalendarPreferences() {
    return navigate({ path: "", search: { section: "calendar_drafts" }, hash: { tab: "configuration" } });
  }

  return (
    <div
      className={`flex items-center ${enableEasyPreferences ? "gap-x-2" : "gap-x-4"}`}
      key={`connection-row-${connection.id}`}
    >
      <div className="max-sm:space-y-2 sm:flex sm:items-center sm:gap-x-4">
        <div className="flex w-24 items-center gap-x-2">
          <img alt={title} src={logoUrl} className="h-8 w-8 rounded-sm" />
          <h4>{title}</h4>
        </div>

        {getIsServiceTypeOrgLevel(serviceType) ? <Badge>Org-wide</Badge> : null}
        {email ? <Badge variant="secondary">{email}</Badge> : null}
      </div>

      <div className="flex-grow" />
      <ConnectionSyncIndicator {...{ connection, emailConnectionSetupStatuses }} />

      {enableEasyPreferences && (
        <Button
          onClick={() => {
            if (serviceType === OauthServiceType.EMAIL) {
              goToEmailPreferences();
            } else {
              goToCalendarPreferences();
            }
          }}
          size="sm"
          variant="ghost"
          className="ml-3 flex-shrink-0"
        >
          <Settings size={16} />
        </Button>
      )}

      <AlertDialog>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-9 w-9 p-0">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="cursor-pointer hover:bg-slate-100">
            <AlertDialogTrigger asChild>
              <DropdownMenuItem>
                <Trash className="mr-2 h-4 w-4" />
                Delete integration
              </DropdownMenuItem>
            </AlertDialogTrigger>
          </DropdownMenuContent>
        </DropdownMenu>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              Deleting this integration will also delete all data associated with it. This action isn't reversible.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <FormUtil
            schema={z.object({ deleteReason: makeSchemaSafe(z.string().min(1).max(2000)) })}
            defaultValues={{ deleteReason: "" }}
            render={(form) => (
              <FormFieldUtil
                control={form.control}
                name="deleteReason"
                render={({ field }) => <Textarea {...field} />}
              />
            )}
            onSubmit={async ({ deleteReason }) => {
              logButtonPress(EventButton.DELETE_CONNECTION, {
                connectionId: connection.id,
                deleteReason,
              });
              await deleteDoc(connection.ref);
            }}
            renderSubmitButton={(props) => (
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <Button className="bg-red-500" {...props}>
                  Delete connection
                </Button>
              </AlertDialogFooter>
            )}
          />
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
