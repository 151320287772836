import {
  CallAnalysis,
  CallRecording,
  CallRecordingChatThread,
  CallRecordingSnippet,
  CallSummary,
  constructUrl,
  first,
  TranscriptSegment,
} from "@fyxer-ai/shared";
import { useMutation } from "@tanstack/react-query";
import { doc, FirestoreError, onSnapshot, orderBy, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { CenterPage } from "@/components/layout/CenterPage";
import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import { Spinner } from "@/components/layout/SpinnerPage";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useApi } from "@/hooks/useApi";
import { useUpdateState } from "@/hooks/useUpdateState";
import { Collection } from "@/lib/firebase/Collection";
import { emptyLoadedValue, LoadedValue } from "@/types/LoadedValue";

import { useBase } from "../BaseContext/state/useBase";
import { AxiosError } from "axios";

type CallRecordingState = {
  callRecordingId: LoadedValue<string>;
  callRecording: LoadedValue<CallRecording>;
  callAnalysis: LoadedValue<QueryDocumentSnapshot<CallAnalysis>>;
  callSummary: LoadedValue<QueryDocumentSnapshot<CallSummary>>;
  transcriptSegments: LoadedValue<QueryDocumentSnapshot<TranscriptSegment>[]>;
  callRecordingSnippets: LoadedValue<QueryDocumentSnapshot<CallRecordingSnippet>[]>;
  callChatThreads: LoadedValue<QueryDocumentSnapshot<CallRecordingChatThread>[]>;
  currentThreadId: string | null;
  updateState: (updates: Partial<CallRecordingState>) => void;
};

const initialCallRecordingState: CallRecordingState = {
  callRecordingId: emptyLoadedValue,
  callRecording: emptyLoadedValue,
  callAnalysis: emptyLoadedValue,
  callSummary: emptyLoadedValue,
  transcriptSegments: emptyLoadedValue,
  callRecordingSnippets: emptyLoadedValue,
  callChatThreads: emptyLoadedValue,
  currentThreadId: null,
  updateState: () => {},
};

export const CallRecordingContext = createContext<CallRecordingState>(initialCallRecordingState);

const AuthedNoPermissionView = ({ callRecordingId }: { callRecordingId: string }) => {
  const api = useApi();
  const { toast } = useToast();
  const navigate = useNavigate();

  const sendAccessRequest = useMutation({
    mutationFn: async () => api.callRecordings.id(callRecordingId).sendAccessRequest(),
    onSuccess: () => toast({ title: "Access request sent" }),
    onError: (error) => {
      if (
        error instanceof AxiosError &&
        error.response?.status === 404 &&
        error.response.data.message === "Call recording not found"
      ) {
        toast({ title: "Call recording not found" });
        return;
      }

      toast({ title: "Failed to send access request", description: error.message });
    },
  });

  return (
    <CenterPage>
      <div className="space-y-8">
        <h2>Permisson denied</h2>
        <div className="space-y-2">
          <p className="text-slate-500">
            You don't have permission to view this recording (or it may have been deleted). If you were invited to this
            meeting, this will be because someone else in the call has removed your access.
          </p>
          <p className="text-slate-500">If you know the organiser, send them an access request.</p>
        </div>

        <div className="space-y-2">
          <Button onClick={() => sendAccessRequest.mutate()} className="w-full">
            {sendAccessRequest.isPending ? <Spinner /> : "Send access request"}
          </Button>
          <Button variant="secondary" onClick={() => navigate("/")} className="w-full">
            Go back
          </Button>
        </div>
      </div>
    </CenterPage>
  );
};

export const CallRecordingProvider = ({ children }: { children: ReactNode }) => {
  const { callRecordingId } = useParams();

  if (!callRecordingId) throw new Error("call recording id is missing");

  const [state, updateState] = useUpdateState<CallRecordingState>({
    ...initialCallRecordingState,
    callRecordingId: { value: callRecordingId, isLoading: false },
  });

  const { authUser } = useBase();
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);

  const setIsPermissionDeniedIfNeeded = useCallback((error: FirestoreError) => {
    if (error.code !== "permission-denied") return;
    setIsPermissionDenied(true);
  }, []);

  useEffect(() => {
    updateState({
      callRecordingId: { value: callRecordingId, isLoading: false },
      callRecording: emptyLoadedValue,
      callAnalysis: emptyLoadedValue,
      callSummary: emptyLoadedValue,
      transcriptSegments: emptyLoadedValue,
      callRecordingSnippets: emptyLoadedValue,
      callChatThreads: emptyLoadedValue,
      currentThreadId: null,
    });

    const callRecordingUnsub = onSnapshot(
      doc(Collection.CallRecording, callRecordingId),
      (doc) => updateState({ callRecording: { value: doc.data(), isLoading: false } }),
      setIsPermissionDeniedIfNeeded,
    );

    const callAnalysesUnsub = onSnapshot(
      query(Collection.CallAnalysis, where("callRecordingId", "==", callRecordingId)),
      (snapshot) => updateState({ callAnalysis: { value: first(snapshot.docs), isLoading: false } }),
      setIsPermissionDeniedIfNeeded,
    );

    const callSummariesUnsub = onSnapshot(
      query(Collection.CallSummary, where("callRecordingId", "==", callRecordingId)),
      (snapshot) => updateState({ callSummary: { value: first(snapshot.docs), isLoading: false } }),
      setIsPermissionDeniedIfNeeded,
    );

    const transcriptSegmentsUnsub = onSnapshot(
      query(Collection.TranscriptSegment, where("callRecordingId", "==", callRecordingId), orderBy("startMs", "asc")),
      (snapshot) => updateState({ transcriptSegments: { value: snapshot.docs, isLoading: false } }),
      setIsPermissionDeniedIfNeeded,
    );

    const callRecordingSnippetsUnsub = onSnapshot(
      query(
        Collection.CallRecordingSnippet,
        where("callRecordingId", "==", callRecordingId),
        orderBy("startMs", "asc"),
      ),
      (snapshot) => {
        const callRecordingSnippets = snapshot.docs;
        updateState({ callRecordingSnippets: { value: callRecordingSnippets, isLoading: false } });
      },
      setIsPermissionDeniedIfNeeded,
    );

    const callChatThreadsUnsub = onSnapshot(
      query(
        Collection.CallRecordingChatThread,
        where("callRecordingId", "==", callRecordingId),
        orderBy("createdAt", "desc"),
      ),
      (snapshot) => updateState({ callChatThreads: { value: snapshot.docs, isLoading: false } }),
      setIsPermissionDeniedIfNeeded,
    );

    return () => {
      callRecordingUnsub();
      callAnalysesUnsub();
      callSummariesUnsub();
      transcriptSegmentsUnsub();
      callRecordingSnippetsUnsub();
      callChatThreadsUnsub();
    };
  }, [updateState, callRecordingId, setIsPermissionDeniedIfNeeded]);

  if (isPermissionDenied) {
    if (authUser.isLoading) return <LoadingDashboard />;
    if (!authUser.value)
      return (
        <Navigate
          to={constructUrl({
            path: "/auth/sign-up",
            search: {
              return_path: location.pathname,
            },
          })}
        />
      );

    return <AuthedNoPermissionView callRecordingId={callRecordingId} />;
  }

  return <CallRecordingContext.Provider value={{ ...state, updateState }}>{children}</CallRecordingContext.Provider>;
};
