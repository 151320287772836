import { createRef, useCallback, useEffect, useMemo, useRef } from "react";

import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useUpdateState } from "@/hooks/useUpdateState";

import { TranscriptSearch } from "./components/TranscriptSearch";
import { TranscriptSegment as TranscriptSegmentComponent } from "./components/TranscriptSegment";
import { TranscriptProvider } from "./context/TranscriptContext";
import { useSearchParams } from "react-router-dom";

type State = {
  searchValue: string;
  searchIndex?: number;
};

const TranscriptContent = () => {
  const { transcriptSegments } = useCallRecording();

  return (
    <div className="flex h-full flex-col">
      <TranscriptSearch />

      <div className="flex-1 overflow-y-auto pt-4">
        {transcriptSegments.map((transcriptSegment) => (
          <TranscriptSegmentComponent key={transcriptSegment.id} transcriptSegment={transcriptSegment} />
        ))}
      </div>
    </div>
  );
};

export const TranscriptTab = () => {
  const { transcriptSegments } = useCallRecording();
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, updateState] = useUpdateState<State>({
    searchValue: searchParams.get("query") || "",
    searchIndex: undefined,
  });

  const transcriptSegmentIds = transcriptSegments.map((transcriptSegment) => transcriptSegment.id);

  const transcriptRefs = useRef(transcriptSegments.map(() => createRef<HTMLDivElement>()));

  const scrollToIndex = useCallback((index: number) => {
    setTimeout(() => {
      transcriptRefs.current[index]?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 0);
  }, []);

  const findSegmentByTimestamp = useCallback(
    (timestamp: number) => {
      return transcriptSegments.find((segment) => segment.data().startMs >= timestamp);
    },
    [transcriptSegments],
  );

  const matchedTranscriptSegmentIds = useMemo(() => {
    if (state.searchValue.length < 3) return [];

    // Check for timestamp format
    if (state.searchValue.startsWith("ts:")) {
      const timestamp = parseInt(state.searchValue.slice(3));
      if (!isNaN(timestamp)) {
        const segment = findSegmentByTimestamp(timestamp);
        if (segment) {
          return [segment.id];
        }
      }
    }

    // Regular text search
    const searchValue = state.searchValue.toLowerCase();
    return transcriptSegments
      .filter((transcriptSegment) => transcriptSegment.data().text.toLowerCase().includes(searchValue))
      .map((transcriptSegment) => transcriptSegment.id);
  }, [state.searchValue, transcriptSegments, findSegmentByTimestamp]);

  // Handle timestamp search
  useEffect(() => {
    if (state.searchValue.startsWith("ts:")) {
      const timestamp = parseInt(state.searchValue.slice(3));
      if (!isNaN(timestamp)) {
        const segment = findSegmentByTimestamp(timestamp);
        if (segment) {
          updateState({ searchIndex: transcriptSegments.indexOf(segment) });
        }
      }
    }
  }, [state.searchValue, findSegmentByTimestamp, transcriptSegments, updateState]);

  useEffect(() => {
    if (state.searchIndex === undefined) return;
    scrollToIndex(state.searchIndex);
  }, [state.searchIndex, scrollToIndex]);

  useEffect(() => {
    if (state.searchValue.length < 3) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.delete("query");
        return newParams;
      });
      return updateState({ searchIndex: undefined });
    }
    if (state.searchIndex !== undefined) return;
    updateState({ searchIndex: 0 });
  }, [state.searchValue, state.searchIndex, updateState, setSearchParams]);

  // Update URL when search query changes
  useEffect(() => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      if (state.searchValue) {
        newParams.set("query", state.searchValue);
      } else {
        newParams.delete("query");
      }
      return newParams;
    });
  }, [state.searchValue, setSearchParams]);

  const contextValue = {
    ...state,
    transcriptSegments,
    transcriptSegmentIds,
    updateState,
    matchedTranscriptSegmentIds,
    transcriptRefs: transcriptRefs.current,
  };

  return (
    <TranscriptProvider value={contextValue}>
      <TranscriptContent />
    </TranscriptProvider>
  );
};
