import {
  AutoJoinMeetingConfiguration,
  LabelName,
  configDefaults,
  deduplicate,
  getFriendlyLabelName,
  sort,
} from "@fyxer-ai/shared";
import { ChevronDown, ChevronUp, Info, Hand } from "lucide-react";
import { forwardRef, useRef, useState, ReactNode } from "react";
import { QueryDocumentSnapshot, updateDoc } from "firebase/firestore";

import { Button, ButtonProps } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Container } from "@/components/layout/Container";
import { Spinner } from "@/components/layout/SpinnerPage";
import { EventPage, useFeatureFlag, useLogPageView } from "@/hooks/useAnalytics";
import { useUpdateState } from "@/hooks/useUpdateState";
import { useBreakpoint } from "@/hooks/useWindowSize";
import { useOauthRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/useOauthRedirect";
import {
  GoogleDisclosure,
  IntegrationDatum,
  integrationData,
} from "@/routes/org/[organisationId]/settings/tabs/integrations/integrationData";
import { cn } from "@/lib/utils";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@radix-ui/react-accordion";
import { Checkbox } from "@/components/ui/checkbox";
import { labelData } from "@/routes/org/[organisationId]/settings/tabs/configuration/labelData";
import { Membership, OauthIntegration, OauthProvider } from "@fyxer-ai/shared";

import gCalUrl from "@/assets/company-logos/google-calendar.png";
import gmailUrl from "@/assets/company-logos/gmail.png";
import outlookUrl from "@/assets/company-logos/outlook.png";
import googleMeetUrl from "@/assets/company-logos/google-meet.png";
import zoomLogo from "@/assets/company-logos/zoom.png";
import teamsUrl from "@/assets/company-logos/microsoft-teams.png";
import { useOrganisation } from "../state/useOrganisation";
import { getCompanyName, isExpAgentCase } from "@/lib/getWhitelabelProvider";
import { useBase } from "@/context/BaseContext/state/useBase";

type NoConnectionsPageState = {
  autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration;
  areLabelsEnabled: boolean;
  areDraftsEnabled: boolean;
  labelNamesInInbox: LabelName[];
  redirectingIntegration: OauthIntegration | undefined;
  showAdvancedSettings: boolean;
  hasOpenedAdvancedSettings: boolean;
  shouldUseFyxerSchedulingLink: boolean;
};

// Common types for preference setting components
type PreferenceCardProps = {
  title: string;
  description: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  icons: ReactNode;
};

// Reusable component for preference setting cards
const PreferenceCard = ({
  title,
  description,
  checked,
  onCheckedChange,
  icons,
  children,
}: PreferenceCardProps & { children?: ReactNode }) => (
  <Card className="mb-3">
    <CardHeader className="p-3">
      <div className="flex items-center gap-x-3">
        <div className="flex-grow">
          <div className="mb-1 flex items-center gap-x-1.5">
            <p className="mr-1 text-sm font-semibold">{title}</p>
            {icons}
          </div>
          <p className="text-xs text-gray-500">{description}</p>
        </div>
        <Switch checked={checked} onCheckedChange={onCheckedChange} className="h-[22px]" />
      </div>
    </CardHeader>
    {children}
  </Card>
);

// Component for categorize inbox card with advanced settings
const CategorizeInboxCard = ({
  areLabelsEnabled,
  updateState,
  state,
  selectedTab,
  showTabsExperiment,
}: {
  areLabelsEnabled: boolean;
  updateState: (state: Partial<NoConnectionsPageState>) => void;
  state: NoConnectionsPageState;
  selectedTab?: "gmail" | "outlook";
  showTabsExperiment: boolean;
}) => {
  // Determine the description text based on the selected tab
  const description =
    showTabsExperiment && selectedTab
      ? `Organize emails into actionable ${selectedTab === "gmail" ? "labels" : "folders"}, making it easy to spot what needs your attention.`
      : "Organize emails into actionable labels, making it easy to spot what needs your attention.";

  // Determine the icons to display based on the experiment and selected tab
  const icons =
    showTabsExperiment && selectedTab ? (
      selectedTab === "gmail" ? (
        <img width={30} src={gCalUrl} alt="Gmail logo" />
      ) : (
        <img width={30} src={outlookUrl} alt="Outlook logo" />
      )
    ) : (
      <>
        <img width={30} src={gmailUrl} />
        <img width={30} src={outlookUrl} />
      </>
    );

  return (
    <PreferenceCard
      title="Categorize inbox"
      description={description}
      checked={areLabelsEnabled}
      onCheckedChange={(checked) => updateState({ areLabelsEnabled: checked })}
      icons={icons}
    >
      <CardContent className="px-3 pb-3">
        <Accordion
          type="single"
          onValueChange={(v) => updateState({ showAdvancedSettings: v === "gmail", hasOpenedAdvancedSettings: true })}
          collapsible
        >
          <AccordionItem value="gmail">
            <AccordionTrigger className="w-full py-0">
              <div className="space-between flex w-full items-center">
                <p className="flex-grow text-left text-xs font-bold">Customize categorization</p>
                {state.showAdvancedSettings ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="my-3">
                <p className="mb-3 text-left text-xs text-gray-500">
                  Choose which types of emails to keep in your inbox and which to file away into their respective
                  folders.
                </p>

                <div className="mb-4 flex items-center gap-4">
                  <p className="w-12 text-center text-xs text-black">Keep in inbox?</p>
                  <p className="text-xs text-black">Categories</p>
                </div>

                <div className="grid grid-cols-1 gap-4">
                  {labelData.map((datum) => (
                    <div className="grid grid-cols-[auto,1fr] items-center gap-x-4" key={datum.labelName}>
                      <div className="flex w-12 items-center justify-center">
                        <Checkbox
                          checked={state.labelNamesInInbox.includes(datum.labelName)}
                          onCheckedChange={(checked) => {
                            const currentValues = state.labelNamesInInbox;
                            const newValues = checked
                              ? sort(deduplicate([...currentValues, datum.labelName]), (x) => x, "asc")
                              : sort(
                                  currentValues.filter((l) => l !== datum.labelName),
                                  (x) => x,
                                  "asc",
                                );
                            updateState({ labelNamesInInbox: newValues });
                          }}
                        />
                      </div>
                      <div className="flex items-center gap-x-3">
                        <div
                          className={cn(
                            "flex flex-shrink-0 flex-grow-0 items-center justify-center gap-x-1 rounded-lg px-2 py-1 text-center text-xs font-semibold",
                            datum.color,
                          )}
                        >
                          {getFriendlyLabelName(datum.labelName)}
                        </div>
                        <p className="flex-grow text-xs text-gray-500">{datum.criteria}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </CardContent>
    </PreferenceCard>
  );
};

// Component for draft replies card
const DraftRepliesCard = ({
  areDraftsEnabled,
  updateState,
  selectedTab,
  showTabsExperiment,
}: {
  areDraftsEnabled: boolean;
  updateState: (state: Partial<NoConnectionsPageState>) => void;
  selectedTab?: "gmail" | "outlook";
  showTabsExperiment: boolean;
}) => {
  // Determine the icons to display based on the experiment and selected tab
  const icons =
    showTabsExperiment && selectedTab ? (
      selectedTab === "gmail" ? (
        <img width={30} src={gCalUrl} />
      ) : (
        <img width={30} src={outlookUrl} />
      )
    ) : (
      <>
        <img width={30} src={gmailUrl} />
        <img width={30} src={outlookUrl} />
      </>
    );

  return (
    <PreferenceCard
      title="Draft replies"
      description="Draft replies to emails that require a response using my tone and language."
      checked={areDraftsEnabled}
      onCheckedChange={(checked) => updateState({ areDraftsEnabled: checked })}
      icons={icons}
    />
  );
};

// Component for schedule meetings card
const ScheduleMeetingsCard = ({
  shouldUseFyxerSchedulingLink,
  updateState,
  selectedTab,
  showTabsExperiment,
}: {
  shouldUseFyxerSchedulingLink: boolean;
  updateState: (state: Partial<NoConnectionsPageState>) => void;
  selectedTab?: "gmail" | "outlook";
  showTabsExperiment: boolean;
}) => {
  // Determine the icons to display based on the experiment and selected tab
  const icons =
    showTabsExperiment && selectedTab ? (
      selectedTab === "gmail" ? (
        <img width={30} src={gCalUrl} />
      ) : (
        <img width={30} src={outlookUrl} />
      )
    ) : (
      <>
        <img width={30} src={gCalUrl} />
        <img width={30} src={outlookUrl} />
      </>
    );

  return (
    <PreferenceCard
      title="Schedule meetings"
      description={`Let ${getCompanyName()} schedule your meetings for you. Just tell us your availability and we'll take care of the rest.`}
      checked={shouldUseFyxerSchedulingLink}
      onCheckedChange={(checked) => updateState({ shouldUseFyxerSchedulingLink: checked })}
      icons={icons}
    />
  );
};

// Component for summarize meetings card with tabs
const SummarizeMeetingsCard = ({
  showCalendarOptions,
  setShowCalendarOptions,
  autoJoinMeetingConfiguration,
  updateState,
  selectedTab,
  showTabsExperiment,
}: {
  showCalendarOptions: boolean;
  setShowCalendarOptions: (show: boolean) => void;
  autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration;
  updateState: (state: Partial<NoConnectionsPageState>) => void;
  selectedTab?: "gmail" | "outlook";
  showTabsExperiment: boolean;
}) => {
  // Handle toggle for calendar options
  const handleCalendarOptionsToggle = (checked: boolean) => {
    if (checked === false) {
      updateState({ autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration.NEVER });
    } else if (checked && autoJoinMeetingConfiguration === AutoJoinMeetingConfiguration.NEVER) {
      updateState({ autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration.ONLY_MINE });
    }

    setShowCalendarOptions(checked);
  };

  // Determine the icons to display based on the experiment and selected tab
  const icons =
    showTabsExperiment && selectedTab ? (
      selectedTab === "gmail" ? (
        <>
          <img width={30} src={googleMeetUrl} />
          <img width={20} src={zoomLogo} />
        </>
      ) : (
        <>
          <img width={30} src={teamsUrl} />
          <img width={30} src={outlookUrl} />
          <img width={20} src={zoomLogo} />
        </>
      )
    ) : (
      <>
        <img width={30} src={googleMeetUrl} />
        <img width={30} src={outlookUrl} />
        <img width={20} src={zoomLogo} />
      </>
    );

  return (
    <PreferenceCard
      title="Summarize meetings"
      description={`Let the ${getCompanyName()} Notetaker join your meetings, transcribe, and draft follow-up emails for you.`}
      checked={showCalendarOptions}
      onCheckedChange={handleCalendarOptionsToggle}
      icons={icons}
    >
      {showCalendarOptions && (
        <CardContent className="p-3 pt-0">
          <div>
            <p className="mb-1.5 text-xs font-medium text-gray-700">Let the AI notetaker summarize:</p>

            <Tabs
              activationMode="manual"
              value={autoJoinMeetingConfiguration}
              onValueChange={(value) => {
                updateState({
                  autoJoinMeetingConfiguration: value as AutoJoinMeetingConfiguration,
                });
              }}
            >
              <TabsList className="h-10 w-full flex-grow border-0">
                <TabsTrigger
                  className="flex-grow text-xs data-[state=active]:border-0 data-[state=active]:bg-gray-200"
                  value={AutoJoinMeetingConfiguration.ONLY_MINE}
                >
                  My meetings
                </TabsTrigger>
                <TabsTrigger
                  className="flex-grow text-xs data-[state=active]:border-0 data-[state=active]:bg-gray-200"
                  value={AutoJoinMeetingConfiguration.ALWAYS}
                >
                  All meetings
                </TabsTrigger>
                <TabsTrigger
                  className="flex-grow text-xs data-[state=active]:border-0 data-[state=active]:bg-gray-200"
                  value={AutoJoinMeetingConfiguration.ONLY_EXTERNAL}
                >
                  External meetings
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </CardContent>
      )}
    </PreferenceCard>
  );
};

export const ConnectionButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    integration: OauthIntegration;
    redirectingIntegration: OauthIntegration | undefined;
    connectIntegration: (integration: OauthIntegration) => Promise<void>;
  }
>(({ integration, redirectingIntegration, connectIntegration, ...props }, ref) => {
  const { title, logoUrl } = integrationData.find((datum) => datum.integration === integration) as IntegrationDatum;

  return (
    <Button
      variant="outline"
      size="lg"
      className="w-full flex-grow drop-shadow-xl"
      {...props}
      ref={ref}
      onClick={() => connectIntegration(integration)}
      disabled={!!redirectingIntegration}
    >
      {redirectingIntegration === integration ? (
        <Spinner className="m-0 flex-grow-0" />
      ) : (
        <img src={logoUrl} className="h-4 w-4" />
      )}
      Connect{redirectingIntegration === integration ? "ing" : ""} {title}
    </Button>
  );
});

export const NoEmailConnectionPage = ({ userMembership }: { userMembership: QueryDocumentSnapshot<Membership> }) => {
  const { authUser } = useBase();
  const { organisation } = useOrganisation();
  const { labelNamesInInbox, userEmail } = userMembership.data();
  const emailParts = userEmail?.split("@");
  const [emailName, emailDomain] =
    !emailParts || emailParts.length !== 2 ? ["", ""] : [emailParts[0] || "", emailParts[1] || ""];

  const { defaultAutoJoinMeetingConfiguration } = organisation;

  // EXPERIMENT: Tab and recommend guard
  const signedUpWith365 = authUser.value?.providerData.some((provider) => provider.providerId === "microsoft.com");
  const [selectedTab, setSelectedTab] = useState<"gmail" | "outlook">(signedUpWith365 ? "outlook" : "gmail");
  const tabAndRecommendGuard = useFeatureFlag("tab-and-recommend-guard");
  const showTabsExperiment = tabAndRecommendGuard.value() === "test";
  // END OF EXPERIMENT: Tab and recommend guard

  const outlookFolderFilling = useFeatureFlag("outlook-folder-filling");

  const [state, updateState] = useUpdateState<NoConnectionsPageState>({
    autoJoinMeetingConfiguration: defaultAutoJoinMeetingConfiguration || AutoJoinMeetingConfiguration.ONLY_MINE,
    areLabelsEnabled: true,
    areDraftsEnabled: true,
    labelNamesInInbox: labelNamesInInbox ?? configDefaults.labelNamesInInbox,
    redirectingIntegration: undefined,
    showAdvancedSettings: false,
    shouldUseFyxerSchedulingLink: true,
    hasOpenedAdvancedSettings: false,
  });
  const [showCalendarOptions, setShowCalendarOptions] = useState(true);
  const { handleOauthRedirect } = useOauthRedirect();
  const breakpoint = useBreakpoint();
  const buttonsRef = useRef<HTMLDivElement>(null);

  const noStickyButtons = useFeatureFlag("no-sticky-buttons");
  const hideStickyButtons = breakpoint === "xs" ? noStickyButtons.value() === "test" : false;

  useLogPageView(EventPage.NO_EMAIL_CONNECTION);

  const connectIntegration = async (integration: OauthIntegration) => {
    let newLabelNamesInInbox = state.labelNamesInInbox;

    if (
      !state.hasOpenedAdvancedSettings &&
      integration === OauthIntegration.MICROSOFT_OUTLOOK_EMAIL &&
      outlookFolderFilling.value() === "test"
    ) {
      newLabelNamesInInbox = [];
    }

    const { organisationId } = userMembership.data();

    updateState({
      labelNamesInInbox: newLabelNamesInInbox,
      redirectingIntegration: integration,
    });

    await updateDoc(userMembership.ref, {
      autoJoinMeetingConfiguration: state.autoJoinMeetingConfiguration,
      shouldUseFyxerSchedulingLink: state.shouldUseFyxerSchedulingLink,
      areDraftsEnabled: state.areDraftsEnabled,
      areLabelsEnabled: state.areLabelsEnabled,
      labelNamesInInbox: newLabelNamesInInbox,
    });

    const isGoogle = [OauthIntegration.GMAIL, OauthIntegration.GOOGLE_CALENDAR].includes(integration);
    const rest = isGoogle
      ? { type: "provider" as const, provider: OauthProvider.GOOGLE }
      : { type: "integration" as const, integration };

    await handleOauthRedirect({ organisationId, ...rest });
    updateState({ redirectingIntegration: undefined });
  };

  return (
    <Container className={`max-w-lg py-12 max-sm:pt-4`}>
      <h1 className={`mb-6 text-center text-3xl max-sm:text-2xl`}>Connect your email</h1>

      <div>
        <p className={`${showTabsExperiment ? "text-left" : "text-center"}`}>
          Personalize how your AI Executive Assistant will work for you:
        </p>

        <div className="mb-6 mt-3 flex items-center gap-x-3 rounded-lg bg-gray-100 p-3">
          <Info size={18} className="flex-shrink-0" />
          <p className="text-xs">
            We never send email on your behalf. We leave drafts for you to edit and send. If it doesn't work out with
            us, we'll leave your inbox as we found it.
          </p>
        </div>
        {isExpAgentCase(emailDomain.toLowerCase()) && (
          <div className="mb-6 mt-3 flex items-center gap-x-3 rounded-lg bg-gray-100 p-3">
            <Hand size={18} className="flex-shrink-0" />
            <p className="text-xs">
              Please add your work email to your account here i.e. {emailName || "your_name"}@your_company.com
            </p>
          </div>
        )}
      </div>

      {showTabsExperiment ? (
        <div className="pb-12">
          <div>
            <div className="grid grid-cols-2 items-center">
              <div
                className={`relative flex cursor-pointer items-center justify-center gap-x-4 rounded border border-gray-200 ${selectedTab === "gmail" ? "bg-white" : "bg-gray-50 hover:bg-gray-100"} px-4 py-4`}
                role="button"
                onClick={() => setSelectedTab("gmail")}
                style={{
                  borderBottom: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderRight: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <img src={gmailUrl} alt="Gmail logo" className="w-8" />
                <p className="font-bold">Gmail</p>
              </div>
              <div
                className={`relative flex cursor-pointer items-center justify-center gap-x-4 rounded border border-gray-200 ${selectedTab === "outlook" ? "bg-white" : "bg-gray-50 hover:bg-gray-100"} px-4 py-4`}
                role="button"
                onClick={() => setSelectedTab("outlook")}
                style={{
                  borderBottom: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: 0,
                }}
              >
                <img src={outlookUrl} alt="Outlook logo" className="w-8" />
                <p className="font-bold">Outlook</p>
              </div>
            </div>
            <div
              className="rounded border border-gray-200 bg-white px-4 py-4"
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <div>
                <CategorizeInboxCard
                  areLabelsEnabled={state.areLabelsEnabled}
                  updateState={updateState}
                  state={state}
                  selectedTab={selectedTab}
                  showTabsExperiment={showTabsExperiment}
                />
                <DraftRepliesCard
                  areDraftsEnabled={state.areDraftsEnabled}
                  updateState={updateState}
                  selectedTab={selectedTab}
                  showTabsExperiment={showTabsExperiment}
                />
              </div>

              <div>
                <ScheduleMeetingsCard
                  shouldUseFyxerSchedulingLink={state.shouldUseFyxerSchedulingLink}
                  updateState={updateState}
                  selectedTab={selectedTab}
                  showTabsExperiment={showTabsExperiment}
                />

                <SummarizeMeetingsCard
                  showCalendarOptions={showCalendarOptions}
                  setShowCalendarOptions={setShowCalendarOptions}
                  autoJoinMeetingConfiguration={state.autoJoinMeetingConfiguration}
                  updateState={updateState}
                  selectedTab={selectedTab}
                  showTabsExperiment={showTabsExperiment}
                />
              </div>

              <Button
                className="mt-4 w-full bg-black py-8 text-base text-white hover:bg-gray-900 focus:bg-gray-900 active:bg-gray-900"
                size={"lg"}
                variant="default"
                onClick={() =>
                  connectIntegration(
                    selectedTab === "gmail" ? OauthIntegration.GMAIL : OauthIntegration.MICROSOFT_OUTLOOK_EMAIL,
                  )
                }
                disabled={!!state.redirectingIntegration}
              >
                <img src={selectedTab === "gmail" ? gmailUrl : outlookUrl} className="h-8 w-8" />
                Connect {selectedTab === "gmail" ? "Gmail" : "Outlook"}
              </Button>

              {selectedTab === "gmail" && (
                <div className="mt-4">
                  <GoogleDisclosure />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={"mb-12 mt-0 sm:mb-0"}
            style={{
              marginBottom:
                !hideStickyButtons && breakpoint === "xs" && buttonsRef.current
                  ? buttonsRef.current.offsetHeight
                  : undefined,
            }}
          >
            <div>
              <CategorizeInboxCard
                areLabelsEnabled={state.areLabelsEnabled}
                updateState={updateState}
                state={state}
                showTabsExperiment={showTabsExperiment}
              />
              <DraftRepliesCard
                areDraftsEnabled={state.areDraftsEnabled}
                updateState={updateState}
                showTabsExperiment={showTabsExperiment}
              />
            </div>

            <div>
              <ScheduleMeetingsCard
                shouldUseFyxerSchedulingLink={state.shouldUseFyxerSchedulingLink}
                updateState={updateState}
                showTabsExperiment={showTabsExperiment}
              />
              <SummarizeMeetingsCard
                showCalendarOptions={showCalendarOptions}
                setShowCalendarOptions={setShowCalendarOptions}
                autoJoinMeetingConfiguration={state.autoJoinMeetingConfiguration}
                updateState={updateState}
                showTabsExperiment={showTabsExperiment}
              />
            </div>
          </div>

          <div
            className={
              hideStickyButtons
                ? "space-y-4 max-sm:border-t max-sm:border-slate-100 max-sm:bg-white"
                : "space-y-4 max-sm:fixed max-sm:bottom-0 max-sm:left-0 max-sm:w-full max-sm:border-t max-sm:border-slate-100 max-sm:bg-white max-sm:p-4"
            }
            ref={buttonsRef}
          >
            <div className="max-sm:space-y-2 sm:flex sm:items-center sm:gap-x-4">
              <ConnectionButton
                {...{
                  integration: OauthIntegration.GMAIL,
                  connectIntegration,
                  redirectingIntegration: state.redirectingIntegration,
                }}
              />
              <ConnectionButton
                {...{
                  integration: OauthIntegration.MICROSOFT_OUTLOOK_EMAIL,
                  connectIntegration,
                  redirectingIntegration: state.redirectingIntegration,
                }}
              />
            </div>

            <GoogleDisclosure />
          </div>
        </div>
      )}
    </Container>
  );
};
