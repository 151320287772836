import {
  Banknote,
  Building,
  ChevronDownIcon,
  CreditCard,
  LucideIcon,
  Menu,
  Phone,
  Plug,
  Plus,
  Settings,
  Users,
  Video,
  Wrench,
  X,
} from "lucide-react";
import { Link, NavLink, To, useLocation, useParams } from "react-router-dom";

import { useMemberships } from "@/context/BaseContext/state/useMemberships";
import { useBase } from "@/context/BaseContext/state/useBase";
import { useIsMobile } from "@/hooks/useIsMobile";
import { useUpdateState } from "@/hooks/useUpdateState";
import { useWindowSize } from "@/hooks/useWindowSize";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";
import { cn } from "@/lib/utils";

import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Skeleton } from "../ui/skeleton";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@radix-ui/react-accordion";
import { useCalPopup } from "@/hooks/useCalPopup";

const SideBarLink = ({
  to,
  title,
  Icon,
  isExpanded,
  isBeta = false,
  newTab = false,
  onClick,
  closeSidebar,
}: {
  to: To;
  title: string;
  Icon: LucideIcon;
  isExpanded: boolean;
  isBeta?: boolean;
  newTab?: boolean;
  onClick?: () => void;
  closeSidebar: () => void;
}) => {
  const isMobile = useIsMobile();

  return (
    <NavLink
      to={to}
      {...(newTab ? { target: "_blank" } : {})}
      onClick={onClick || (isMobile ? closeSidebar : undefined)}
      className={({ isActive }) =>
        cn(
          "flex h-12 items-center gap-4 px-4 text-sm hover:opacity-60",
          isActive ? "bg-slate-200" : "",
          !isExpanded ? "justify-center" : "",
        )
      }
    >
      <Icon size={20} />
      {isExpanded ? title : null}
      {isBeta && isExpanded ? <Badge className="text-[10px]">Beta</Badge> : null}
    </NavLink>
  );
};

interface Data {
  isExpanded: boolean;
  settingsOpen: boolean;
}

export const SideBar = () => {
  useCalPopup({
    namespace: "conta",
  });

  const location = useLocation();
  const { organisationId } = useParams();
  const { enrichedData } = useBase();
  const { memberships } = useMemberships();
  const { logButtonPress } = useAnalytics();
  const activeMembership = memberships.find((m) => m.data().organisationId === organisationId);
  const activeMembershipData = activeMembership?.data();
  const { width } = useWindowSize();
  const isMobile = width < 640;

  const [{ settingsOpen, ...state }, updateState] = useUpdateState<Data>({
    isExpanded: !isMobile,
    settingsOpen: false,
  });

  const orgSizeValidForCall = (enrichedData.value?.enrichedIndividualData?.org?.numberOfEmployees ?? 0) >= 50;
  const eligbleForCallOrDemo = orgSizeValidForCall;

  const isSettingsBasePath = location.hash === "" && location.pathname.includes("settings");

  return (
    <>
      {isMobile ? (
        <Button
          size="icon"
          variant="secondary"
          className="absolute left-0 top-12 z-20 rounded-none rounded-br-md"
          onClick={() => updateState({ isExpanded: !state.isExpanded })}
        >
          {state.isExpanded ? <X /> : <Menu />}
        </Button>
      ) : null}
      <div
        className={cn(
          "flex flex-shrink-0 flex-col border-r-2 border-slate-100 bg-slate-50",
          state.isExpanded
            ? "max-sm:absolute max-sm:bottom-0 max-sm:left-0 max-sm:right-0 max-sm:top-12 max-sm:z-10 sm:w-64"
            : "hidden w-auto",
        )}
      >
        <div className="p-2 max-sm:pt-12">
          {activeMembershipData ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div
                  className={cn(
                    "flex h-10 cursor-pointer items-center gap-2 rounded-md border-2 border-slate-100 bg-white",
                    state.isExpanded ? "px-2" : "justify-center",
                  )}
                >
                  {state.isExpanded ? <Building className="h-5 w-5" /> : null}
                  <h4>
                    {state.isExpanded
                      ? activeMembershipData.organisationName
                      : activeMembershipData.organisationName[0].toUpperCase()}
                  </h4>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Your organisations</DropdownMenuLabel>
                {memberships.map((membership) => {
                  const { organisationId: mOrgId, organisationName } = membership.data();
                  return (
                    <Link to={`/org/${mOrgId}`} key={membership.id}>
                      <DropdownMenuItem className={mOrgId === organisationId ? "bg-slate-100" : "cursor-pointer"}>
                        <Building className="mr-2 h-4 w-4" />
                        {organisationName}
                      </DropdownMenuItem>
                    </Link>
                  );
                })}
                <DropdownMenuSeparator />
                <Link to="/org/create">
                  <DropdownMenuItem className="cursor-pointer">
                    <Plus className="mr-2 h-4 w-4" />
                    New organisation
                  </DropdownMenuItem>
                </Link>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Skeleton className="h-6" />
          )}
        </div>

        {isMobile ? (
          <NavLink
            to={`/org/${organisationId}/settings#tab=configuration`}
            role="button"
            onClick={() => updateState({ isExpanded: !state.isExpanded })}
            className={() =>
              `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60 ${isSettingsBasePath || location.hash.includes("tab=configuration") ? "bg-slate-200" : ""}`
            }
          >
            <div className="flex flex-grow gap-4">
              <Wrench size={20} /> <span>Preferences</span>
            </div>
          </NavLink>
        ) : (
          <Accordion
            value={settingsOpen ? "settings" : ""}
            onValueChange={() => updateState({ settingsOpen: !settingsOpen })}
            type="single"
            collapsible
          >
            <AccordionItem value="settings">
              <AccordionTrigger className="w-full">
                <NavLink
                  to={`/org/${organisationId}/settings#tab=configuration`}
                  role="button"
                  onClick={() => {
                    document.getElementById("settings_container")?.scrollIntoView();
                    updateState({ settingsOpen: !settingsOpen });
                  }}
                  className={({ isActive }) =>
                    `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60 ${isActive ? "bg-slate-200" : ""}`
                  }
                >
                  <div className="flex flex-grow gap-4">
                    <Settings size={20} /> <span>Settings</span>
                  </div>
                  <ChevronDownIcon className={`${settingsOpen ? "rotate-180" : ""}`} />
                </NavLink>
              </AccordionTrigger>
              <AccordionContent className="pl-4">
                <NavLink
                  to={`/org/${organisationId}/settings#tab=configuration`}
                  role="button"
                  className={() => `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60`}
                >
                  <div className="flex flex-grow gap-4">
                    <Wrench size={20} />{" "}
                    <span className={`${location.hash.includes("tab=configuration") ? "font-bold" : ""}`}>
                      Preferences
                    </span>
                  </div>
                </NavLink>
                <NavLink
                  to={`/org/${organisationId}/settings#tab=integrations`}
                  role="button"
                  className={() => `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60`}
                >
                  <div className="flex flex-grow gap-4">
                    <Plug size={20} />{" "}
                    <span className={`${location.hash.includes("tab=integrations") ? "font-bold" : ""}`}>
                      Integrations
                    </span>
                  </div>
                </NavLink>
                <NavLink
                  to={`/org/${organisationId}/settings#tab=billing`}
                  onClick={() => document.getElementById("settings_container")?.scrollIntoView()}
                  role="button"
                  className={() => `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60`}
                >
                  <CreditCard size={20} />{" "}
                  <span className={`${location.hash.includes("tab=billing") ? "font-bold" : ""}`}>Billing</span>
                </NavLink>
                <NavLink
                  to={`/org/${organisationId}/settings#tab=team`}
                  onClick={() => document.getElementById("settings_container")?.scrollIntoView()}
                  role="button"
                  className={() => `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60`}
                >
                  <Users size={20} />{" "}
                  <span className={`${location.hash.includes("tab=team") ? "font-bold" : ""}`}>Team</span>
                </NavLink>
                <NavLink
                  to={`/org/${organisationId}/settings#tab=details`}
                  onClick={() => document.getElementById("settings_container")?.scrollIntoView()}
                  role="button"
                  className={() => `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60`}
                >
                  <Building size={20} />{" "}
                  <span className={`${location.hash.includes("tab=details") ? "font-bold" : ""}`}>Organization</span>
                </NavLink>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}

        {isMobile && (
          <NavLink
            to={`/org/${organisationId}/settings#tab=integrations`}
            role="button"
            onClick={() => {
              document.getElementById("settings_container")?.scrollIntoView();
              if (isMobile) updateState({ isExpanded: !state.isExpanded });
            }}
            className={() =>
              `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60 ${location.hash.includes("tab=integrations") ? "bg-slate-200" : ""}`
            }
          >
            <div className="flex flex-grow gap-4">
              <Plug size={20} /> <span>Integrations</span>
            </div>
          </NavLink>
        )}

        <SideBarLink
          to={`/org/${organisationId}/meeting-assistant`}
          title="Meetings"
          closeSidebar={() => updateState({ isExpanded: false })}
          Icon={Video}
          isExpanded={state.isExpanded}
        />

        {eligbleForCallOrDemo ? (
          <button
            data-cal-namespace="conta"
            data-cal-link="forms/fd6d69b9-72cb-4056-a61b-aecc6a27c981"
            data-cal-config='{"layout":"month_view"}'
            onClick={() => logButtonPress(EventButton.BOOK_A_CALL_CLICK, { source: "sidebar" })}
            className="pointer bg flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60"
          >
            <Phone size={20} />
            <span>Speak to sales</span>
          </button>
        ) : null}

        {isMobile && (
          <NavLink
            to={`/org/${organisationId}/settings#tab=billing`}
            role="button"
            onClick={() => {
              document.getElementById("settings_container")?.scrollIntoView();
              if (isMobile) updateState({ isExpanded: !state.isExpanded });
            }}
            className={() =>
              `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60 ${location.hash.includes("tab=billing") ? "bg-slate-200" : ""}`
            }
          >
            <div className="flex flex-grow gap-4">
              <CreditCard size={20} /> <span>Billing</span>
            </div>
          </NavLink>
        )}

        {isMobile && (
          <NavLink
            to={`/org/${organisationId}/settings#tab=team`}
            role="button"
            onClick={() => {
              document.getElementById("settings_container")?.scrollIntoView();
              if (isMobile) updateState({ isExpanded: !state.isExpanded });
            }}
            className={() =>
              `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60 ${location.hash.includes("tab=team") ? "bg-slate-200" : ""}`
            }
          >
            <div className="flex flex-grow gap-4">
              <Users size={20} /> <span>Team</span>
            </div>
          </NavLink>
        )}

        {isMobile && (
          <NavLink
            to={`/org/${organisationId}/settings#tab=details`}
            role="button"
            onClick={() => {
              document.getElementById("settings_container")?.scrollIntoView();
              if (isMobile) updateState({ isExpanded: !state.isExpanded });
            }}
            className={() =>
              `pointer flex h-12 w-full items-center gap-4 px-4 text-sm hover:opacity-60 ${location.hash.includes("tab=details") ? "bg-slate-200" : ""}`
            }
          >
            <div className="flex flex-grow gap-4">
              <Building size={20} /> <span>Organization</span>
            </div>
          </NavLink>
        )}

        <SideBarLink
          to={`/org/${organisationId}/rewards`}
          title="Rewards"
          closeSidebar={() => updateState({ isExpanded: false })}
          Icon={Banknote}
          isExpanded={state.isExpanded}
        />

        <div className="flex-grow"></div>
      </div>
    </>
  );
};
