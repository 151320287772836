import { useEffect } from "react";
import { getCalApi } from "@calcom/embed-react";
import { useMutation } from "@tanstack/react-query";

export const useCalPopup = ({ namespace }: { namespace: string }) => {
  const createCalPopup = useMutation({
    mutationFn: async () => {
      const cal = await getCalApi({ namespace });
      cal("ui", { hideEventTypeDetails: false, layout: "month_view" });
      return cal;
    },
  });

  useEffect(() => {
    createCalPopup.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
