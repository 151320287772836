import { Bell, Star, Trophy, Unlock, X, Zap } from "lucide-react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useUpdateState } from "@/hooks/useUpdateState";
import { useForm } from "react-hook-form";
import { useOrganisationActions } from "@/context/OrganisationContext/actions/useOrganisationActions";
import {
  BillingCycle,
  Currency,
  formatCurrency,
  getAnnualPriceId,
  OrganisationRole,
  SubscriptionPlanType,
} from "@fyxer-ai/shared";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import FreeTrialPopup, { calculateCost, useCheckout } from "./FreeTrialPopup";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { EventForm, EventName, useAnalytics, useFeatureFlag } from "@/hooks/useAnalytics";
import { useToast } from "@/components/ui/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { addDays, formatDate } from "date-fns";
import { Spinner, SpinnerPage } from "./layout/SpinnerPage";
import { getEnvironment } from "@/lib/getEnvironment";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";

const Progress = ({
  value,
  max = 100,
  className = "",
  indicator = "",
}: {
  value: number;
  max?: number;
  className?: string;
  indicator?: string;
}) => {
  return (
    <div className={`relative w-full ${className} flex items-center`}>
      <div className="h-8 w-full overflow-hidden rounded-full bg-amber-100">
        <div
          className={`flex h-full items-center justify-center rounded-full bg-amber-500 text-xs font-bold text-amber-900 transition-all`}
          style={{ width: `${(value / max) * 100}%`, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          {indicator}
        </div>
      </div>
      <div className="relative -left-8 -mr-8 flex h-8 w-8 items-center justify-center rounded-full border-2 border-amber-500 bg-amber-400">
        <Trophy size={16} className="stroke-amber-900" />
      </div>
    </div>
  );
};

export const FreeTrialGuard = ({ onSkip, calendarEnabled }: { onSkip: () => void; calendarEnabled: boolean }) => {
  const inviteFreeTrialPopupExp = useFeatureFlag("invite-free-trial-popup-4");
  const { organisationId, organisation } = useOrganisation();
  const { logEvent } = useAnalytics();
  const api = useApi();
  const [{ recommendations }, updateState] = useUpdateState<{
    recommendations: string[];
  }>({
    recommendations: [],
  });

  const query = useQuery({
    queryKey: ["recommendedInvitees", organisationId],
    queryFn: async () => {
      if (calendarEnabled) {
        const emails = await api.organisations.id(organisationId).invites.recommend();
        updateState({ recommendations: emails });
        logEvent(EventName.INVITE_RECOMMENDATIONS_LOADED, { recommendations: emails.length });
        return emails;
      }
      return [];
    },
    refetchOnWindowFocus: false,
  });

  if (query.isLoading) {
    return <SpinnerPage />;
  }

  if (recommendations.length && inviteFreeTrialPopupExp.value() === "test") {
    return <InviteFreeTrialPopup recommendations={recommendations} onSkip={onSkip} />;
  }

  const isPersonalOrg = !organisation.domain;
  const trialLength = isPersonalOrg ? 14 : 7;

  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white">
      <X size={32} className="absolute right-4 top-4 cursor-pointer" onClick={onSkip} />
      <div>
        <FreeTrialPopup onSkip={onSkip} showSkip trialLength={trialLength} />
      </div>
    </div>
  );
};

const InviteFreeTrialPopup = ({ recommendations, onSkip }: { recommendations: string[]; onSkip: () => void }) => {
  const { logFormSubmit } = useAnalytics();
  const { toast } = useToast();
  const { organisation } = useOrganisation();
  const { sendInvites } = useOrganisationActions();
  const checkout = useCheckout();
  const domain = organisation.domain;

  const [{ invites, loading, billingCycle }, updateState] = useUpdateState<{
    invites: Array<{
      role: OrganisationRole;
      email: string;
    }>;
    loading: boolean;
    billingCycle: BillingCycle;
  }>({
    billingCycle: BillingCycle.ANNUALLY,
    invites: recommendations.map((e) => ({ email: e, role: OrganisationRole.MEMBER })).slice(0, 5),
    loading: false,
  });

  const trialLength = 7 + 7 * invites.length;
  const endTrialDate = addDays(Date.now(), trialLength);
  const shouldDisableForm = invites.length >= 5;

  const { register, handleSubmit, reset, watch } = useForm<{ email: string }>();

  const inputEmail = watch("email");

  const onInviteFormSubmit = ({ email }: { email: string }) => {
    if (email.length < 1) return;

    const fullEmail = `${email.replace(/\s/g, "")}@${domain}`;
    // Check if email already exists in invites array
    if (!invites.some((invite) => invite.email === fullEmail)) {
      updateState({
        invites: [...invites, { email: fullEmail, role: OrganisationRole.MEMBER }],
      });
    } else {
      toast({ title: "Email already added" });
    }
    reset();
  };

  function onRemoveInvite(email: string) {
    updateState({
      invites: invites.filter((invite) => invite.email !== email),
    });
  }

  async function onStartCheckout() {
    if (invites.length > 0) {
      sendInvites(invites);
      logFormSubmit(EventForm.SEND_INVITES, {
        count: invites.length,
      });
    }
    updateState({ loading: true });
    try {
      const checkoutOptions = {
        trial_length: trialLength,
        billingCycle,
        planType: SubscriptionPlanType.PRO,
        ...(billingCycle === BillingCycle.ANNUALLY ? { priceId: getAnnualPriceId(getEnvironment(), true) } : {}),
      };
      await checkout.start("invite_free_trial_popup", checkoutOptions);
    } catch (error) {
      console.error(error);
    }
    updateState({ loading: false });
  }

  const monthlyCost = calculateCost({ billingCycle: BillingCycle.MONTHLY, membershipsLength: 1 });
  const cost = calculateCost({ billingCycle, membershipsLength: 1 });

  return (
    <div className="relative mx-auto max-w-lg px-6 py-12">
      <X size={24} className="absolute -top-0 right-6 cursor-pointer" onClick={onSkip} />
      <h1 className="mb-4 text-center text-3xl">Start your free trial</h1>

      {billingCycle === BillingCycle.ANNUALLY ? (
        <p className="mb-6 text-center">
          First {trialLength} days free, then{" "}
          <s className="text-gray-500">
            {formatCurrency({
              currency: Currency.USD,
              value: monthlyCost,
              includeFractionDigits: false,
            })}
          </s>{" "}
          <span className="font-semibold text-purple-600">
            {formatCurrency({
              currency: Currency.USD,
              value: cost / 12,
            })}
          </span>{" "}
          / seat / pm
        </p>
      ) : (
        <p className="mb-6 text-center">
          First {trialLength} days free, then{" "}
          {formatCurrency({
            currency: Currency.USD,
            value: cost,
            includeFractionDigits: false,
          })}{" "}
          / seat / pm
        </p>
      )}

      <div className="mb-6">
        <div className="mx-auto grid grid-cols-2 items-center">
          <div className="flex items-center justify-end gap-x-3">
            <Label className={`${billingCycle === BillingCycle.MONTHLY ? "font-bold" : "text-gray-500"}`}>
              Monthly
            </Label>
            <Switch
              checked={billingCycle === BillingCycle.ANNUALLY}
              onCheckedChange={(checked) =>
                updateState({
                  billingCycle: checked ? BillingCycle.ANNUALLY : BillingCycle.MONTHLY,
                })
              }
            />
          </div>
          <div className="flex items-center gap-x-3 pl-3">
            <Label className={`${billingCycle === BillingCycle.ANNUALLY ? "font-bold" : "text-gray-500"}`}>
              Yearly
            </Label>
            <div className="rounded-md bg-yellow-200 px-2 py-1 text-xs font-semibold">3 months free</div>
          </div>
        </div>
      </div>

      <div className="mx-auto mb-7 max-w-[450px]">
        <Card className="mb-6 border border-orange-200 bg-amber-50">
          <CardHeader
            className="rounded-md p-3 pb-1.5"
            style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          >
            <CardTitle className="flex items-center gap-x-3 text-lg">
              <Zap size={24} className="fill-amber-500 stroke-amber-600" />
              <span className="font-bold text-amber-900">Special offer</span>
            </CardTitle>
          </CardHeader>
          <CardContent className="p-3 pt-0">
            <p className="mb-3 text-sm text-orange-900 opacity-75">
              For every team member you invite, your trial will extend by 7 days up to a maximum of 42 days.
            </p>
            <div className="mb-0 flex items-center">
              <Progress value={(trialLength / 42) * 100} indicator={`${trialLength} / 42`} />
            </div>

            {invites.length > 0 ? (
              <div className="mt-4 flex flex-wrap gap-3">
                {invites.map(({ email }) => (
                  <div
                    key={email}
                    className="flex items-center justify-between gap-x-3 rounded-full bg-orange-100 px-3 py-1.5"
                  >
                    <p className="text-wrap break-all text-xs text-gray-800">{email}</p>
                    <X
                      onClick={() => onRemoveInvite(email)}
                      className="flex-shrink-0 cursor-pointer stroke-gray-600 hover:stroke-black"
                      size={14}
                    />
                  </div>
                ))}
              </div>
            ) : null}

            {invites.length < 5 ? (
              <form
                className={`w-full ${invites.length ? "mt-4 border-t border-slate-200 pt-4" : "mt-1.5 pt-3"}`}
                onSubmit={handleSubmit(onInviteFormSubmit)}
              >
                <div className="mb-3 flex w-full flex-grow">
                  <Input
                    placeholder="john.doe"
                    className="w-full focus-visible:ring-0"
                    disabled={shouldDisableForm}
                    required
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    {...register("email", { required: true })}
                  />
                  <div
                    className={`flex h-10 items-center justify-center rounded-md border border-slate-200 bg-gray-100 px-2 text-sm text-gray-600 ${shouldDisableForm ? "opacity-50" : ""}`}
                    style={{ borderLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  >
                    @{domain}
                  </div>
                </div>
                <Button
                  variant={inputEmail?.length > 0 ? "default" : "secondary"}
                  size="sm"
                  className={`h-10 w-full border border-amber-700 bg-amber-600 font-bold text-white hover:bg-amber-700`}
                  type="submit"
                >
                  Add member
                </Button>
              </form>
            ) : null}
          </CardContent>
        </Card>
      </div>

      <div className="relative mx-auto mb-3 max-w-[450px]">
        <span className="absolute left-[11px] top-[6px] z-[-1] h-[182px] w-[6px] bg-blue-300"></span>
        <div className="mb-6 flex items-start gap-6">
          <div className="flex items-center justify-center rounded-full bg-blue-700 p-2">
            <Unlock size={12} strokeWidth={2} className="flex-shrink-0 stroke-white" />
          </div>
          <div className="flex-grow">
            <p className="mb-1 font-semibold">Today</p>
            <p className="text-sm text-gray-500">
              Unlock access to the Fyxer AI email organizer, draft replier, event scheduler, and meeting notetaker.
            </p>
          </div>
        </div>
        <div className="mb-6 flex items-start gap-6">
          <div className="flex items-center justify-center rounded-full bg-blue-700 p-2">
            <Bell size={12} strokeWidth={2} className="flex-shrink-0 stroke-white" />
          </div>
          <div className="flex-grow">
            <p className="mb-1 font-semibold">In {trialLength - 2} days</p>
            <p className="text-sm text-gray-500">We'll send you a reminder that your trial is ending soon.</p>
          </div>
        </div>
        <div className="flex items-start gap-6">
          <div className="flex items-center justify-center rounded-full bg-blue-700 p-2">
            <Star size={12} strokeWidth={2} className="flex-shrink-0 stroke-white" />
          </div>
          <div className="flex-grow">
            <p className="mb-1 font-semibold">In {trialLength} days</p>
            <p className="text-sm text-gray-500">
              You'll be charged on {formatDate(endTrialDate, "MMM d")},{" "}
              <span className="font-bold text-gray-500">cancel anytime</span> before.
            </p>
          </div>
        </div>
      </div>

      <Button
        disabled={loading}
        onClick={onStartCheckout}
        className="mt-6 flex w-full items-center justify-center py-6 font-bold"
      >
        {loading ? (
          <Spinner className="my-0" size={16} />
        ) : (
          <span className="font-bold text-white">Start free trial</span>
        )}
      </Button>
      <Button className="mt-3 w-full text-gray-400" variant="link" onClick={onSkip}>
        Skip offer
      </Button>
    </div>
  );
};

export default InviteFreeTrialPopup;
