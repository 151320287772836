import { useEffect, useState } from "react";
import { z } from "zod";

import { EventName, useAnalytics, EventForm } from "@/hooks/useAnalytics";
import { useQueryParams } from "@/hooks/useQueryParams";
import { FormUtil } from "@/components/controls/FormUtil";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

export const DraftRatingPage = () => {
  const { isDraftGood, triggeringEmailMessageId } = useQueryParams();
  const { logEvent, logFormSubmit } = useAnalytics();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    logEvent(EventName.DRAFT_RATING, {
      triggeringEmailMessageId,
      isDraftGood: isDraftGood ? true : false,
    });
  }, [logEvent, triggeringEmailMessageId, isDraftGood]);

  const feedbackSchema = z.object({
    draftFeedback: makeSchemaSafe(z.string().min(1).max(2000)),
  });

  const defaultValues = {
    draftFeedback: "",
  };

  const handleSubmit = ({ draftFeedback }: { draftFeedback: string }) => {
    try {
      logFormSubmit(EventForm.DRAFT_FEEDBACK, {
        draftFeedback,
        triggeringEmailMessageId,
        isDraftGood: isDraftGood ? true : false,
      });
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (isDraftGood || isSubmitted) {
    return (
      <div className="flex min-h-screen items-center justify-center px-4">
        <p className="text-center">Thank you for your feedback! Feel free to close this window.</p>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen items-center justify-center px-4">
      <div className="w-full max-w-md">
        <p>
          Thank you for your rating!
          <br />
          This will be vital in improving our drafting functionality.
          <br />
          <br />
          Please leave additional feedback below:
        </p>
        <FormUtil
          schema={feedbackSchema}
          defaultValues={defaultValues}
          render={(form) => (
            <FormFieldUtil
              isLabelHidden={true}
              control={form.control}
              name="draftFeedback"
              render={({ field }) => (
                <Textarea {...field} placeholder="Type your feedback here..." rows={4} className="w-full" />
              )}
            />
          )}
          onSubmit={handleSubmit}
          renderSubmitButton={(props) => (
            <Button {...props} className="w-full">
              Submit Feedback
            </Button>
          )}
        />
      </div>
    </div>
  );
};
