import { BillingCycle, constructUrl, SubscriptionPlanType } from "@fyxer-ai/shared";
import { ChevronLeft } from "lucide-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { Button } from "@/components/ui/button";
import { NumberInput } from "@/components/ui/input";
import { Table, TableCell, TableRow } from "@/components/ui/table";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventForm, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useQueryParams } from "@/hooks/useQueryParams";
import { redirectExternally } from "@/lib/redirectExternally";
import { getDisplayPrice, getPriceCents, newData } from "@/routes/org/PricingView";
import { getCompanyName } from "@/lib/getWhitelabelProvider";

const selfServePlanTypes = [SubscriptionPlanType.STANDARD, SubscriptionPlanType.PRO] as const;

const paramsSchema = z.object({
  billingCycle: z.nativeEnum(BillingCycle),
  seatCount: z.string(),
  planType: z.enum(selfServePlanTypes),
});

export const CreateSubscriptionPage = () => {
  useLogPageView(EventPage.CREATE_SUBSCRIPTION);
  const { logFormSubmit } = useAnalytics();
  const { memberships, organisationId } = useOrganisation();
  const { billingCycle, seatCount: seatCountString, planType } = paramsSchema.parse(useQueryParams());
  const seatCount = parseInt(seatCountString, 10);
  const api = useApi();

  const membershipCount = useMemo(() => memberships.length, [memberships]);

  return (
    <div className="p-8">
      <Link
        to={constructUrl({
          path: `/org/${organisationId}/settings`,
          hash: {
            tab: "billing",
          },
        })}
      >
        <Button variant="secondary">
          <ChevronLeft />
          Back
        </Button>
      </Link>

      <div className="m-auto max-w-md space-y-4 py-20">
        <h2>Start a subscription</h2>
        <FormUtil
          schema={z.object({
            billingCycle: z.nativeEnum(BillingCycle),
            planType: z.enum(selfServePlanTypes),
            seatCount: z.number().int().gte(Math.max(1, membershipCount)),
          })}
          defaultValues={{
            seatCount: Math.max(seatCount, memberships.length),
            planType,
            billingCycle,
          }}
          submitTitle="Proceed to payment"
          render={(form) => {
            const billingCycle = form.watch("billingCycle");
            const seatCount = form.watch("seatCount");
            const planTypeForm = form.watch("planType");
            const priceMonthlyCents =
              newData.find((datum) => datum.planType === planTypeForm)?.priceMonthlyCents ?? 3000;

            const pricePerSeatPerMonth = getPriceCents({ priceMonthlyCents, billingCycle });

            return (
              <>
                <FormFieldUtil
                  render={({ field }) => (
                    <SelectUtil {...field} items={[SubscriptionPlanType.STANDARD, SubscriptionPlanType.PRO]} />
                  )}
                  control={form.control}
                  name="planType"
                />
                <FormFieldUtil
                  render={({ field }) => <SelectUtil {...field} items={Object.values(BillingCycle)} />}
                  control={form.control}
                  name="billingCycle"
                />
                <FormFieldUtil
                  render={({ field }) => <NumberInput {...field} />}
                  control={form.control}
                  name="seatCount"
                  description={`This can't be less than the number of teammates you have on ${getCompanyName()}, which is ${memberships.length}.`}
                />

                <Table>
                  <TableRow>
                    <TableCell>Cost per seat</TableCell>
                    <TableCell className="text-right">
                      {seatCount ? getDisplayPrice(pricePerSeatPerMonth, true) + " / month" : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total cost</TableCell>
                    <TableCell className="text-right">
                      {seatCount
                        ? getDisplayPrice(
                            pricePerSeatPerMonth * seatCount * (billingCycle === BillingCycle.MONTHLY ? 1 : 12),
                            true,
                          ) +
                          " / " +
                          (billingCycle === BillingCycle.MONTHLY ? "month" : "year")
                        : "-"}
                    </TableCell>
                  </TableRow>
                </Table>
              </>
            );
          }}
          onSubmit={async (data) => {
            logFormSubmit(EventForm.START_SUBSCRIPTION, data);
            const checkoutSessionUrl = await api.organisations.id(organisationId).checkoutSessions.create(data);
            redirectExternally(checkoutSessionUrl);
          }}
        />
      </div>
    </div>
  );
};
