import { getIsServiceTypeOrgLevel, SubscriptionPlanType } from "@fyxer-ai/shared";
import { toHeaderCase, toTextCase } from "js-convert-case";
import { HelpCircle } from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { unwrap } from "@/lib/firebase/unwrap";

import { ConnectIntegrationForFirstTimeRow } from "./ConnectIntegrationForFirstTimeRow";
import { ConnectionRow } from "./ConnectionRow";
import { integrationData } from "./integrationData";
import { ServiceTypeDatum } from "./serviceTypeData";
import { useOauthRedirect } from "./useOauthRedirect";
import { ConnectAnother } from "./ConnectAnother";

export const ServiceTypeRow = (serviceTypeDatum: ServiceTypeDatum) => {
  const { serviceType, status, strapline, detail } = serviceTypeDatum;
  const {
    connections,
    organisationId,
    planData: { planType },
  } = useOrganisation();
  const { handleOauthRedirect, redirectingIntegration } = useOauthRedirect();
  const connectionsForServiceType = connections.filter(unwrap((connection) => connection.serviceType === serviceType));
  const hasConnection = connectionsForServiceType.length > 0;
  const integrationDataForServiceType = integrationData.filter(
    (integration) => integration.serviceType === serviceType,
  );

  const isStandard = planType === SubscriptionPlanType.STANDARD;
  const isFreePlan = planType === "FREE";
  const hasNoPlan = isStandard || isFreePlan;

  return (
    <Card key={`service-type-row-${serviceType}`}>
      <div className="flex w-full items-center gap-x-4 p-6">
        <CardHeader className="flex-grow p-0">
          <CardTitle className="text-lg">{toHeaderCase(serviceType)}</CardTitle>
          <CardDescription>
            {strapline}
            {getIsServiceTypeOrgLevel(serviceType) ? (
              <>
                {" "}
                <b>You'll need to be an admin to connect this.</b>
              </>
            ) : null}
          </CardDescription>
        </CardHeader>
        {status === "BETA" ? <Badge>Beta</Badge> : null}
        <Dialog>
          <DialogTrigger>
            <Button variant="ghost" size="icon" className="flex-shrink-0">
              <HelpCircle className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>How the {toTextCase(serviceType)} integration works</DialogTitle>
            </DialogHeader>
            <div className="text-sm">{detail()}</div>
          </DialogContent>
        </Dialog>
      </div>
      <CardContent>
        {hasConnection ? (
          <div className="space-y-4">
            {connectionsForServiceType.map((connection) => (
              <ConnectionRow connection={connection} />
            ))}
          </div>
        ) : (
          <div className="flex gap-x-4">
            {integrationDataForServiceType.map((integrationDatum) => (
              <ConnectIntegrationForFirstTimeRow
                {...{
                  serviceTypeDatum,
                  integrationDatum,
                  hasNoPlan,
                  redirectingIntegration,
                  handleRedirect: () =>
                    handleOauthRedirect({
                      integration: integrationDatum.integration,
                      organisationId,
                      type: "integration",
                    }),
                }}
              />
            ))}
          </div>
        )}
      </CardContent>
      <CardFooter>
        <ConnectAnother serviceType={serviceType} />
      </CardFooter>
    </Card>
  );
};
