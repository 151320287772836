import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { Input } from "@/components/ui/input";
import { useOrganisationActions } from "@/context/OrganisationContext/actions/useOrganisationActions";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventForm, useAnalytics } from "@/hooks/useAnalytics";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

import "react-image-crop/dist/ReactCrop.css";
import { useUpdateState } from "@/hooks/useUpdateState";

import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from "react-image-crop";
import { useRef } from "react";
import { Plus } from "lucide-react";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "@/lib/firebase/app";
import { useQuery } from "@tanstack/react-query";
import { deleteField } from "firebase/firestore";
import { getEnvironment } from "@/lib/getEnvironment";
import {
  Environment,
  SubscriptionPlanType,
  getIsOnPlanEqualToOrHigherThan,
  organisationConfigDefaults,
} from "@fyxer-ai/shared";
import { getCompanyName } from "@/lib/getWhitelabelProvider";
import { Switch } from "@/components/ui/switch";

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const ImageUpload = ({
  aspectRatio,
  currentImageStoragePath,
  uploadPath,
  targetHeight,
  onUploadComplete,
  onDeleteComplete,
}: {
  aspectRatio: number;
  currentImageStoragePath?: string;
  uploadPath: string;
  targetHeight?: number;
  onUploadComplete?: (path: string) => void | Promise<void>;
  onDeleteComplete?: (path: string) => void | Promise<void>;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const cropImageRef = useRef<HTMLImageElement>(null);

  const [state, updateState] = useUpdateState<{
    file?: File;
    imgSrc?: string;
    crop?: Crop;
    completedCrop?: PixelCrop;
    isCropOpen: boolean;
    currentImageStoragePath: string | undefined;
  }>({ isCropOpen: false, currentImageStoragePath });

  const downloadPath = useQuery({
    queryKey: ["downloadUrl", currentImageStoragePath ?? "undefined"],
    queryFn: async () => {
      if (!currentImageStoragePath) return undefined;
      const url = await getDownloadURL(ref(storage, currentImageStoragePath));
      return url;
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={(event) => {
          const { files } = event.target;
          if (!files || files.length === 0) return;
          updateState({ file: files[0], imgSrc: URL.createObjectURL(files[0]), isCropOpen: true });
        }}
      />
      {state.currentImageStoragePath && (
        <div style={{ aspectRatio }} className="relative w-full max-w-96 overflow-hidden rounded-md">
          <img src={downloadPath.data} className="h-full w-full" style={{ aspectRatio }} />

          <div className="absolute bottom-4 left-4 right-4 flex gap-x-2">
            <Button variant="secondary" onClick={() => fileInputRef.current?.click()}>
              Replace
            </Button>
            <Button
              variant="secondary"
              onClick={async () => {
                if (!state.currentImageStoragePath) return;
                await deleteObject(ref(storage, state.currentImageStoragePath));
                await onDeleteComplete?.(state.currentImageStoragePath);
                updateState({ currentImageStoragePath: undefined });
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      {!state.file && !state.currentImageStoragePath && (
        <button
          onClick={() => fileInputRef.current?.click()}
          className="w-full max-w-96 rounded-md border border-slate-200 p-8 hover:bg-slate-50"
          style={{ aspectRatio }}
        >
          <Plus className="m-auto" />
          Upload
        </button>
      )}
      <Dialog open={state.isCropOpen} onOpenChange={(open) => updateState({ isCropOpen: open })}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Crop image</DialogTitle>
          </DialogHeader>
          <ReactCrop
            crop={state.crop}
            onChange={(_, percentCrop) => updateState({ crop: percentCrop })}
            onComplete={(c) => {
              updateState({ completedCrop: c });
              console.log(c);
            }}
            aspect={aspectRatio}
          >
            <img
              src={state.imgSrc}
              ref={cropImageRef}
              onLoad={(e) => {
                const { width, height } = e.currentTarget;
                updateState({ crop: centerAspectCrop(width, height, aspectRatio) });
              }}
            />
          </ReactCrop>
          <DialogFooter>
            <Button
              onClick={async () => {
                const cropImage = cropImageRef.current;
                if (!state.completedCrop || !cropImage) return;

                const scaleX = cropImage.naturalWidth / cropImage.clientWidth;
                const scaleY = cropImage.naturalHeight / cropImage.clientHeight;
                const width = state.completedCrop.width * scaleX;
                const height = state.completedCrop.height * scaleY;
                const x = state.completedCrop.x * scaleX;
                const y = state.completedCrop.y * scaleY;
                const canvas = document.createElement("canvas");
                const resizeRatio = targetHeight ? targetHeight / height : 1;
                canvas.width = width * resizeRatio;
                canvas.height = height * resizeRatio;
                const ctx = canvas.getContext("2d");
                console.log({
                  clientHeight: cropImage.clientHeight,
                  clientWidth: cropImage.clientWidth,
                  naturalHeight: cropImage.naturalHeight,
                  naturalWidth: cropImage.naturalWidth,
                  scaleX,
                  scaleY,
                  width,
                  height,
                  x,
                  y,
                  resizeRatio,
                });
                if (!ctx) {
                  throw new Error("Unable to get 2D context");
                }

                ctx.drawImage(cropImage, x, y, width, height, 0, 0, width * resizeRatio, height * resizeRatio);

                const blob = await new Promise<Blob>((resolve, reject) => {
                  canvas.toBlob((blob) => {
                    if (!blob) {
                      reject(new Error("No blob"));
                    } else {
                      resolve(blob);
                    }
                  }, "image/jpeg");
                });

                await uploadBytes(ref(storage, uploadPath), blob);

                updateState({
                  isCropOpen: false,
                  file: undefined,
                  imgSrc: undefined,
                  crop: undefined,
                  currentImageStoragePath: uploadPath,
                });

                downloadPath.refetch();

                await onUploadComplete?.(uploadPath);
              }}
            >
              Crop
            </Button>
            <Button
              variant="secondary"
              onClick={() => updateState({ isCropOpen: false, file: undefined, imgSrc: undefined })}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const OrganisationDetailsView = () => {
  const { updateOrg } = useOrganisationActions();
  const { organisation, isAdmin, organisationId, subscription } = useOrganisation();
  const planType = subscription?.data().planType;
  const { logFormSubmit } = useAnalytics();

  return (
    <div className="space-y-8">
      <h2>Details</h2>
      {!isAdmin ? <p>Only admins can edit your Organisation's details.</p> : null}

      <FormUtil
        schema={z.object({
          name: makeSchemaSafe(z.string().min(1).max(320)),
          domain: makeSchemaSafe(z.string().min(1).max(320)).optional(),
          shouldAutoAddUsersForDomain: z.boolean(),
        })}
        disabled={!isAdmin}
        defaultValues={{
          name: organisation.name,
          domain: organisation.domain,
          shouldAutoAddUsersForDomain:
            organisation.shouldAutoAddUsersForDomain ?? organisationConfigDefaults.shouldAutoAddUsersForDomain,
        }}
        onSubmit={async (data) => {
          logFormSubmit(EventForm.UPDATE_ORGANISATION);
          await updateOrg(data);
        }}
        submitTitle="Save changes"
        successMessage="Organisation updated"
        render={(form) => (
          <>
            <FormFieldUtil control={form.control} name="name" render={({ field }) => <Input {...field} />} />
            <FormFieldUtil
              control={form.control}
              name="domain"
              description={`Teammates with this email domain will automatically join this organisation when they sign up to ${getCompanyName()} if the "Automatically add users for this domain" option is enabled.`}
              render={({ field }) => <Input {...field} />}
            />
            <FormFieldUtil
              control={form.control}
              isInline
              name="shouldAutoAddUsersForDomain"
              title="Automatically add users for this domain"
              description={`Teammates with this email domain will automatically join this organisation when they sign up to ${getCompanyName()}`}
              render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
            />
          </>
        )}
      />

      {isAdmin &&
        (getEnvironment() !== Environment.PROD ||
          (planType && getIsOnPlanEqualToOrHigherThan(SubscriptionPlanType.PRO)(planType)) ||
          organisation.isPrivateBeta) && (
          <div className="space-y-2">
            <p className="text-sm font-medium">Meeting notetaker image</p>
            <p className="text-sm text-slate-500">
              This is the image that will show on your meeting notetaker for every member of your organisation. It will
              only show up for new meetings organised going forwards. The image should be 16:9 aspect ratio and at least
              720 pixels high.
            </p>
            <ImageUpload
              aspectRatio={16 / 9}
              targetHeight={360 * 2}
              currentImageStoragePath={organisation.customMeetingNotetakerImageStoragePath}
              uploadPath={`organisations/${organisationId}/meeting-notetaker-image.jpg`}
              onUploadComplete={(path) => updateOrg({ customMeetingNotetakerImageStoragePath: path })}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onDeleteComplete={() => updateOrg({ customMeetingNotetakerImageStoragePath: deleteField() as any })}
            />
          </div>
        )}
    </div>
  );
};
