import { AvatarProps } from "@radix-ui/react-avatar";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

interface Props {
  name?: string | null;
  photoUrl?: string | null;
}

const getInitials = (name: string) =>
  name
    .split(" ")
    .filter((n) => n.length > 0)
    .map((n) => n[0].toUpperCase())
    .join("");

const generateInteger = (name: string, max: number) =>
  [...name].reduce((hash, char) => {
    return Math.abs((hash << 5) - hash + char.charCodeAt(0)) | 0;
  }, 0) %
  (max - 1);

const colors = [
  "bg-purple-200 text-purple-600",
  "bg-blue-200 text-blue-600",
  "bg-green-200 text-green-600",
  "bg-red-200 text-red-600",
  "bg-orange-200 text-orange-600",
];

export function getRandomAvatarColor(name: string | undefined) {
  const strongName = name ?? "UN";
  return colors[generateInteger(strongName, colors.length)];
}

export const UserAvatar = ({ name, photoUrl, ...props }: Props & AvatarProps) => {
  const strongName = name ?? "UN";

  return (
    <Avatar {...props}>
      <AvatarImage src={photoUrl ?? undefined} />
      <AvatarFallback className={getRandomAvatarColor(strongName)}>{getInitials(strongName)}</AvatarFallback>
    </Avatar>
  );
};
