import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CallRecordingChatMessage, CallRecordingChatThread } from "@fyxer-ai/shared";

import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useEffect, useMemo } from "react";
import { Collection } from "@/lib/firebase/Collection";

export interface CallRecordingChatMessageWithId extends CallRecordingChatMessage {
  id: string;
}

interface CurrentChatThreadData {
  thread: CallRecordingChatThread | null;
  threadId: string | null;
  messages: CallRecordingChatMessageWithId[];
}

export const useCurrentChatThread = () => {
  const { currentThreadId, callChatThreads } = useCallRecording();
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => ["chat-messages", currentThreadId] as const, [currentThreadId]);

  const thread = callChatThreads.find((thread) => thread.id === currentThreadId)?.data() ?? null;

  useEffect(() => {
    if (!currentThreadId) return;

    const unsubscribe = onSnapshot(
      query(
        Collection.CallRecordingChatMessage,
        where("callChatThreadId", "==", currentThreadId),
        orderBy("timestamp", "asc"),
      ),
      (snapshot) => {
        const messages = snapshot.docs.map((doc) => ({ ...(doc.data() as CallRecordingChatMessage), id: doc.id }));
        queryClient.setQueryData(queryKey, messages);
      },
    );

    return () => unsubscribe();
  }, [currentThreadId, queryKey, queryClient]);

  const { data: messages = [] } = useQuery({
    queryKey,
    queryFn: () => new Promise<CallRecordingChatMessageWithId[]>(() => {}),
    enabled: !!currentThreadId,
  });

  return {
    thread,
    messages,
    threadId: currentThreadId,
  } as CurrentChatThreadData;
};
