import { Clock, Send, ExternalLink, ThumbsUp, ThumbsDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { formatMilliseconds } from "../transcript/formatMilliseconds";

import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";

import { useSearchParams } from "react-router-dom";
import { useChatMessageRating } from "./hooks/useChatMessageRating";
import { useChatMessageSender } from "./hooks/useChatMessageSender";
import { CallRecordingChatMessageWithId, useCurrentChatThread } from "./hooks/useCurrentChatThread";
import { DEFAULT_CHAT_MESSAGES } from "./constants";
import { EventButton, EventName, useAnalytics } from "@/hooks/useAnalytics";
import { formatDate } from "date-fns";
import { toast } from "@/components/ui/use-toast";
import Markdown from "react-markdown";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";

interface ChatMessageProps {
  chatMessage: CallRecordingChatMessageWithId;
}

const ChatMessage = ({ chatMessage }: ChatMessageProps) => {
  const { content, role, timestamp, relevantTranscriptSegments, rating } = chatMessage;

  const { transcriptSegments } = useCallRecording();
  const { logButtonPress } = useAnalytics();
  const { canVote, handleRating } = useChatMessageRating();
  const navigate = useComplexNavigate();

  const hasVoted = rating !== undefined;

  const segments = useMemo(() => {
    return transcriptSegments
      .filter((segment) => relevantTranscriptSegments?.some((ref) => ref.startMs === segment.data().startMs))
      .map((ref) => ref.data());
  }, [transcriptSegments, relevantTranscriptSegments]);

  const onNavigateToTranscript = (timestamp: number) => {
    logButtonPress(EventButton.RELATED_TRANSCRIPT_SEGMENT_CLICK);
    navigate({ path: "", hash: { tab: "transcript" }, search: { query: `ts:${timestamp}` } });
  };

  return (
    <div className={`flex ${role === "user" ? "justify-end" : "justify-start"}`}>
      <div className={`max-w-[80%] rounded-lg p-3 ${role === "user" ? "bg-slate-200" : "bg-slate-100"}`}>
        <Markdown className="chat-message space-y-4">{content}</Markdown>
        <div className="mt-2 flex items-center justify-between">
          <span className="text-xs opacity-70">{timestamp.toLocaleDateString()}</span>
        </div>
        {role === "assistant" && (
          <div className="relative mt-2 min-h-3">
            {relevantTranscriptSegments && relevantTranscriptSegments.length > 0 && (
              <Accordion type="single" collapsible>
                <AccordionItem value="references" className="border-none">
                  <AccordionTrigger className="text-muted-foreground flex w-auto justify-start gap-2 py-0 text-xs hover:no-underline">
                    <span>View relevant transcript excerpts</span>
                  </AccordionTrigger>
                  <AccordionContent className="space-y-2 pt-2">
                    {segments.map((segment) => (
                      <button
                        className="group w-full text-left"
                        key={segment.startMs}
                        onClick={() => onNavigateToTranscript(segment.startMs)}
                        title="Go to this part of the transcript"
                      >
                        <Card className="border-dashed bg-slate-100 p-2 group-hover:bg-slate-200/50">
                          <div className="flex items-start justify-between">
                            <div className="flex-1">
                              <p className="text-xs font-medium">
                                {segment.speaker}{" "}
                                <span className="text-muted-foreground">at {formatMilliseconds(segment.startMs)}</span>
                              </p>
                              <p className="mt-1 line-clamp-2 text-sm">{segment.text}</p>
                            </div>

                            <ExternalLink className="h-3 w-3" />
                          </div>
                        </Card>
                      </button>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}

            {canVote && (
              <div className="absolute -top-1 right-0 flex items-center gap-1">
                {[true, false].map((rating, i) => (
                  <Button
                    key={i}
                    variant="ghost"
                    size="icon"
                    onClick={() => handleRating(chatMessage.id, rating)}
                    title={hasVoted ? "Thank you for your feedback!" : `This was ${rating ? "helpful" : "not helpful"}`}
                    disabled={hasVoted}
                    className={`h-6 w-6 p-0 ${
                      rating
                        ? "text-green-600 hover:bg-green-50 hover:text-green-700"
                        : "text-red-600 hover:bg-red-50 hover:text-red-700"
                    } ${hasVoted ? "cursor-not-allowed opacity-50" : ""}`}
                  >
                    {rating ? <ThumbsUp size={14} /> : <ThumbsDown size={14} />}
                  </Button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const MAX_USER_MESSAGES = 10;

export const ChatTab = () => {
  const { callRecordingId, callChatThreads, updateState } = useCallRecording();
  const { logEvent } = useAnalytics();
  const { thread, messages, threadId } = useCurrentChatThread();
  const sendMessage = useChatMessageSender(callRecordingId, threadId);
  const [searchParams, setSearchParams] = useSearchParams();
  const hasHandledPrefilledQuestion = useRef(false);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState("");

  const userMessageCount = useMemo(() => {
    return messages.filter((msg) => msg.role === "user").length;
  }, [messages]);

  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, sendMessage.isPending, scrollToBottom]);

  const handleSendMessage = useCallback(
    async (message: string, { clearThread }: { clearThread?: boolean } = {}) => {
      if (!message.trim()) return;

      if (userMessageCount === MAX_USER_MESSAGES - 1) {
        toast({
          title: "Message limit reached",
          description:
            "You've reached the maximum number of messages allowed in this conversation. Please start a new conversation.",
        });
      }

      try {
        await sendMessage.mutateAsync({ message, clearThread });
        setMessage("");
      } catch {
        toast({
          title: "Failed to send message",
          description: "Please try again",
          variant: "destructive",
        });
      }
    },
    [sendMessage, userMessageCount],
  );

  useEffect(() => {
    const prefilledQuestion = searchParams.get("q");
    if (prefilledQuestion && !hasHandledPrefilledQuestion.current) {
      console.log("Create new thread");
      handleSendMessage(decodeURIComponent(prefilledQuestion), { clearThread: true });
      hasHandledPrefilledQuestion.current = true;
      setSearchParams({});
    }
  }, [searchParams, handleSendMessage, setSearchParams]);

  return (
    <div className="flex h-full flex-col">
      {callChatThreads.length > 0 && (
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="past-conversations">
            <div className="flex items-center justify-between">
              <AccordionTrigger className="flex-1">
                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4" />
                  <span className="text-sm">Past Conversations</span>
                </div>
              </AccordionTrigger>
              <Button
                variant="outline"
                size="sm"
                onClick={() => updateState({ currentThreadId: null })}
                className="ml-2"
              >
                New Chat
              </Button>
            </div>
            <AccordionContent className="p-0">
              <div className="max-h-48 overflow-y-auto border-t">
                <div className="space-y-1">
                  {callChatThreads.map((threadDoc) => (
                    <Button
                      key={threadDoc.id}
                      variant={threadDoc.id === threadId ? "secondary" : "ghost"}
                      className="w-full justify-start text-left"
                      onClick={() => updateState({ currentThreadId: threadDoc.id })}
                    >
                      <div className="flex flex-col items-start">
                        <span className="truncate">{threadDoc.data().title}</span>
                        <span className="text-xs opacity-70">
                          {formatDate(threadDoc.data().createdAt, "MMM d, yyyy")}
                        </span>
                      </div>
                    </Button>
                  ))}
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}

      <div ref={messagesContainerRef} className="flex-1 overflow-y-auto py-4">
        {thread ? (
          <div className="space-y-4">
            {messages.map((message) => (
              <ChatMessage key={message.id} chatMessage={message} />
            ))}
            {sendMessage.isPending && (
              <div className="flex justify-start">
                <div className="max-w-[80%] animate-pulse rounded-lg bg-slate-100 p-3">
                  <p className="whitespace-pre-wrap">Thinking...</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex h-full items-center justify-center">
            <p className="text-gray-500">Select a conversation or start a new one</p>
          </div>
        )}
      </div>

      <div className="border-t p-4">
        {!thread && (
          <div className="flex flex-wrap gap-2">
            {DEFAULT_CHAT_MESSAGES.map((msg) => (
              <Button
                key={msg}
                variant="outline"
                size="sm"
                onClick={() => {
                  handleSendMessage(msg);
                  logEvent(EventName.DEFAULT_CHAT_MESSAGE_SENT, {
                    message: msg,
                  });
                }}
                disabled={userMessageCount >= MAX_USER_MESSAGES}
              >
                {msg}
              </Button>
            ))}
          </div>
        )}
        <div className="mt-4 flex gap-2">
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage(message);
              }
            }}
            placeholder={
              userMessageCount >= MAX_USER_MESSAGES ? "Message limit reached" : "Ask a question about this meeting..."
            }
            disabled={sendMessage.isPending || userMessageCount >= MAX_USER_MESSAGES}
          />
          <Button
            onClick={() => handleSendMessage(message)}
            disabled={sendMessage.isPending || userMessageCount >= MAX_USER_MESSAGES}
          >
            {sendMessage.isPending ? "Sending..." : <Send className="h-4 w-4" />}
          </Button>
        </div>
      </div>
    </div>
  );
};
