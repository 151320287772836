import { useLocation } from "react-router-dom";

/**
 * A custom hook that parses the URL hash parameters.
 * @returns An object containing the hash parameters as key-value pairs.
 * @example
 * // Assuming the URL is http://example.com/#key1=value1&key2=value2
 * const params = useHashParams();
 * console.log(params); // { key1: 'value1', key2: 'value2' }
 */
export const useHashParams = () => {
  const { hash } = useLocation();
  const hashParams = new URLSearchParams(hash.slice(1));
  return Object.fromEntries(hashParams.entries());
};
