import { BillingCycle, OauthServiceType, SubscriptionPlanType } from "@fyxer-ai/shared";
import { ReactNode } from "react";
import { toTextCase } from "js-convert-case";

import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { useAnalytics, EventButton } from "@/hooks/useAnalytics";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";
import { useClickProps } from "@/hooks/useClickProps";
import { useApi } from "@/hooks/useApi";

import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { DialogClose, DialogTitle, DialogTrigger } from "@radix-ui/react-dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";

import { serviceTypeData } from "./serviceTypeData";
import { integrationData } from "./integrationData";
import { ConnectAnotherIntegrationRow } from "./ConnectAnotherIntegrationRow";
import { useOauthRedirect } from "./useOauthRedirect";
import { unwrap } from "@/lib/firebase/unwrap";

const ConfirmDialog = ({ trigger, clickProps }: { trigger: ReactNode; clickProps: object }) => (
  <Dialog>
    <DialogTrigger asChild>{trigger}</DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Upgrade to the Pro plan</DialogTitle>
        <DialogDescription>
          By upgrading, you'll be charged $50/user/seat rather than $30/user/seat. You'll stil get a discount if paying
          annually.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose>
          <Button variant="secondary">Cancel</Button>
        </DialogClose>
        <Button {...clickProps} />
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

export function ConnectAnother({ serviceType }: { serviceType: OauthServiceType }) {
  const { logButtonPress } = useAnalytics();
  const navigate = useComplexNavigate();
  const { toast } = useToast();
  const api = useApi();
  const { handleOauthRedirect, redirectingIntegration } = useOauthRedirect();
  const {
    organisationId,
    planData: { planType },
    subscription,
    memberships,
    connections,
  } = useOrganisation();

  const connectionsForServiceType = connections.filter(unwrap((connection) => connection.serviceType === serviceType));
  const hasConnection = connectionsForServiceType.length > 0;

  const isStandard = planType === SubscriptionPlanType.STANDARD;
  const isFreePlan = planType === "FREE";
  const hasNoPlan = isStandard || isFreePlan;

  const handleRedirectToBilling = () => {
    logButtonPress(EventButton.SELECT_PLAN, { planName: SubscriptionPlanType.PRO });
    navigate({ path: "", hash: { tab: "billing" } });
  };

  const clickProps = useClickProps({
    onClick: async () => {
      if (subscription) {
        logButtonPress(EventButton.UPGRADE_TO_PRO, {
          organisationId,
          subscriptionId: subscription?.id,
        });

        await api.organisations
          .id(organisationId)
          .subscriptions.id(subscription.id)
          .update({
            billingCycle: BillingCycle.ANNUALLY,
            planType: SubscriptionPlanType.PRO,
            seatCount: memberships.length ?? 1,
          });

        toast({
          title: `Upgrade successful`,
          description: `Your team is now on the Pro Plan!`,
        });
      }
    },
    buttonText: "Upgrade",
  });

  if (hasNoPlan && hasConnection) {
    return (
      <p className="text-sm">
        {!subscription ? (
          <span
            onClick={handleRedirectToBilling}
            role="button"
            className="cursor-pointer text-sm font-bold text-purple-700 underline"
          >
            Upgrade to Pro
          </span>
        ) : (
          <ConfirmDialog
            trigger={
              <span role="button" className="cursor-pointer text-sm font-bold text-purple-700 underline">
                Upgrade to Pro
              </span>
            }
            clickProps={clickProps}
          />
        )}{" "}
        to connect another
      </p>
    );
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="secondary">Connect another</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Connect your {toTextCase(serviceType)}</DialogTitle>
          <DialogDescription>{serviceTypeData.find((s) => s.serviceType === serviceType)?.strapline}</DialogDescription>
        </DialogHeader>
        <div>
          {integrationData
            .filter((integration) => integration.serviceType === serviceType)
            .map((integrationDatum) => {
              const { integration } = integrationDatum;
              return (
                <ConnectAnotherIntegrationRow
                  {...{
                    integrationDatum,
                    redirectingIntegration,
                    handleRedirect: () => handleOauthRedirect({ integration, organisationId, type: "integration" }),
                  }}
                />
              );
            })}
        </div>
      </DialogContent>
    </Dialog>
  );
}
