"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import { toHeaderCase } from "js-convert-case";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

type SelectItem<T> = {
  value: T;
  label: string;
};

interface Props<T extends string | number>
  extends Omit<SelectPrimitive.SelectProps, "onValueChange" | "defaultValue" | "value" | "onChange"> {
  placeholder?: string;
  items: (T | SelectItem<T>)[];
  className?: string;
  onChange: (value: T) => void;
  value?: T;
}

export const SelectUtil = <T extends string | number>({
  placeholder,
  items,
  className,
  onChange,
  value,
  ...props
}: Props<T>) => {
  const selectItems: SelectItem<T>[] = items.map((item) => {
    if (typeof item === "object" && item !== null && "value" in item && "label" in item) {
      return item;
    }
    return {
      value: item,
      label: toHeaderCase(String(item)),
    };
  });

  return (
    <Select
      {...props}
      onValueChange={(val) => {
        const originalItem = selectItems.find((item) => String(item.value) === val);
        if (originalItem && typeof originalItem.value === "number") {
          onChange(Number(val) as T);
        } else {
          onChange(val as T);
        }
      }}
      defaultValue={String(value)}
    >
      <SelectTrigger className={className ?? ""}>
        <SelectValue placeholder={placeholder ?? "Select"} />
      </SelectTrigger>
      <SelectContent>
        {selectItems.map(({ value, label }) => (
          <SelectItem key={String(value)} value={String(value)}>
            {label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
