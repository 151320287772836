import Markdown from "react-markdown";
import { ThumbsUp, ThumbsDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { EventName, useAnalytics, useFeatureFlag } from "@/hooks/useAnalytics";
import { useToast } from "@/components/ui/use-toast";
import { Separator } from "@/components/ui/separator";
import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Send } from "lucide-react";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";

function ChatInput() {
  const [question, setQuestion] = useState("");

  const navigate = useComplexNavigate();

  const handleSubmitQuestion = (e: React.FormEvent) => {
    e.preventDefault();
    if (!question.trim()) return;

    const encodedQuestion = encodeURIComponent(question);

    navigate({ path: "", hash: { tab: "chat" }, search: { q: encodedQuestion } });
  };

  return (
    <form
      onSubmit={handleSubmitQuestion}
      className="fixed bottom-12 left-1/2 w-[70vw] max-w-2xl -translate-x-1/2 px-4 md:absolute md:w-full"
    >
      <div className="flex items-center gap-2 rounded-lg border bg-white p-2 shadow-lg">
        <Input
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Ask a question about this meeting..."
          className="flex-1"
        />
        <Button type="submit" size="icon" disabled={!question.trim()}>
          <Send size={20} />
        </Button>
      </div>
    </form>
  );
}

export const SummaryTab = () => {
  const { callSummary, callRecordingId } = useCallRecording();
  const { logEvent } = useAnalytics();
  const isChatWithTranscriptEnabled = useFeatureFlag("chat-with-transcript");
  const { toast } = useToast();
  const [hasVoted, setHasVoted] = useState(false);

  useEffect(() => {
    const votedSummaries = JSON.parse(localStorage.getItem("votedSummaries") || "[]");
    setHasVoted(votedSummaries.includes(callRecordingId));
  }, [callRecordingId]);

  if (!callSummary) return <p>No summary found</p>;

  const { content } = callSummary.data();

  const handleRating = (isGood: boolean) => {
    if (hasVoted) return;

    logEvent(EventName.MEETING_SUMMARY_RATING, {
      callRecordingId,
      isSummaryGood: isGood,
    });

    const votedSummaries = JSON.parse(localStorage.getItem("votedSummaries") || "[]");
    votedSummaries.push(callRecordingId);
    localStorage.setItem("votedSummaries", JSON.stringify(votedSummaries));
    setHasVoted(true);

    toast({
      title: isGood ? "Thank you for your positive rating!" : "Thank you for your feedback",
      description: isGood
        ? "Your rating helps us improve our meeting notetaker."
        : "We appreciate your feedback and will use it to improve our meeting notetaker.",
    });
  };

  return (
    <div className="pb-12">
      <Markdown className="meeting-summary space-y-4">{content}</Markdown>
      <Separator className="mb-4 mt-8 bg-slate-200" />
      <div className="flex items-center gap-2">
        <span className="text-sm text-slate-500">Was this summary helpful?</span>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => handleRating(true)}
          disabled={hasVoted}
          className={`text-green-600 hover:bg-green-50 hover:text-green-700 ${hasVoted ? "cursor-not-allowed opacity-50" : ""}`}
        >
          <ThumbsUp size={20} />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => handleRating(false)}
          disabled={hasVoted}
          className={`text-red-600 hover:bg-red-50 hover:text-red-700 ${hasVoted ? "cursor-not-allowed opacity-50" : ""}`}
        >
          <ThumbsDown size={20} />
        </Button>
        {hasVoted && <span className="text-sm text-slate-500">Thank you for your feedback!</span>}
      </div>

      {isChatWithTranscriptEnabled.value() && <ChatInput />}
    </div>
  );
};
