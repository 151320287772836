import { QueryDocumentSnapshot } from "firebase/firestore";
import { createContext, useContext, ReactNode, RefObject } from "react";
import { TranscriptSegment as TranscriptSegmentType } from "@fyxer-ai/shared";

interface TranscriptContextType {
  searchValue: string;
  searchIndex?: number;
  transcriptSegments: QueryDocumentSnapshot<TranscriptSegmentType>[];
  transcriptSegmentIds: string[];
  updateState: (updates: Partial<TranscriptContextType>) => void;

  matchedTranscriptSegmentIds: string[];
  transcriptRefs: RefObject<HTMLDivElement>[];
}

const TranscriptContext = createContext<TranscriptContextType | undefined>(undefined);

export const useTranscript = () => {
  const context = useContext(TranscriptContext);
  if (!context) {
    throw new Error("useTranscript must be used within a TranscriptProvider");
  }
  return context;
};

interface TranscriptProviderProps {
  children: ReactNode;
  value: TranscriptContextType;
}

export const TranscriptProvider = ({ children, value }: TranscriptProviderProps) => {
  return <TranscriptContext.Provider value={value}>{children}</TranscriptContext.Provider>;
};
