import { BoxContainer } from "@/components/ui/boxContainer";
import { EventDetailsPanel } from "@/components/ui/eventDetailsPanel";
import { SlotSelectionPanel } from "@/components/ui/slotSelectionPanel";
import { useSchedulingRequest } from "@/context/SchedulingContext/state/useSchedulingRequest";
import { useEffect, useState } from "react";
import { UserDetailsForm } from "@/components/ui/schedulerInfoForm";
import { ConfirmationComponent } from "@/components/ui/ScheduleConfirmationComponent";
import { Collection } from "@/lib/firebase/Collection";
import { doc, updateDoc } from "firebase/firestore";
import { getSiteVisitorsTimeZone } from "@/lib/getSiteVisitorsTimeZone";

export type SchedulingStates = "time-selection" | "details" | "pending-event" | "confirmation";

export const SchedulingRequestView = () => {
  const { schedulingRequest, schedulingRequestId } = useSchedulingRequest();

  // TO-DO: When making this update with availability, make an error view for if there are no slots left to choose.
  const [step, setStep] = useState<SchedulingStates>(() =>
    schedulingRequest.hasCalendarLinkBeenSubmitted
      ? schedulingRequest.hasEventBeenCreated
        ? "confirmation"
        : "pending-event"
      : "time-selection",
  );

  useEffect(() => {
    if (schedulingRequest.hasCalendarLinkBeenSubmitted) {
      setStep(schedulingRequest.hasEventBeenCreated ? "confirmation" : "pending-event");
    }
  }, [schedulingRequest.hasCalendarLinkBeenSubmitted, schedulingRequest.hasEventBeenCreated]);

  const [selectedTime, setSelectedTime] = useState<{ startsAt: Date; endsAt: Date; usersTimeZone: string } | undefined>(
    undefined,
  );

  const isAtLeastOneInvitee = schedulingRequest.inferredEventData.emailPeopleToInvite.length > 0;
  const defaultAddress = isAtLeastOneInvitee ? schedulingRequest.inferredEventData.emailPeopleToInvite[0].address : "";
  const defaultName = isAtLeastOneInvitee
    ? (schedulingRequest.inferredEventData.emailPeopleToInvite[0].name ?? "")
    : "";
  // A rare case where custom email clients put a name field but it is just the same as the email address
  const isNameTheSameAsAddress = defaultAddress === defaultName;

  const [details, setDetails] = useState<{ email: string; name: string }>({
    email: defaultAddress,
    name: isNameTheSameAsAddress ? "" : defaultName,
  });

  const handleTimeSelect = (slot: { startsAt: Date; endsAt: Date; usersTimeZone: string }) => {
    setSelectedTime(slot);
    setStep("details");
  };

  const handleBackSelect = () => {
    setStep("time-selection");
  };

  const updateSchedulingRequest = async ({
    schedulerDetails,
    selectedTime,
    schedulingRequestId,
  }: {
    schedulerDetails: { email: string; name: string };
    selectedTime: { startsAt: Date; endsAt: Date; usersTimeZone: string };
    schedulingRequestId: string;
  }) => {
    const calendarLinkData = {
      chosenSlot: selectedTime,
      schedulerDetails: {
        address: schedulerDetails.email,
        name: schedulerDetails.name,
      },
      calendarLinkSubmittedAt: new Date(),
    };
    await updateDoc(doc(Collection.CalendarSchedulingRequest, schedulingRequestId), {
      calendarLinkData,
      hasCalendarLinkBeenSubmitted: true,
    });
  };

  const handleDetailsSubmit = async ({ email, name }: { email: string; name: string }) => {
    if (!selectedTime) {
      throw new Error("No time selected");
    }
    await updateSchedulingRequest({ schedulerDetails: { email, name }, selectedTime, schedulingRequestId });
    setDetails({ email, name });
    setStep("pending-event");
  };

  const detailsPanelProps = {
    schedulingRange: {
      startsAt: new Date(Math.min(...schedulingRequest.suggestedSlots.map((slot) => slot.startsAt.getTime()))),
      endsAt: new Date(Math.max(...schedulingRequest.suggestedSlots.map((slot) => slot.endsAt.getTime()))),
    },
    schedulersTimeZone: getSiteVisitorsTimeZone(),
    durationMins: schedulingRequest.inferredEventData.durationMins,
    title: schedulingRequest.inferredEventData.title,
    userName: schedulingRequest.userData.userName,
    chosenTime: schedulingRequest.calendarLinkData?.chosenSlot ?? selectedTime,
    meetingLink: schedulingRequest.eventCreationData?.meetingLink,
  };

  return (
    <>
      <BoxContainer
        leftContent={<EventDetailsPanel eventDetails={detailsPanelProps} state={step} />}
        rightContent={(() => {
          switch (step) {
            case "time-selection":
              return (
                <SlotSelectionPanel
                  suggestedSlots={schedulingRequest.suggestedSlots}
                  schedulingTimeZone={detailsPanelProps.schedulersTimeZone}
                  onSlotSelect={handleTimeSelect}
                />
              );
            case "details":
              return <UserDetailsForm onSubmit={handleDetailsSubmit} details={details} />;
            case "pending-event":
            case "confirmation":
              return <ConfirmationComponent userName={schedulingRequest.userData.userName} state={step} />;
            default:
              return null;
          }
        })()}
        onBackClick={step === "details" ? handleBackSelect : undefined}
      />
    </>
  );
};
