import { useMutation } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { useToast } from "@/components/ui/use-toast";
import { auth } from "@/lib/firebase/app";
import { useAnalytics } from "@/hooks/useAnalytics";
import { LocalStorageStringKey, storage } from "@/lib/storage";

export const useSignOut = () => {
  const navigate = useNavigate();
  const { reset } = useAnalytics();
  const { toast } = useToast();
  return useMutation({
    mutationFn: () => signOut(auth),
    onSuccess: () => {
      reset();
      storage.local.string(LocalStorageStringKey.USER_ID).remove();
      toast({
        title: "Signed out",
        description: "You signed out successfully",
      });
      navigate("/");
    },
  });
};
