import { addDays, formatDate } from "date-fns";
import { Bell, Star, Unlock } from "lucide-react";
import { SubscriptionPlanType, BillingCycle, formatCurrency, Currency, getAnnualPriceId } from "@fyxer-ai/shared";

import { redirectExternally } from "@/lib/redirectExternally";
import { getEnvironment } from "@/lib/getEnvironment";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { useApi } from "@/hooks/useApi";
import { Button } from "./ui/button";
import { useState } from "react";
import { EventButton, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";

export const useCheckout = () => {
  const { organisationId, memberships } = useOrganisation();
  const api = useApi();
  const { logButtonPress } = useAnalytics();

  const startCheckout = async (
    location = "free_trial_guard",
    {
      trial_length,
      priceId,
      billingCycle,
      planType,
    }: { trial_length: number; billingCycle: BillingCycle; priceId?: string; planType?: SubscriptionPlanType } = {
      trial_length: 7,
      billingCycle: BillingCycle.MONTHLY,
      planType: SubscriptionPlanType.STANDARD,
      priceId: undefined,
    },
  ) => {
    const details = {
      planType: planType || SubscriptionPlanType.STANDARD,
      seatCount: memberships.length,
      billingCycle,
      priceId,
      ...(Number(trial_length) > 0 ? { trialLength: trial_length } : {}),
    };
    logButtonPress(EventButton.START_SUBSCRIPTION, { ...details, location });
    try {
      const checkoutSessionUrl = await api.organisations.id(organisationId).checkoutSessions.create(details);
      redirectExternally(checkoutSessionUrl);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    start: startCheckout,
  };
};

export const calculateCost = ({
  billingCycle,
  membershipsLength,
}: {
  billingCycle: BillingCycle;
  membershipsLength: number;
}) => {
  const cost = billingCycle === BillingCycle.MONTHLY ? 50 : 450;
  return cost * membershipsLength;
};

const FreeTrialPopup = ({
  title,
  trialLength = 7,
  onSkip,
  showSkip,
}: {
  title?: string;
  trialLength?: number;
  onSkip: () => void;
  showSkip: boolean;
}) => {
  const checkout = useCheckout();
  const { memberships } = useOrganisation();

  const endTrialDate = addDays(Date.now(), trialLength);
  const [billingCycle, setBillingCycle] = useState<BillingCycle>(BillingCycle.ANNUALLY);

  useLogPageView(EventPage.FREE_TRIAL_POPUP);

  const costArgs = { membershipsLength: memberships.length };
  const monthlyCost = calculateCost({ billingCycle: BillingCycle.MONTHLY, ...costArgs });
  const cost = calculateCost({ billingCycle, ...costArgs });
  const planType = SubscriptionPlanType.PRO;
  const interval = billingCycle === BillingCycle.MONTHLY ? "month" : "year";

  return (
    <div className="mx-auto mt-6 max-w-lg px-3 pb-12 sm:pt-12">
      <h1 className="mb-4 text-center text-3xl">{title ?? "Start your free trial"}</h1>
      {billingCycle === BillingCycle.ANNUALLY ? (
        <p className="mb-6 text-center">
          First {trialLength} days free, then{" "}
          <s className="text-gray-500">
            {formatCurrency({
              currency: Currency.USD,
              value: monthlyCost,
            })}
          </s>{" "}
          <span className="font-semibold text-purple-600">
            {formatCurrency({
              currency: Currency.USD,
              value: cost / 12,
            })}
          </span>{" "}
          / month
        </p>
      ) : (
        <p className="mb-6 text-center">
          First {trialLength} days free, then{" "}
          {formatCurrency({
            currency: Currency.USD,
            value: cost,
          })}{" "}
          / {interval}
        </p>
      )}
      <div className="mb-8">
        <div className="mx-auto grid grid-cols-2 items-center">
          <div className="flex items-center justify-end gap-x-3">
            <Label className={`${billingCycle === BillingCycle.MONTHLY ? "font-bold" : "text-gray-500"}`}>
              Monthly
            </Label>
            <Switch
              checked={billingCycle === BillingCycle.ANNUALLY}
              onCheckedChange={(checked) => setBillingCycle(checked ? BillingCycle.ANNUALLY : BillingCycle.MONTHLY)}
            />
          </div>
          <div className="flex items-center gap-x-3 pl-3">
            <Label className={`${billingCycle === BillingCycle.ANNUALLY ? "font-bold" : "text-gray-500"}`}>
              Yearly
            </Label>
            <div className="rounded-md bg-yellow-200 px-2 py-1 text-xs font-semibold">3 months free</div>
          </div>
        </div>
      </div>
      <div className="relative mx-auto max-w-[350px]">
        <span className="absolute left-[11px] top-[6px] z-[-1] h-[204px] w-[6px] bg-blue-300"></span>
        <div className="mb-6 flex items-start gap-6">
          <div className="flex items-center justify-center rounded-full bg-blue-700 p-2">
            <Unlock size={12} strokeWidth={2} className="flex-shrink-0 stroke-white" />
          </div>
          <div className="flex-grow">
            <p className="mb-1 font-semibold">Today</p>
            <p className="text-sm text-gray-500">
              Unlock access to the AI email organizer, draft replier, and meeting notetaker.
            </p>
          </div>
        </div>
        <div className="mb-6 flex items-start gap-6">
          <div className="flex items-center justify-center rounded-full bg-blue-700 p-2">
            <Bell size={12} strokeWidth={2} className="flex-shrink-0 stroke-white" />
          </div>
          <div className="flex-grow">
            <p className="mb-1 font-semibold">In {trialLength - 2} days</p>
            <p className="text-sm text-gray-500">We'll send you a reminder that your trial is ending soon.</p>
          </div>
        </div>
        <div className="flex items-start gap-6">
          <div className="flex items-center justify-center rounded-full bg-blue-700 p-2">
            <Star size={12} strokeWidth={2} className="flex-shrink-0 stroke-white" />
          </div>
          <div className="flex-grow">
            <p className="mb-1 font-semibold">In {trialLength} days</p>
            <p className="text-sm text-gray-500">
              You'll be charged on {formatDate(endTrialDate, "MMM d")},{" "}
              <span className="font-bold text-gray-500">cancel anytime</span> before.
            </p>
          </div>
        </div>
        <Button
          className="mt-8 w-full py-6 text-lg"
          onClick={() => {
            let priceId = undefined;

            if (billingCycle === BillingCycle.ANNUALLY) {
              priceId = getAnnualPriceId(getEnvironment(), true);
            }

            return checkout.start("free_trial_guard", {
              trial_length: trialLength,
              billingCycle,
              planType,
              ...(priceId ? { priceId } : {}),
            });
          }}
        >
          Start free trial
        </Button>
        {showSkip && (
          <Button className="mt-2 w-full text-sm text-gray-400" variant="link" onClick={onSkip}>
            Skip offer
          </Button>
        )}
      </div>
    </div>
  );
};

export default FreeTrialPopup;
