import {
  AutoJoinMeetingConfiguration,
  configDefaults,
  constructUrl,
  deduplicate,
  getFriendlyLabelName,
  LabelName,
  Membership,
  OauthServiceType,
  sort,
  SubscriptionPlanType,
  UpsellLocation,
  getIsOnPlanEqualToOrHigherThan,
} from "@fyxer-ai/shared";
import { useLayoutEffect } from "react";
import { toHeaderCase, toLowerCase } from "js-convert-case";
import { AlertCircle, Info } from "lucide-react";
import { Link } from "react-router-dom";
import { z } from "zod";
import { HexColorPicker } from "react-colorful";

import { useQueryParams } from "@/hooks/useQueryParams";

import { ArrayField } from "@/components/controls/ArrayField";
import { ComboboxUtil } from "@/components/controls/ComboboxUtil";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { HTMLPaster } from "@/components/controls/HTMLPaster";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { Button } from "@/components/ui/button";
import { Input, NumberInput } from "@/components/ui/input";
import { SliderRoot, SliderThumb, SliderTrack } from "@/components/ui/slider";
import { Switch } from "@/components/ui/switch";
import { useOrganisationActions } from "@/context/OrganisationContext/actions/useOrganisationActions";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventForm, useAnalytics } from "@/hooks/useAnalytics";
import { unwrap } from "@/lib/firebase/unwrap";
import { getTimeZones } from "@/lib/getTimeZones";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";
import { cn } from "@/lib/utils";

import { labelData } from "./labelData";
import { getCompanyName } from "@/lib/getWhitelabelProvider";
import rawFonts from "@/fonts.json";
import { Checkbox } from "@/components/ui/checkbox";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

const fonts = rawFonts as string[];

const ConnectionWarning = ({ serviceTypes }: { serviceTypes: OauthServiceType[] }) => {
  const { connections, organisationId } = useOrganisation();

  const unconnectedServceTypes = serviceTypes.filter((serviceType) =>
    connections.every(unwrap((connection) => connection.serviceType !== serviceType)),
  );
  const hasConnections = unconnectedServceTypes.length === 0;

  if (hasConnections) return null;

  const integrationsPath = constructUrl({
    path: `/org/${organisationId}/settings`,
    hash: { tab: "integrations" },
  });

  return (
    <div className="flex items-center gap-x-2 rounded-xl border border-black bg-slate-50 p-4">
      <AlertCircle />
      <p className="text-sm text-gray-500">
        You need to{" "}
        <Link to={integrationsPath} className="font-semibold text-black hover:text-gray-500">
          connect your {unconnectedServceTypes.map(toLowerCase).join(" and ")}
        </Link>{" "}
        for this feature to work.
      </p>
    </div>
  );
};

const PlanWarning = ({ minimumPlanType }: { minimumPlanType: SubscriptionPlanType | "FREE" }) => {
  const {
    organisationId,
    planData: { planType },
  } = useOrganisation();

  if (getIsOnPlanEqualToOrHigherThan(minimumPlanType)(planType)) return null;

  const billingPath = constructUrl({
    path: `/org/${organisationId}/settings`,
    hash: { tab: "billing" },
  });

  return (
    <div className="flex items-center gap-x-2 rounded-xl border border-black bg-slate-50 p-4">
      <AlertCircle />
      <p className="text-sm text-gray-500">
        You need to{" "}
        <Link to={billingPath} className="font-semibold text-black hover:text-gray-500">
          {minimumPlanType === SubscriptionPlanType.STANDARD
            ? "upgrade to a paid plan"
            : `upgrade to the ${minimumPlanType} plan`}
        </Link>{" "}
        to enable this feature. You're currently on the {toHeaderCase(planType)} plan.
      </p>
    </div>
  );
};

export const getMeetingConfigName = (config: AutoJoinMeetingConfiguration) => {
  switch (config) {
    case AutoJoinMeetingConfiguration.ALWAYS:
      return "All meetings";
    case AutoJoinMeetingConfiguration.ONLY_MINE:
      return "Meetings I'm hosting";
    case AutoJoinMeetingConfiguration.ONLY_EXTERNAL:
      return "External meetings";
    case AutoJoinMeetingConfiguration.NEVER:
      return "None";
  }
};

export const EmailCategoryExplainer = () => (
  <div className="space-y-3">
    {labelData.map((datum) => (
      <div className={cn("flex items-center gap-x-4")} key={datum.labelName}>
        <div
          className={cn(
            "flex w-32 flex-shrink-0 flex-grow-0 items-center justify-center gap-x-1 rounded-lg px-2 py-1 text-center text-xs font-semibold",
            datum.color,
          )}
        >
          {getFriendlyLabelName(datum.labelName)}
        </div>
        <p className="flex-grow text-sm">{datum.criteria}</p>
      </div>
    ))}
  </div>
);

export const ConfigurationTab = () => {
  const { section } = useQueryParams();
  const { userMembership, timeZone } = useOrganisation();
  const { logFormSubmit } = useAnalytics();
  const { updateMembership } = useOrganisationActions();

  const {
    font,
    fontColor,
    fontSize,
    areLabelsEnabled,
    areDraftsEnabled,
    emailRuleItems,
    labelNamesInInbox,
    alternativeEmails,
    areFollowUpsEnabled,
    isConversationViewEnabled,
    emailSignature,
    followUpHours,
    schedulingLink,
    appEmails,
    autoJoinMeetingConfiguration,
    upsellLocations,
    shouldSendAttendeesCallRecording,
    shouldSendReferralNotificationEmails,
    shouldSendMeetingRecordingFailureEmails,
    shouldUseFyxerSchedulingLink,
    shouldRespondThreshold,
    marketingNonautomatedThreshold,
  } = userMembership.data();

  useLayoutEffect(() => {
    if (["labels", "drafts", "follow_ups", "notetaker", "calendar_drafts"].includes(String(section))) {
      document.getElementById(String(section))?.scrollIntoView();
    }
  }, [section]);

  return (
    <FormUtil
      schema={z.object({
        areLabelsEnabled: z.boolean(),
        shouldRespondThreshold: z.number().int().gte(2).lte(5),
        marketingNonautomatedThreshold: z.number().int().gte(2).lte(5),
        labelNamesInInbox: z.array(z.nativeEnum(LabelName)),
        isConversationViewEnabled: z.boolean(),
        alternativeEmails: z.array(makeSchemaSafe(z.string().email().max(320))),
        emailRuleItems: z.array(
          z.object({
            emailAddress: makeSchemaSafe(z.string().min(1).max(320)),
            labelName: z.enum(["NONE", ...Object.values(LabelName)]),
          }),
        ),
        autoJoinMeetingConfiguration: z.nativeEnum(AutoJoinMeetingConfiguration),
        shouldSendReferralNotificationEmails: z.boolean(),
        shouldSendMeetingRecordingFailureEmails: z.boolean(),

        areDraftsEnabled: z.boolean(),
        appEmails: z.object({
          schedulingProposal: z.boolean(),
          schedulingAcceptance: z.boolean(),
        }),
        font: z.string(),
        fontColor: makeSchemaSafe(z.string().max(320)).optional(),
        fontSize: z.union([z.literal(0), z.number().int().gte(8).lte(16).optional()]),
        emailSignature: z.string(),
        schedulingLink: makeSchemaSafe(z.string().max(2000)).optional(),

        areFollowUpsEnabled: z.boolean(),
        followUpHours: z
          .number()
          .int()
          .gte(1)
          .lte(24 * 14),

        timeZone: makeSchemaSafe(z.string().max(400)),
        shouldUpsellInMeetingFollowUpDrafts: z.boolean(),
        shouldUpsellInCalendarDrafts: z.boolean(),
        shouldSendAttendeesCallRecording: z.boolean(),
        shouldUseFyxerSchedulingLink: z.boolean(),
      })}
      defaultValues={{
        areLabelsEnabled: areLabelsEnabled ?? configDefaults.areLabelsEnabled,
        shouldRespondThreshold: shouldRespondThreshold ?? configDefaults.shouldRespondThreshold,
        marketingNonautomatedThreshold: marketingNonautomatedThreshold ?? configDefaults.marketingNonautomatedThreshold,
        emailRuleItems: emailRuleItems ?? configDefaults.emailRuleItems,
        labelNamesInInbox: labelNamesInInbox ?? configDefaults.labelNamesInInbox,
        alternativeEmails: alternativeEmails ?? configDefaults.alternativeEmails,
        isConversationViewEnabled: isConversationViewEnabled ?? configDefaults.isConversationViewEnabled,
        shouldSendReferralNotificationEmails:
          shouldSendReferralNotificationEmails ?? configDefaults.shouldSendReferralNotificationEmails,
        shouldSendMeetingRecordingFailureEmails:
          shouldSendMeetingRecordingFailureEmails ?? configDefaults.shouldSendMeetingRecordingFailureEmails,

        autoJoinMeetingConfiguration: autoJoinMeetingConfiguration ?? configDefaults.autoJoinMeetingConfiguration,

        areDraftsEnabled: areDraftsEnabled ?? configDefaults.areDraftsEnabled,
        appEmails: appEmails ?? configDefaults.appEmails,
        font: font ?? configDefaults.font,
        fontColor: fontColor ?? configDefaults.fontColor,
        fontSize: fontSize ?? configDefaults.fontSize,
        emailSignature: emailSignature ?? "",
        schedulingLink: schedulingLink ?? "",

        areFollowUpsEnabled: areFollowUpsEnabled ?? configDefaults.areFollowUpsEnabled,
        followUpHours: followUpHours ?? configDefaults.followUpHours,

        timeZone,

        shouldUpsellInMeetingFollowUpDrafts: (upsellLocations ?? configDefaults.upsellLocations).includes(
          UpsellLocation.MEETING_FOLLOW_UP_DRAFTS,
        ),
        shouldUpsellInCalendarDrafts: (upsellLocations ?? configDefaults.upsellLocations).includes(
          UpsellLocation.CALENDAR_DRAFTS,
        ),
        shouldSendAttendeesCallRecording:
          shouldSendAttendeesCallRecording ?? configDefaults.shouldSendAttendeesCallRecording,
        shouldUseFyxerSchedulingLink: shouldUseFyxerSchedulingLink ?? configDefaults.shouldUseFyxerSchedulingLink,
      }}
      submitTitle="Update"
      successMessage="Configuration updated"
      render={(form) => (
        <div className="space-y-16 pb-12">
          <div>
            <div id="labels">
              <div className="mb-4 flex items-center justify-between gap-x-8">
                <div>
                  <h2 className="mb-1">Email categorization</h2>
                  <p className="text-sm text-gray-500">
                    Automatically sort and filter your emails to keep your main inbox focused on important messages.
                  </p>
                </div>
                <FormFieldUtil
                  isInline
                  control={form.control}
                  name="areLabelsEnabled"
                  title=""
                  render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
                />
              </div>

              <div className="mb-6 flex items-center gap-x-4 rounded-lg border border-gray-300 bg-gray-100 p-4">
                <Info size={18} className="flex-shrink-0 stroke-gray-700" />
                <p className="text-xs text-gray-700">
                  If you switch a category off here, emails in that category will be filed away in their folder
                  (Outlook) or label (Gmail), and won't be shown in your main inbox.
                </p>
              </div>

              <div className="mb-4 flex items-center gap-4">
                <p className="w-12 text-center text-xs text-gray-500">Show in inbox?</p>
                <p className="text-xs text-gray-500">Categories</p>
              </div>

              <div className="grid grid-cols-1 gap-4">
                {labelData.map((datum) => (
                  <div className="grid grid-cols-[auto,1fr] items-center gap-x-4" key={datum.labelName}>
                    <div className="flex w-12 items-center justify-center">
                      <FormFieldUtil
                        isInline
                        control={form.control}
                        name="labelNamesInInbox"
                        title=""
                        render={({ field }) => (
                          <Checkbox
                            checked={field.value.includes(datum.labelName)}
                            onCheckedChange={(checked) => {
                              const currentValues = form.getValues("labelNamesInInbox");
                              const newValues = checked
                                ? sort(deduplicate([...currentValues, datum.labelName]), (x) => x, "asc")
                                : sort(
                                    currentValues.filter((l) => l !== datum.labelName),
                                    (x) => x,
                                    "asc",
                                  );
                              field.onChange(newValues);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="flex items-center gap-x-3">
                      <div
                        className={cn(
                          "flex flex-shrink-0 flex-grow-0 items-center justify-center gap-x-1 rounded-lg px-2 py-1 text-center text-xs font-semibold",
                          datum.color,
                        )}
                      >
                        {getFriendlyLabelName(datum.labelName)}
                      </div>
                      <p className="flex-grow text-xs text-gray-500">{datum.criteria}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-8">
                <h4 className="text-lg font-semibold">Email rules</h4>
                <p className="mb-4 text-sm text-slate-500">
                  Specify email addresses, domains, or specific email subjects to automatically assign to a label. For
                  example, if there's a newsletter you're interested in, you can assign it to FYI so it doesn't go to
                  marketing.
                </p>
                <ArrayField
                  form={form}
                  name="emailRuleItems"
                  maxLength={400}
                  addButtonLabel="Add email or subject"
                  generateEmptyDatum={() => ({
                    emailAddress: "",
                    labelName: LabelName.TO_RESPOND,
                  })}
                  render={(index) => (
                    <div className="flex flex-grow items-center gap-x-2">
                      <FormFieldUtil
                        className="flex-grow"
                        control={form.control}
                        name={`emailRuleItems.${index}.emailAddress`}
                        render={({ field }) => <Input {...field} />}
                        isLabelHidden
                      />
                      <FormFieldUtil
                        control={form.control}
                        name={`emailRuleItems.${index}.labelName`}
                        render={({ field }) => (
                          <SelectUtil
                            className="w-40"
                            items={([...Object.values(LabelName), "NONE"] as (LabelName | "NONE")[])
                              .filter(
                                (labelName) =>
                                  !([LabelName.ACTIONED, LabelName.AWAITING_REPLY] as (LabelName | "NONE")[]).includes(
                                    labelName,
                                  ),
                              )
                              .map((labelName) => ({
                                label: labelName === "NONE" ? "No label" : getFriendlyLabelName(labelName),
                                value: labelName,
                              }))}
                            {...field}
                          />
                        )}
                        isLabelHidden
                      />
                    </div>
                  )}
                />
              </div>

              <div className="mt-8">
                <h4 className="text-lg font-semibold">Alternative emails</h4>
                <p className="mb-4 text-sm text-slate-500">
                  Specify other email addresses you send from apart from your main email address, so they're labeled as
                  Actioned or Awaiting Reply.
                </p>
                <ArrayField
                  form={form}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  name={"alternativeEmails" as any}
                  maxLength={20}
                  addButtonLabel="Add email"
                  generateEmptyDatum={() => ""}
                  render={(index) => (
                    <FormFieldUtil
                      className="flex-grow"
                      control={form.control}
                      name={`alternativeEmails.${index}`}
                      render={({ field }) => <Input {...field} />}
                      isLabelHidden
                    />
                  )}
                />
              </div>

              <div className="mb-6 mt-6 space-y-6">
                <FormFieldUtil
                  control={form.control}
                  name="shouldRespondThreshold"
                  title="Response threshold"
                  description="Governs which emails we mark as 'to respond' and generate a draft reply for."
                  render={({ field }) => (
                    <SelectUtil
                      {...field}
                      items={[
                        { value: 2, label: "1 - Emails that could use a polite acknowledgement" },
                        { value: 3, label: "2 - Emails offering new information that I should acknowledge" },
                        { value: 4, label: "3 - Emails without a direct ask, that likely need me to address" },
                        { value: 5, label: "4 - Emails with a direct ask from me" },
                      ]}
                    />
                  )}
                />

                <FormFieldUtil
                  control={form.control}
                  name="marketingNonautomatedThreshold"
                  title="Marketing threshold"
                  description="Governs which emails get filtered into the marketing folder"
                  render={({ field }) => (
                    <SelectUtil
                      {...field}
                      items={[
                        { value: 2, label: "1 - Obvious sales cold emails" },
                        { value: 3, label: "2 - Emails from all people it's obvious you don't know" },
                        { value: 4, label: "3 - Non business critical emails from people I might know" },
                        { value: 5, label: "4 - Anything that's not business critical" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div id="drafts">
            <div className="mb-4 flex items-center justify-between gap-x-8">
              <div>
                <h2 className="mb-1">Draft replies</h2>
                <p className="text-sm text-gray-500">
                  When you get an email you need to reply to, we'll leave a draft response in your inbox for you to send
                  or edit.
                </p>
              </div>
              <FormFieldUtil
                isInline
                control={form.control}
                name="areDraftsEnabled"
                title=""
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
            </div>

            <div className="space-y-4">
              <ConnectionWarning serviceTypes={[OauthServiceType.EMAIL]} />
              <PlanWarning minimumPlanType={SubscriptionPlanType.STANDARD} />
            </div>

            <div className="mb-8 flex items-center gap-x-4 rounded-lg border border-gray-300 bg-gray-100 p-4">
              <div className="flex items-start gap-x-4">
                <Info size={18} className="flex-shrink-0 stroke-gray-700" />
                <div>
                  <p className="mb-2 text-xs text-gray-700 sm:text-sm">
                    For draft replies to work smoothly, your Gmail or Outlook should group related emails into a single
                    thread. We save drafts within the thread for easy access.
                  </p>
                  <p className="mb-4 text-xs text-gray-700 sm:text-sm">
                    By default, both Gmail and Outlook do this automatically. If your emails aren't grouping, you can
                    enable threading by following the steps below:
                  </p>
                  <Accordion type="multiple">
                    <AccordionItem value="gmail">
                      <AccordionTrigger className="pb-2 pt-2">
                        <p className="text-xs font-bold sm:text-sm">How to enable threading in Gmail</p>
                      </AccordionTrigger>
                      <AccordionContent>
                        <ol className="flex list-decimal flex-col gap-y-2 pl-5 text-xs sm:text-sm">
                          <li>Click the settings cog in the top right.</li>
                          <li>Scroll to the bottom.</li>
                          <li>
                            Enable <strong>Conversation View</strong>.
                          </li>
                        </ol>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="outlook" className="border-0">
                      <AccordionTrigger className="pb-2 pt-2">
                        <p className="text-xs font-bold sm:text-sm">How to enable threading in Outlook</p>
                      </AccordionTrigger>
                      <AccordionContent>
                        <ol className="flex list-decimal flex-col gap-y-2 pl-5 text-xs sm:text-sm">
                          <li>Click the settings cog in the top right.</li>
                          <li>
                            Scroll to <strong>Message Organization</strong>.
                          </li>
                          <li>
                            Select <strong>Show email grouped by conversation</strong>.
                          </li>
                        </ol>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>

            {form.getValues("areDraftsEnabled") && (
              <div>
                <div className="mb-4">
                  <FormFieldUtil
                    control={form.control}
                    name="emailSignature"
                    labelClassName="mb-2 block"
                    description="To ensure your signature displays correctly, you should copy it directly from your Gmail/Outlook settings, instead of from an email you've sent."
                    render={({ field }) => <HTMLPaster {...field} />}
                  />
                </div>
                <div className="mb-4">
                  <FormFieldUtil
                    control={form.control}
                    name="font"
                    labelClassName="mb-2 block"
                    render={({ field }) => (
                      <ComboboxUtil
                        {...field}
                        className="w-full"
                        items={[{ value: "inherit", label: "Gmail/Outlook default" }, ...fonts]}
                      />
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormFieldUtil
                    control={form.control}
                    name="fontSize"
                    labelClassName="mb-2 block"
                    description="Set this value to 0 to inherit the font size from your email client."
                    render={({ field }) => <NumberInput value={field.value ?? 0} onChange={field.onChange} />}
                  />
                </div>
                <FormFieldUtil
                  control={form.control}
                  name="fontColor"
                  labelClassName="mb-2 block"
                  render={({ field }) => (
                    <>
                      <div className="flex items-center gap-x-2">
                        <Popover>
                          <PopoverTrigger>
                            <div
                              className="block h-10 w-10 flex-shrink-0 rounded-md border border-slate-200"
                              style={{
                                backgroundColor: field.value ?? "#000000",
                              }}
                            ></div>
                          </PopoverTrigger>
                          <PopoverContent align="start" className="bg-white" style={{ width: "236px" }}>
                            <HexColorPicker color={field.value} onChange={field.onChange} />
                          </PopoverContent>
                        </Popover>
                        <Input {...field} placeholder="#000000" />
                      </div>
                    </>
                  )}
                />
              </div>
            )}
          </div>

          <div className="space-y-8">
            <div className="space-y-4">
              <h2 id="follow_ups">Follow ups</h2>

              <p className="text-sm">
                When you enable Follow Ups, we'll keep track of emails you've sent that you're expecting a reply to, and
                draft a follow up message for you to send after the amount of days you specify.
              </p>
            </div>

            <ConnectionWarning serviceTypes={[OauthServiceType.EMAIL]} />
            <PlanWarning minimumPlanType={SubscriptionPlanType.STANDARD} />
            <FormFieldUtil
              isInline
              control={form.control}
              name="areFollowUpsEnabled"
              title="Enable follow ups"
              render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
            />
            {form.getValues("areFollowUpsEnabled") && (
              <div className="space-y-4">
                <FormFieldUtil
                  control={form.control}
                  name="followUpHours"
                  title="Days before following up"
                  render={({ field }) => (
                    <SliderRoot
                      defaultValue={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                      step={24}
                      min={24}
                      max={24 * 14}
                      className="mt-8"
                    >
                      <SliderTrack />
                      <SliderThumb label={(field.value / 24).toFixed(0)} />
                    </SliderRoot>
                  )}
                />
              </div>
            )}
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <div className="flex items-center gap-x-4">
                <h2 id="calendar_drafts">Calendar drafts</h2>
              </div>
              <p className="text-sm">
                When someone sends you an email asking to meet, we'll use your calendar to pick the best times for a
                meeting, send a response informed by your calendar, and draft a calendar invite for you to send.
              </p>
            </div>
            <ConnectionWarning serviceTypes={[OauthServiceType.EMAIL, OauthServiceType.CALENDAR]} />
            <PlanWarning minimumPlanType={SubscriptionPlanType.STANDARD} />

            <div className="space-y-4">
              <FormFieldUtil
                isInline
                control={form.control}
                name="shouldUseFyxerSchedulingLink"
                title="Beta: Use Fyxer's calendar link to enhance scheduling"
                description="When someone asks what times you're available for a meeting, we'll create a link they can click to see some of your available slots. Will override any third-party links provided."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
              {!form.getValues("shouldUseFyxerSchedulingLink") && (
                <FormFieldUtil
                  control={form.control}
                  name="schedulingLink"
                  title="Third-Party scheduling link"
                  description="A third-party link others can use to schedule meetings with you, such as Calendly."
                  render={({ field }) => <Input {...field} />}
                />
              )}
              <FormFieldUtil
                control={form.control}
                name="timeZone"
                render={({ field }) => <ComboboxUtil {...field} items={getTimeZones()} />}
              />
              <FormFieldUtil
                isInline
                control={form.control}
                name="appEmails.schedulingProposal"
                title="Email when proposing times"
                description="When someone asks what times you're available for a meeting, we'll email you with a view of the best slots you have available, in the context of your other events."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
              <FormFieldUtil
                isInline
                control={form.control}
                name="appEmails.schedulingAcceptance"
                title="Email when accepting times"
                description="When you lock in a meeting time with someone, we'll send an email with a link to create the calendar event."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />

              <FormFieldUtil
                isInline
                control={form.control}
                name="shouldUpsellInCalendarDrafts"
                title={`Mention ${getCompanyName()} in calendar drafts`}
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
            </div>
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <h2 id="notetaker">Meeting notetaker</h2>
              <p className="text-sm">
                You can find a list of upcoming meetings eligible for the Meeting Notetaker (they have a meeting link
                and two or more attendees) in the Meeting Notetaker section of the dashboard. You can control which
                meetings to send the assistant to there. When the meeting ends, we'll email a meeting summary, and leave
                an email draft in your inbox for you to send to the meeting's attendees.
              </p>
            </div>

            <ConnectionWarning serviceTypes={[OauthServiceType.CALENDAR, OauthServiceType.EMAIL]} />
            <PlanWarning minimumPlanType={SubscriptionPlanType.STANDARD} />

            <>
              <FormFieldUtil
                control={form.control}
                name="autoJoinMeetingConfiguration"
                title="Should join meetings automatically"
                description="If this setting is on, we'll automatically set the switch in the Upcoming Meetings tab of the Meeting Notetaker section to 'on', meaning our Meeting Notetaker will join them. You can set the switch to off to let the bot know not to join."
                render={({ field }) => (
                  <SelectUtil
                    {...field}
                    items={Object.values(AutoJoinMeetingConfiguration).map((value) => ({
                      value,
                      label: getMeetingConfigName(value),
                    }))}
                  />
                )}
              />

              {/* <FormFieldUtil
                isInline
                control={form.control}
                name="shouldUpsellInMeetingFollowUpDrafts"
                title=`Mention ${getCompanyName()} in meeting follow ups`
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              /> */}
              <FormFieldUtil
                isInline
                control={form.control}
                name="shouldSendMeetingRecordingFailureEmails"
                title="Send meeting notetaker failure emails"
                description="When you tell us to send the meeting notetaker to a call and it fails to record, we'll send you an email explaining why."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
              <FormFieldUtil
                isInline
                control={form.control}
                name="shouldSendAttendeesCallRecording"
                title="Send call recording to attendees"
                description="When the meeting ends, we'll email the attendees a secure link to the call recording."
                render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
              />
            </>
          </div>
        </div>
      )}
      renderSubmitButton={(submitProps) => (
        <div className="opacity-100">
          <Button className={`fixed bottom-4 w-[calc(100vw-32px)] max-w-[540px]`} {...submitProps}>
            Update preferences
          </Button>
        </div>
      )}
      onSubmit={async ({ shouldUpsellInCalendarDrafts, shouldUpsellInMeetingFollowUpDrafts, ...formData }) => {
        const upsellLocations = [
          ...(shouldUpsellInCalendarDrafts ? [UpsellLocation.CALENDAR_DRAFTS] : []),
          ...(shouldUpsellInMeetingFollowUpDrafts ? [UpsellLocation.MEETING_FOLLOW_UP_DRAFTS] : []),
        ];
        const data: Partial<Membership> = {
          ...formData,
          upsellLocations,
        };
        logFormSubmit(EventForm.UPDATE_CONFIGURATION, {
          areDraftsEnabled: data.areDraftsEnabled,
          areLabelsEnabled: data.areLabelsEnabled,
          labelNamesInInboxLength: data.labelNamesInInbox?.length ?? 0,
        });
        await updateMembership(data);
      }}
    />
  );
};
