import DOMPurify from "dompurify";
import { ChangeEventHandler, ClipboardEventHandler, useEffect, useRef } from "react";

import { Input } from "../ui/input";

const SignaturePreview = ({ htmlContent }: { htmlContent: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current && htmlContent) {
      const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(htmlContent);
        iframeDoc.close();
      }
    }
  }, [htmlContent]);

  return (
    <iframe
      ref={iframeRef}
      style={{ width: "100%", height: "300px", border: "none" }}
      title="Signature Preview"
    ></iframe>
  );
};

export const HTMLPaster = ({
  value,
  onChange,
}: {
  value: string | undefined;
  onChange: (html: string | undefined) => void;
}) => {
  const handlePaste: ClipboardEventHandler = (event) => {
    try {
      const html = event.clipboardData.getData("text/html");
      const sanitizedHtml = DOMPurify.sanitize(html);
      onChange(sanitizedHtml);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      onChange(undefined);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.target.value === "") {
      onChange("");
    }

    onChange(DOMPurify.sanitize(event.target.value));
  };

  return (
    <div className="space-y-4">
      <Input value={value} onPaste={handlePaste} onChange={handleChange} placeholder="Paste signature here" />
      {value ? (
        <div className="space-y-2">
          <h4>Preview</h4>
          <SignaturePreview htmlContent={value} />
        </div>
      ) : undefined}
    </div>
  );
};
