import { constructUrl } from "@fyxer-ai/shared";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const OAuthFeedback = ({ connection, error }: { connection: string; error: string | undefined }) => {
  return (
    <div className="mx-auto flex max-w-lg items-center justify-center px-3" style={{ height: "calc(100vh - 48px)" }}>
      <div>
        <Card>
          <CardHeader>
            <CardTitle className="mb-3 leading-8">
              An error occurred when connecting with{" "}
              <span style={{ fontFamily: "inherit" }} className="capitalize">
                {connection?.split?.("-").join(" ")}
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent>
            {error ? (
              <div className="-mt-3 mb-6 rounded-lg bg-red-200 p-3 text-left font-mono text-xs">{error}</div>
            ) : null}
          </CardContent>
        </Card>

        <div className="mt-6 text-center">
          <Link to={constructUrl({ path: "/org" })}>
            <Button variant="link">Back to Fyxer AI</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OAuthFeedback;
