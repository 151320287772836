// External dependencies
import { CallRecordingAccessSetting, OrganisationRole } from "@fyxer-ai/shared";
import { doc, updateDoc } from "firebase/firestore";
import { X } from "lucide-react";
import { useState } from "react";
import { z } from "zod";

// UI Components
import { ArrayField } from "@/components/controls/ArrayField";
import { CopyTextButton } from "@/components/controls/CopyUtil";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

// Hooks and Context
import { useBase } from "@/context/BaseContext/state/useBase";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { useOrganisationActions } from "@/context/OrganisationContext/actions/useOrganisationActions";
import { EventButton, EventForm, useAnalytics, useFeatureFlag } from "@/hooks/useAnalytics";
import { useQueryParams } from "@/hooks/useQueryParams";

// Utilities
import { Collection } from "@/lib/firebase/Collection";
import { unwrap } from "@/lib/firebase/unwrap";
import { getCompanyName } from "@/lib/getWhitelabelProvider";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

// Local imports
import { getCallRecordingUrl } from "./getCallRecordingUrl";
import { UseFormReturn } from "react-hook-form";
import { useOrganisationNullable } from "@/context/OrganisationContext/state/useOrganisationNullable";

const getTitleFromAccessSetting = (accessSetting: CallRecordingAccessSetting) => {
  switch (accessSetting) {
    case CallRecordingAccessSetting.ONLY_ME:
      return "Restricted";
    case CallRecordingAccessSetting.ORGANISATION:
      return "My Organisation";
    case CallRecordingAccessSetting.ANYONE_WITH_LINK:
      return "Anyone with the link";
  }
};

const getExplanationFromAccessSetting = (accessSetting: CallRecordingAccessSetting) => {
  switch (accessSetting) {
    case CallRecordingAccessSetting.ONLY_ME:
      return "Only myself and the people listed above can view the recording";
    case CallRecordingAccessSetting.ORGANISATION:
      return "Anyone who's a member of your organisation can view the recoding";
    case CallRecordingAccessSetting.ANYONE_WITH_LINK:
      return "Anyone on the internet with the link can view the recording";
  }
};

 
const schema = z.object({
  accessSetting: z.nativeEnum(CallRecordingAccessSetting),
  emailsWithAccess: z.array(makeSchemaSafe(z.string().email().max(320))),
});

type EditAccessSettingDialogData = z.infer<typeof schema>;

const SuggestInvitesArrayField = ({
  form,
  emailsWithAccess,
}: {
  form: UseFormReturn<
    {
      accessSetting: CallRecordingAccessSetting;
      emailsWithAccess: string[];
    },
    unknown,
    undefined
  >;
  emailsWithAccess: string[];
}) => {
  const { user } = useBase();
  const { organisation, memberships, invites } = useOrganisation();
  const { sendInvites } = useOrganisationActions();
  const { logFormSubmit } = useAnalytics();
  const { toast } = useToast();
  const orgDomain = organisation.domain;
  const emailsInOrganisation = memberships.map(unwrap((membership) => membership.userEmail)).filter(Boolean);
  const emailsInvites = invites.map(unwrap((invite) => invite.email)).filter(Boolean);
  const invitedOrInOrganisation = [...emailsInOrganisation, ...emailsInvites];

  function shouldHideRemoveButton(index: number) {
    const email = emailsWithAccess.length > index ? emailsWithAccess[index] : undefined;
    if (!email) return false;
    return email === user.value?.email;
  }

  return (
    <ArrayField
      form={form}
      name={"emailsWithAccess" as never}
      maxLength={50}
      generateEmptyDatum={() => ""}
      shouldHideRemoveButton={() => true}
      render={(index, _, remove) => {
        const currentEmail = emailsWithAccess[index] ?? "";
        const hasOrgDomain = currentEmail.includes(`@${orgDomain}`);
        const notInvited = !invitedOrInOrganisation.includes(currentEmail);
        const showInvitePrompt = hasOrgDomain && notInvited;

        return (
          <div className="w-full">
            <div className="flex w-full items-center gap-x-4">
              <FormFieldUtil
                className="flex-grow"
                control={form.control}
                name={`emailsWithAccess.${index}`}
                render={({ field }) => <Input {...field} />}
                isLabelHidden
              />
              {shouldHideRemoveButton(index) ? null : (
                <Button variant="secondary" onClick={remove}>
                  <X size={16} />
                </Button>
              )}
            </div>
            {showInvitePrompt ? (
              <p
                className="mt-2 text-xs"
                onClick={() => {
                  sendInvites([{ email: currentEmail, role: OrganisationRole.MEMBER }]);
                  logFormSubmit(EventForm.SEND_INVITES, { count: 1 });
                  toast({ title: `Invite sent to ${currentEmail}` });
                }}
              >
                This person isn't on {getCompanyName()} yet.{" "}
                <span className="font-bold text-purple-500" role="button">
                  Send them an invite
                </span>
              </p>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export const EditAccessSettingDialogContent = ({
  shareLink,
  defaultValues,
  onSubmit,
  title,
}: {
  shareLink: string;
  defaultValues: EditAccessSettingDialogData;
  onSubmit: (data: EditAccessSettingDialogData) => Promise<void>;
  title: string;
}) => {
  const { logButtonPress } = useAnalytics();
  const { toast } = useToast();
  const { user } = useBase();
  const { organisation } = useOrganisationNullable();
  const shareRecordingInviteSuggest = useFeatureFlag("share-recording-invite-suggest");
  const shouldSugggestInvites = organisation.value && shareRecordingInviteSuggest.value() === "test";

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <FormUtil
        schema={schema}
        defaultValues={defaultValues}
        onSubmit={async (data) => {
          await onSubmit(data);
          toast({ title: "Access updated" });
        }}
        submitTitle="Save"
        render={(form) => {
          const emailsWithAccess = form.watch("emailsWithAccess");

          function shouldHideRemoveButton(index: number) {
            const email = emailsWithAccess.length > index ? emailsWithAccess[index] : undefined;
            if (!email) return false;
            return email === user.value?.email;
          }

          return (
            <>
              <div className="space-y-2">
                <Label>Emails with access</Label>
                {shouldSugggestInvites ? (
                  <SuggestInvitesArrayField form={form} emailsWithAccess={emailsWithAccess} />
                ) : (
                  <ArrayField
                    form={form}
                    name={"emailsWithAccess" as never}
                    maxLength={50}
                    generateEmptyDatum={() => ""}
                    shouldHideRemoveButton={shouldHideRemoveButton}
                    render={(index) => (
                      <FormFieldUtil
                        className="flex-grow"
                        control={form.control}
                        name={`emailsWithAccess.${index}`}
                        render={({ field }) => <Input {...field} />}
                        isLabelHidden
                      />
                    )}
                  />
                )}
              </div>
              <FormFieldUtil
                control={form.control}
                name="accessSetting"
                title="General access"
                description={getExplanationFromAccessSetting(form.watch("accessSetting"))}
                render={({ field }) => (
                  <SelectUtil
                    {...field}
                    items={Object.values(CallRecordingAccessSetting).map((accessSetting) => ({
                      value: accessSetting,
                      label: getTitleFromAccessSetting(accessSetting),
                    }))}
                  />
                )}
              />
            </>
          );
        }}
      />
      <CopyTextButton onClick={() => logButtonPress(EventButton.SHARE_CALL_RECORDING)} value={shareLink} />
    </DialogContent>
  );
};

export const EditAccessSettingDialog = () => {
  const shouldEditAccess = !!useQueryParams().shouldEditAccess;
  const { callRecording, callRecordingId } = useCallRecording();
  const { accessSetting } = callRecording;
  const emailsWithAccess = callRecording.emailsWithAccess ?? [];

  const callRecordingShareLink = getCallRecordingUrl({ callRecordingId });
  const [open, setOpen] = useState(shouldEditAccess);

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <DialogTrigger asChild>
        <Button>Share</Button>
      </DialogTrigger>
      <EditAccessSettingDialogContent
        title="Share call recording"
        onSubmit={async (data) => {
          await updateDoc(doc(Collection.CallRecording, callRecordingId), data);
          setOpen(false);
        }}
        defaultValues={{ accessSetting, emailsWithAccess }}
        shareLink={callRecordingShareLink}
      />
    </Dialog>
  );
};
