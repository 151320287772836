import { isPending, Membership, organisationConfigDefaults, OrganisationRole } from "@fyxer-ai/shared";
import { format } from "date-fns";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { toHeaderCase } from "js-convert-case";
import { Edit2, MoreVertical, Trash2 } from "lucide-react";
import { z } from "zod";

import { CopyIconButton } from "@/components/controls/CopyUtil";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { UserAvatar } from "@/components/layout/UserAvatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useUser } from "@/context/BaseContext/state/useUser";
import { useOrganisationActions } from "@/context/OrganisationContext/actions/useOrganisationActions";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventButton, EventForm, useAnalytics } from "@/hooks/useAnalytics";
import { useClickProps } from "@/hooks/useClickProps";
import { useUpdateState } from "@/hooks/useUpdateState";
import { unwrap } from "@/lib/firebase/unwrap";

import { InvitesDialog } from "./InvitesDialog";
import { Input } from "@/components/ui/input";
import { useMemo } from "react";

interface Data {
  membershipIdToDelete?: string;
  membershipIdToEditRole?: string;
  inviteIdToDelete?: string;
  teamSearchValue: string;
}

export const TeamTab = () => {
  const { removeMember, changeMemberRole, cancelInvite } = useOrganisationActions();
  const { invites, memberships, isAdmin, teams, organisation, userMembership } = useOrganisation();
  const { role: userRole } = userMembership.data();
  const canInviteUsers = (
    organisation.rolesThatCanInviteUsers ?? organisationConfigDefaults.rolesThatCanInviteUsers
  ).includes(userRole);
  const { userId } = useUser();
  const pendingInvites = invites.filter(unwrap(isPending));
  const [state, updateState] = useUpdateState<Data>({ teamSearchValue: "" });
  const { logButtonPress, logFormSubmit } = useAnalytics();

  const deleteMemberClickProps = useClickProps({
    async onClick() {
      const id = state.membershipIdToDelete;
      if (!id) return;
      logButtonPress(EventButton.DELETE_MEMBER, {
        membershipId: id,
      });
      await removeMember(id);
      updateState({ membershipIdToDelete: undefined });
    },
    buttonText: "Remove teammate",
  });

  const hasTeams = memberships.some(unwrap((membership) => membership.organisationTeamId));

  const deleteInviteClickProps = useClickProps({
    async onClick() {
      const id = state.inviteIdToDelete;
      if (!id) return;
      logButtonPress(EventButton.DELETE_INVITE, {
        inviteId: id,
      });
      await cancelInvite(id);
      updateState({ inviteIdToDelete: undefined });
    },
    buttonText: "Cancel invite",
  });

  const filteredMemberships = useMemo(
    () =>
      state.teamSearchValue.length > 1
        ? memberships.filter(
            unwrap(
              (membership) =>
                membership.userName?.toLowerCase().includes(state.teamSearchValue.toLowerCase()) ||
                membership.userEmail.includes(state.teamSearchValue.toLowerCase()),
            ),
          )
        : memberships,
    [state.teamSearchValue, memberships],
  );

  return (
    <div className="space-y-12">
      <Dialog
        open={!!state.membershipIdToDelete}
        onOpenChange={(open) => {
          if (!open) updateState({ membershipIdToDelete: undefined });
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              The user will lose access to the organisation. This action isn't reversible.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="destructive" {...deleteMemberClickProps}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!state.inviteIdToDelete}
        onOpenChange={(open) => {
          if (!open) updateState({ inviteIdToDelete: undefined });
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
          </DialogHeader>
          <DialogFooter>
            <Button variant="destructive" {...deleteInviteClickProps}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!state.membershipIdToEditRole}
        onOpenChange={(open) => {
          if (!open) updateState({ membershipIdToEditRole: undefined });
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit user role</DialogTitle>
          </DialogHeader>
          <FormUtil
            schema={z.object({
              role: z.nativeEnum(OrganisationRole),
            })}
            onSubmit={async ({ role }) => {
              const { membershipIdToEditRole: id } = state;
              if (!id) return;
              logFormSubmit(EventForm.CHANGE_MEMBER_ROLE, {
                membershipId: id,
                role,
              });
              await changeMemberRole({ membershipId: id, role });
              updateState({ membershipIdToEditRole: undefined });
            }}
            submitTitle="Change role"
            defaultValues={{
              role:
                memberships.find((m) => m.id === state.membershipIdToEditRole)?.data()?.role ?? OrganisationRole.ADMIN,
            }}
            render={(form) => (
              <FormFieldUtil
                name="role"
                control={form.control}
                render={({ field }) => <SelectUtil {...field} items={Object.values(OrganisationRole)} />}
              />
            )}
          />
        </DialogContent>
      </Dialog>
      <div className="space-y-4">
        <div className="flex items-center">
          <h2>Teammates</h2>
          <div className="flex-grow"></div>
          {canInviteUsers ? <InvitesDialog /> : null}
        </div>
        <Input
          value={state.teamSearchValue}
          onChange={(e) => updateState({ teamSearchValue: e.target.value })}
          placeholder="Search"
        />
        <Table className="w-full overflow-scroll">
          {filteredMemberships.length === 0 ? <TableCaption>No memberships</TableCaption> : null}
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              {hasTeams ? <TableHead>Team</TableHead> : null}
              <TableHead>Role</TableHead>
              <TableHead>Joined</TableHead>
              {isAdmin ? <TableHead></TableHead> : null}
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredMemberships.map((membership) => {
              const {
                userName,
                userPhotoUrl,
                createdAt,
                role,
                userId: membershipUserId,
                organisationTeamId,
              } = membership.data();
              const teamName = teams.find((team) => team.id === organisationTeamId)?.data().name;
              return (
                <TableRow key={membership.id}>
                  <TableCell className="flex items-center gap-4">
                    <UserAvatar className="hidden sm:block" name={userName} photoUrl={userPhotoUrl} />
                    {userName}
                    {membershipUserId === userId ? <Badge>You</Badge> : null}
                  </TableCell>
                  {hasTeams ? <TableCell>{teamName ?? ""}</TableCell> : null}
                  <TableCell>{toHeaderCase(role)}</TableCell>
                  <TableCell>{format(createdAt, "dd/MM/yyyy")}</TableCell>
                  {membershipUserId !== userId && isAdmin ? (
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button size="icon" variant="ghost">
                            <MoreVertical size={16} />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            className="cursor-pointer"
                            onClick={() =>
                              updateState({
                                membershipIdToEditRole: membership.id,
                              })
                            }
                          >
                            <Edit2 className="mr-2 h-4 w-4" />
                            Change role
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="cursor-pointer"
                            onClick={() =>
                              updateState({
                                membershipIdToDelete: membership.id,
                              })
                            }
                          >
                            <Trash2 className="mr-2 h-4 w-4" />
                            Remove user
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className="space-y-4">
        <h2>Invites</h2>
        <p>The people you've invited to join your team will show here.</p>
        <Table className="overflow-scroll">
          {pendingInvites.length === 0 ? <TableCaption>No pending invites</TableCaption> : null}
          <TableHeader>
            <TableRow>
              <TableHead>Email</TableHead>
              <TableHead>Role</TableHead>
              <TableHead className="hidden sm:table-cell">Invited by</TableHead>
              <TableHead className="hidden sm:table-cell">Invited at</TableHead>
              <TableHead className="hidden sm:table-cell">Invite link</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {pendingInvites.map((invite) => {
              const { createdAt, email, role, invitingUserId } = invite.data();
              const invitingUserMembership = memberships.find(
                (m) => m.data().userId === invitingUserId,
              ) as QueryDocumentSnapshot<Membership>;
              const { userName } = invitingUserMembership.data();
              const url = new URL(window.location.href);
              const base = `${url.protocol}//${url.host}`;

              return (
                <TableRow>
                  <TableCell>{email}</TableCell>
                  <TableCell>{toHeaderCase(role)}</TableCell>
                  <TableCell className="hidden sm:table-cell">{userName}</TableCell>
                  <TableCell className="hidden sm:table-cell">{format(createdAt, "dd/MM/yy")}</TableCell>
                  <TableCell className="hidden sm:table-cell">
                    <CopyIconButton value={`${base}/invites/${invite.id}/accept`} />
                  </TableCell>
                  <TableCell>
                    {isAdmin || invitingUserId === userId ? (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button size="icon" variant="ghost">
                            <MoreVertical size={16} />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            className="cursor-pointer"
                            onClick={() => updateState({ inviteIdToDelete: invite.id })}
                          >
                            <Trash2 className="mr-2 h-4 w-4" />
                            Cancel invite
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
