import { sleep } from "@fyxer-ai/shared";
import { useEffect } from "react";

import { ua } from "@/lib/userAgent";

const replaceUrlScheme = ({ url, scheme }: { url: string; scheme: string }) =>
  url.replace("https://", scheme).replace("http://", scheme);

export const redirectIfOnWebview = async () => {
  const browserName = ua.browser.name ?? "NONE";
  const isWebView = ["Instagram", "Facebook", "LinkedIn"].includes(browserName);
  if (!isWebView) return;

  const url = window.location.href;
  const isIos = ua.os.name === "iOS";

  if (isIos) {
    window.location.href = replaceUrlScheme({ url, scheme: "x-safari-https://" });
    await sleep(200);
  }

  window.location.href = replaceUrlScheme({ url, scheme: "googlechrome://" });
  await sleep(200);
};

export const useRedirectToSystemDefaultBrowserIfInWebView = () => {
  useEffect(() => {
    redirectIfOnWebview();
     
  }, []);
};
