import { isSubscriptionActive } from "@fyxer-ai/shared";

import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";

import { FreePlanView } from "./FreePlanView";
import { InactiveSubscriptionView } from "./InactiveSubscriptionView";
import { SubscriptionView } from "./SubscriptionView";
import { PlanTypeSource } from "./getPlanType";
import { PrivateBetaView } from "./PrivateBetaView";

export const BillingTab = () => {
  const { subscription, planData } = useOrganisation();
  const isPrivateBeta = planData?.planTypeSource === PlanTypeSource.PRIVATE_BETA;

  if (isPrivateBeta) {
    return <PrivateBetaView />;
  }

  if (!subscription) return <FreePlanView />;
  const isActive = isSubscriptionActive(subscription.data());
  if (!isActive) return <InactiveSubscriptionView />;
  return <SubscriptionView />;
};
